import { GET, POST, prefix } from '@utils/lib-request/request';

// 获取申请资源时可选择的资源列表
export const getResourcesList = (params?: any) => GET(`${prefix}/resources/list`, params);

// 获取该用户所有已购买的大型资源
export const getUserResourcesList = (params?: any) => GET(`${prefix}/resources/user`, params);

// 获取对应资源的总价格
export const getTotalPrice = (params: any) => POST(`${prefix}/resources/totalPrice`, params);
