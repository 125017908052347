import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Table, Tag, Tooltip } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
// @ts-ignore
import { getUsersExperiments } from '@pages/user/api';
import { RenderExperimentState } from '@pages/experiment/experimentList';

// @ts-ignore
import S from './style.module.less';

const { CheckableTag } = Tag;

// 用户实验
export default function UserExperiments() {
  const [list, setList] = useState<any[]>([]); // 表格数据源
  const [total, setTotal] = useState(1); // 表格总条数，默认不要是0哦
  const [current, setCurrent] = useState(1); // 表格当前页码
  const [loading, setLoading] = useState(true); // 表格是否是加载中
  const [filterState, setFilterState] = useState<number | string>(''); // 过滤条件
  const pageSize = 15;
  const filterStateOptions = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '已完成',
      value: 1,
    },
    {
      label: '未完成',
      value: 0,
    },
  ];
  const columns = [
    {
      title: '实验名称',
      dataIndex: 'name',
      render: (text: any, record: any) => {
        return (
          <div className={S.nameWrap}>
            {
              [
                <Tooltip key="未完成" title="未完成">
                  <ClockCircleOutlined key="未完成" style={{ color: '#9d9d9d' }} />
                </Tooltip>,

                <Tooltip key="已完成" title="已完成">
                  <CheckCircleOutlined key="已完成" style={{ color: '#1DC11D' }} />
                </Tooltip>,
              ][record.state] // 状态 0未完成  1完成
            }
            <p className={S.name}>{text}</p>
          </div>
        );
      },
    },
    {
      width: '160px',
      title: '得分',
      dataIndex: 'score',
      render: (text: any, record: any) => {
        return <span className={Number(text) < 60 && S.warningScore}>{text}</span>;
      },
    },
    {
      title: '得分说明',
      dataIndex: 'scoreExplain',
      width: '50%',
      render: (text: any, record: any) => {
        return text || '-';
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '100px',
      render: (text: any, record: any) => {
        return <RenderExperimentState record={record} />;
      },
    },
  ];

  useEffect(() => {
    const params = {
      pageSize: pageSize,
      pageNum: current,
      state: filterState, // 全部null   未完成0    已完成1
    };

    setLoading(true);
    getUsersExperiments(params).then((result: any) => {
      const records = result.records.map((item: any) => ({
        id: item.experimentId, // 实验id
        state: item.state, // 状态 0未完成  1完成
        score: item.score, // 得分
        scoreExplain: item.scoreExplain, // 得分说明
        name: item.experimentVo.name, // 实验名称
      }));

      setList(records);
      setTotal(result.count);
      setLoading(false);
    });
  }, [current, filterState]);

  const handleFilterState = (item: { label: string; value: string | number }, checked: boolean) => {
    setFilterState(item.value);
    setCurrent(1);
  };

  return (
    <>
      <div className={S.filterWrap}>
        {filterStateOptions.map((item: any) => (
          <CheckableTag
            key={item.label}
            checked={filterState === item.value}
            onChange={checked => handleFilterState(item, checked)}
          >
            {item.label}
          </CheckableTag>
        ))}
      </div>

      <Table
        loading={loading}
        rowKey="id"
        dataSource={list}
        columns={columns}
        pagination={{
          total,
          pageSize,
          current,
          onChange: page => {
            setCurrent(page);
          },
        }}
      />
    </>
  );
}
