import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Button, DatePicker, Form, Input, Table } from 'antd';
// @ts-ignore
import { getUsersExaminations } from '@pages/user/api';
import { RenderExamState } from '@pages/exam/examList';
// @ts-ignore
import S from './style.module.less';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

// 用户考试
export default function UserExam() {
  const [list, setList] = useState<any[]>([]); // 表格数据源
  const [total, setTotal] = useState(1); // 表格总条数，默认不要是0哦
  const [current, setCurrent] = useState(1); // 表格当前页码
  const [loading, setLoading] = useState(true); // 表格是否是加载中

  const [examinationName, setExaminationName] = useState(''); // 试卷名称
  const [examTime, setExamTime] = useState(['', '']); // 考试时间 第1个是开始时间 第2个是结束时间

  const [refreshListKey, setRefreshListKey] = useState(0); // 用于标识是不是需要刷新表格数据
  const [searchKey, setSearchKey] = useState(`${examinationName}${examTime}`); // 用于标识搜索字段在搜索前后是否发生值的变化，如果没变化则不进行搜索

  const pageSize = 15;

  const columns = [
    {
      title: '试卷名称',
      dataIndex: 'name',
      width: '55%',
    },
    {
      title: '总分',
      dataIndex: 'totalScore',
    },
    {
      title: '题数',
      dataIndex: 'subjectCount',
    },
    {
      title: '完成时间',
      dataIndex: 'createdAt',
    },
    {
      title: '得分',
      dataIndex: 'score',
      render: (text: any, record: any) => {
        return <span className={Number(text) < 60 && S.warningScore}>{text}</span>;
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text: any, record: any) => {
        return (
          <RenderExamState
            record={{
              ...record,
              id: record.examinationId,
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const params = {
      pageSize: pageSize,
      pageNum: current,
      examinationName,
      startTime: examTime[0], // 开始时间 '2020-05-24 00:00:00'
      endTime: examTime[1], // 结束时间
    };

    setLoading(true);
    getUsersExaminations(params).then((result: any) => {
      const records = result.records.map((item: any) => ({
        id: item.id,
        examinationId: item.examinationId, // 考试id
        name: item.examinationVo.name, // 试卷名称
        totalScore: item.examinationVo.totalScore, // 总分
        subjectCount: item.subjectCount, // 题数
        createdAt: item.createdAt, // 创建时间 也就是交卷时间
        score: item.score, // 得分
        userResultState: item.userResultState,
      }));

      setList(records);
      setTotal(result.count);
      setLoading(false);
    });

    // eslint-disable-next-line
  }, [current, refreshListKey]);

  return (
    <>
      <Form
        className={S.filter}
        layout="inline"
        onFinish={value => {
          const flag = `${examinationName}${examTime}`;

          if (searchKey !== flag) {
            setSearchKey(flag);
            setRefreshListKey(Math.random());
          }
        }}
      >
        <Form.Item label="试卷名称" name="examinationName">
          <Input
            value={examinationName}
            placeholder="请输入试卷名称"
            allowClear={true}
            maxLength={20}
            onChange={event => {
              // 不用onChange 改用 onBlur 主要是出于减少 render 次数考虑
              // TODO：这里为啥会触发2次render？？？不太理解
              if (examinationName !== event.target.value) {
                setExaminationName(event.target.value);
              }
            }}
          />
        </Form.Item>

        <Form.Item label="考试时间" name="startTime">
          <RangePicker
            allowEmpty={[true, true]}
            format={dateFormat}
            onChange={(dates, dateStrings) => {
              setExamTime(dateStrings);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
        </Form.Item>
      </Form>

      <Table
        loading={loading}
        rowKey="examinationId"
        dataSource={list}
        columns={columns}
        pagination={{
          showSizeChanger: false,
          total,
          pageSize,
          current,
          onChange: page => {
            setCurrent(page);
          },
        }}
      />
    </>
  );
}
