const routes = [
  {
    path: '/',
    text: '首页',
    component: 'Index',
  },
  {
    path: '/user',
    text: '用户',
    children: [
      {
        path: '/register',
        text: '注册',
        component: 'register',
      },
      {
        path: '/login',
        text: '登录',
        component: 'login',
      },
      {
        path: '/change-password',
        text: '修改密码',
        component: 'changePassword',
      },
      {
        path: '/home',
        text: '个人中心',
        component: 'home',
      },
    ],
  },
  {
    path: '/exam',
    text: '考试',
    children: [
      {
        path: '/',
        text: '考试列表',
        component: 'examList',
      },
      {
        path: '/start/:id',
        text: '进入考试',
        component: 'examStart',
      },
      {
        path: '/result/:id',
        text: '考试结果',
        component: 'result',
      },
      {
        path: '/:id',
        text: '准备考试',
        component: 'ready',
      },
    ],
  },
  {
    path: '/course',
    text: '在线课程',
    children: [
      {
        path: '/',
        text: '课程列表',
        component: 'courseList',
      },
      {
        path: '/introduce/:id',
        text: '课程介绍',
        component: 'courseIntroduce',
      },
      {
        path: '/:id',
        text: '课程详情',
        component: 'courseDetails',
      },
    ],
  },
  {
    path: '/experiment',
    text: '在线实验',
    children: [
      {
        path: '/',
        text: '实验列表',
        component: 'experimentList',
      },
      {
        path: '/introduce/:id',
        text: '实验介绍',
        component: 'experimentIntroduce',
      },
      {
        path: '/:id',
        text: '实验详情',
        component: 'experimentDetails',
      },
      {
        path: '/noResource/:id',
        text: '实验详情',
        component: 'experimentDetails',
      },
    ],
  },
  {
    path: '/forum',
    text: '论坛',
    children: [
      {
        path: '/',
        text: '论坛首页',
        component: 'forumIndex',
      },
      {
        path: '/article/add',
        text: '发布文章',
        component: 'article',
      },
      {
        path: '/article/edit/:id',
        text: '编辑文章',
        component: 'article',
      },
      {
        path: '/article/:id',
        text: '查看文章',
        component: 'articleDetails',
      },
      {
        path: '/my-article',
        text: '我的文章列表',
        component: 'myArticle',
      },
      {
        path: '/user-article-list/:userId',
        text: '用户文章列表',
        component: 'userArticle',
      },
      {
        path: '/tag-article/:tagName',
        text: '标签搜索结果',
        component: 'tagArticle',
      },
      {
        path: '/my-fans',
        text: '我的粉丝',
        component: 'myFans',
      },
      {
        path: '/my-follow',
        text: '我的关注',
        component: 'myFollow',
      },
      {
        path: '/message',
        text: '消息列表',
        component: 'myMessage',
      },
    ],
  },
  {
    path: '/order',
    text: '订单管理',
    children: [
      {
        path: '/manage',
        text: '订单列表',
        component: 'orderManage',
      },
    ],
  },
  {
    path: '/resources',
    text: '资源管理',
    children: [
      {
        path: '/manage',
        text: '资源列表',
        component: 'manage',
      },
      {
        path: '/application',
        text: '资源申请',
        component: 'application',
      },
      {
        path: '/resources-details/:id',
        text: '资源详情',
        component: 'resourcesDetails',
      },
    ],
  },
  {
    path: '/agreement',
    text: '协议',
    children: [
      {
        path: '/user-register',
        text: '用户注册协议',
        component: 'userRegister',
      },
      {
        path: '/pay',
        text: '用户付费协议',
        component: 'pay',
      },
    ],
  },
  {
    path: '/pay',
    text: '支付',
    children: [
      {
        path: '/wechat-pay',
        text: '微信支付',
        component: 'transferPay',
      },
      {
        path: '/alipay',
        text: '支付宝支付',
        component: 'transferPay',
      },
      {
        path: '/alipay/result',
        text: '支付结果',
        component: 'payResult',
      },
      {
        path: '/wechat/result',
        text: '支付结果',
        component: 'payResult',
      },
    ],
  },
  {
    path: '/403',
    text: '无权限',
    component: '403',
  },
];

module.exports = routes;
