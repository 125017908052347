import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { Button } from 'antd';

import { prefix } from '@utils/lib-request/request';
import EditorPreview from './EditorPreview';
const Showdown = require('showdown');

const Wrapper = styled.div`
  .mde-header ul.mde-header-group {
    margin: 0 1px;
    padding: 2px;
    background: #f4f6f9;
  }
  .mde-header {
    background: transparent;
  }
  .mde-header .mde-tabs button {
    padding: 4px 15px;
    margin-left: 0;
  }
  .mde-header .mde-tabs button {
    &:hover {
      color: #4db8ff;
      background: #fff;
      border-color: #4db8ff;
    }
    margin: 0 4px;
    transition: all 0.3s;
    padding: 4px 15px;
    border-radius: 4px;
    color: #515151;
    background: #fff;
    border-color: #d9d9d9;
  }

  .mde-header .mde-tabs button.selected {
    padding: 4px 15px;
    color: #249bf9;
    //background: #249bf9;
    border-color: #249bf9;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .react-mde {
    border: none;
    padding: 4px 0px;
  }
  .mde-tabs {
    position: absolute;
    bottom: 14px;
  }
  .mde-header {
    border: none;
  }
  .mde-preview {
    max-height: 300px;
    overflow: auto;
  }
  .mde-preview-content {
    padding: 0;
  }
  .mde-textarea-wrapper textarea.mde-text {
    resize: none;
    padding: 10px 0px;
  }
`;

// 笔记字符数上限
const articleMaxLength = 50000;

export const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});
const MarkdownEditor: FunctionComponent<{ onSave: Function; content: string }> = ({
  onSave,
  content,
}) => {
  const [value, setValue] = React.useState(content);

  return (
    <Wrapper>
      <EditorPreview
        value={value}
        onChange={setValue}
        action={`${prefix}/upload/course`}
        height="400px"
        defaultToolbar={{ save: false, subfield: false }}
        maxCount={50000}
      />
      <span style={{ position: 'absolute', bottom: '14px', left: '60px' }}>
        {value.length}/{articleMaxLength}
      </span>
      <Button
        type="primary"
        style={{ position: 'absolute', bottom: '14px', right: '24px' }}
        onClick={() => onSave(value)}
      >
        保存
      </Button>
    </Wrapper>
  );
};

export default MarkdownEditor;
