import React, { useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  Result,
  Statistic,
  Steps,
} from 'antd';
import { CheckCircleFilled, CloseCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import cx from 'classnames';
import { PageWrap } from '@components/MainLayout/index';
import { changePassword, checkCode } from '@pages/user/api';
import { sendCheckCode } from '@services/user';
import { sessionStorage } from '@utils/lib-storage';
import { getQueryParams } from '@utils/lib-url';
// @ts-ignore
import S from './style.module.less';

const { Step } = Steps;
const { Countdown } = Statistic;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 15 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 15 },
};

// 表单校验提示语
export const checkResultTips = {
  0: null,
  1: (
    <CheckResult
      type="right"
      text="验证码已发送，5分钟内输入有效，验证码等同于密码，请不要轻易告知他人"
    />
  ),
  2: <CheckResult type="error" text="验证码不能为空" />,
  3: <CheckResult type="error" text="验证码错误，请确认后重新输入。" />,
  4: <CheckResult type="error" text="验证码发送失败，请稍后再试" />,
  5: <CheckResult type="error" text="手机号不能为空" />,
  6: <CheckResult type="error" text="请输入正确的手机号" />,
  7: <CheckResult type="error" text="邮箱不能为空" />,
  8: <CheckResult type="error" text="请输入正确的邮箱地址" />,
};

// 是不是在倒计时 本地存储key
const isCountdownKey = 'changePasswordPageIsCountdown';

// 倒计时的开始时间 本地存储key
const countdownStartTimeKey = 'changePasswordPageCountdownStartTime';

// 修改密码一些接口需要用到的参数
let changePasswordParams = {
  channel: '', // 'email' 'phone'
  checkCodeType: '', // 注册类型'register'   忘记密码和重置密码 'forget_passwd'
  channelValue: '',
  identityCode: '',
};

// 表单校验提示语，输入框下面的小文字提示
function CheckResult(props: any) {
  const {
    type, // 校验结果类型 error错误的  right对的
    text, // 提示文案
  }: { type: 'error' | 'right'; text: string } = props;

  // 没文案不显示
  if (!text.length) {
    return null;
  }

  return (
    <div className={cx(S.tipsError, S[type])}>
      {
        {
          error: <CloseCircleFilled />,
          right: <CheckCircleFilled />,
        }[type]
      }

      <span>{text}</span>
    </div>
  );
}

function Step1(props: any) {
  // 枚举校验类型
  const checkList = [
    {
      value: 'phone',
      label: '手机验证',
    },
    {
      value: 'email',
      label: '邮箱验证',

      // @ts-ignore
      disabled: getQueryParams()?.code === 434,
    },
  ];

  const [checkType, setCheckType] = useState(checkList[0].value); // 当前选择的校验类型

  const [channelStateCode, setChannelStateCode] = useState<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>(0); // 手机和邮箱校验提示语级别
  const [codeStateCode, setCodeStateCode] = useState<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>(0); // 验证码校验提示语级别

  const [channelValue, setChannelValue] = useState(''); // 手机或者邮箱的值
  const [code, setCode] = useState(''); // 验证码的值

  const isCountdownDefaults = sessionStorage.getItem(isCountdownKey) || false; // 是不是在倒计时（本地存储）
  const countdownStartTimeDefaults = sessionStorage.getItem(countdownStartTimeKey) || Date.now();
  const isExpired = Date.now() - +sessionStorage.getItem(countdownStartTimeKey) >= 60 * 1000; // 是不是过期了，已经超过60秒了

  const [isCountdown, setIsCountdown] = useState<boolean>(isExpired ? false : isCountdownDefaults); // 如果时间过期了，则不倒计时;  如果没过期 按照原有状态
  const [countdownStartTime, setCountdownStartTime] = useState<number>(countdownStartTimeDefaults); // 倒计时的开始时间
  const currIndex = checkList.findIndex(item => item.value === checkType);

  const showTips = () => {
    Modal.warning({
      title: '手机或邮箱验证不可用',
      icon: <QuestionCircleFilled />,
      content: '请直接拨打 4009100100 联系管理员处理',
    });
  };

  // 校验手机号合法性
  const checkPhone = () => {
    // 手机号为空
    if (!channelValue.length) {
      setChannelStateCode(5);
      return false;
    }

    // 不合法的手机号
    if (!/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(channelValue)) {
      setChannelStateCode(6);
      return false;
    }

    return true;
  };

  // 校验邮箱合法性
  const checkEmail = () => {
    // 邮箱为空
    if (!channelValue.length) {
      setChannelStateCode(7);
      return false;
    }

    // 不合法的邮箱
    if (!/^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(channelValue)) {
      setChannelStateCode(8);
      return false;
    }

    return true;
  };

  // 获取验证码
  const sendCode = () => {
    // 手机校验
    if (checkType === 'phone' && !checkPhone()) {
      return;
    }

    // 邮箱校验
    if (checkType === 'email' && !checkEmail()) {
      return;
    }

    // 校验通过  重置表单校验提示
    setChannelStateCode(0);

    const params = {
      channel: checkType, // 校验类型
      checkCodeType: 'forget_passwd', // 注册类型'register'   忘记密码和重置密码 'forget_passwd'
      channelValue: channelValue,
    };

    sendCheckCode(params)
      .then((result: any) => {
        setCodeStateCode(1);

        const time = Date.now();

        // 开始倒计时 本地存储记录相关信息
        sessionStorage.setItem(isCountdownKey, true); // 标记处于倒计时状态
        sessionStorage.setItem(countdownStartTimeKey, time); // 记录倒计时的开始时间
        setIsCountdown(true);
        setCountdownStartTime(time);
      })
      .catch(() => {
        setCodeStateCode(4);
      });
  };

  // 【下一步】校验验证码是否正确
  const handleCheckCode = () => {
    // 手机校验
    if (checkType === 'phone' && !checkPhone()) {
      return;
    }

    // 邮箱校验
    if (checkType === 'email' && !checkEmail()) {
      return;
    }

    // 校验验证码
    if (!code.length) {
      setCodeStateCode(2);
      return;
    }

    // 校验通过 重置表单校验提示
    setChannelStateCode(0);
    setCodeStateCode(0);

    const params = {
      channel: checkType, // 校验类型
      checkCodeType: 'forget_passwd', // 注册类型'register'   忘记密码和重置密码 'forget_passwd'
      channelValue: channelValue, // 手机号或邮箱
      checkCode: code, // 校验码
    };

    checkCode(params)
      .then((result: any) => {
        changePasswordParams = {
          ...params,
          identityCode: result.identityCode,
        };
        props.onChangeStep(1);
      })
      .catch(() => {
        // TODO: 这里感觉用不到
        setCodeStateCode(3);
      });
  };

  // 监听倒计时结束
  const onCountdownFinish = () => {
    sessionStorage.setItem(isCountdownKey, false); // 标记处于倒计时状态
    sessionStorage.setItem(countdownStartTimeKey, 0); // 记录倒计时的开始时间

    setIsCountdown(false);
    setCountdownStartTime(0);
    setCodeStateCode(0);
  };

  const btnGetCode = (
    <Button
      disabled={!channelValue.length}
      onClick={sendCode}
      type="primary"
      style={{ margin: '0 0 0 8px' }}
    >
      获取验证码
    </Button>
  );
  const btnCountdown = (
    <Button disabled type="primary" style={{ margin: '0 0 0 8px' }}>
      {isCountdown && (
        <Countdown
          value={Date.now() + 60 * 1000 - (Date.now() - countdownStartTime)}
          onFinish={onCountdownFinish}
          format="ss秒后可重发"
          valueStyle={{ fontSize: '14px', color: '#C1C4C6' }}
        />
      )}
    </Button>
  );

  return (
    <Form {...layout} labelAlign="left" onFinish={handleCheckCode}>
      <Form.Item noStyle>
        <p className={S.tips}>为了您的账号安全，进行敏感操作前须先验证身份</p>
      </Form.Item>

      <Form.Item label="验证方式">
        <Radio.Group
          value={checkType}
          options={checkList}
          onChange={event => setCheckType(event.target.value)}
        />
      </Form.Item>

      <Form.Item
        label={checkList[currIndex].label}
        help={checkResultTips[channelStateCode]}
        validateStatus={[5, 6, 7, 8].includes(channelStateCode) ? 'error' : 'success'}
      >
        <Input
          value={channelValue}
          onChange={event => setChannelValue(event.target.value)}
          style={{ width: 230 }}
          allowClear
          placeholder="请输入"
        />
        <Button type="link" onClick={showTips} style={{ margin: '0 0 0 8px' }}>
          当前手机或邮箱不可用？
        </Button>
      </Form.Item>

      <Form.Item
        label="验证码"
        help={checkResultTips[codeStateCode]}
        validateStatus={[2, 3, 4].includes(codeStateCode) ? 'error' : 'success'}
      >
        <Input
          value={code}
          maxLength={6}
          onChange={event => setCode(event.target.value)}
          style={{ width: 120 }}
          placeholder="6位数字验证码"
        />
        {isCountdown ? btnCountdown : btnGetCode}
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button disabled={!channelValue.length && !code.length} type="primary" htmlType="submit">
          下一步
        </Button>
      </Form.Item>
    </Form>
  );
}

function Step2(props: any) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activationCheck, setActivationCheck] = useState(false);
  const [stateCode, setStateCode] = useState<0 | 1>(0);
  const checkResultTips = {
    0: null,
    1: <CheckResult type="error" text="两次输入的密码不一致" />,
  };

  const verificationTipsJSX = (
    <>
      <CheckResult text="密码长度超过8位" type={password.length > 8 ? 'right' : 'error'} />
      <CheckResult
        text="必须包含英文和数字"
        type={/[\d]/gi.test(password) && /[a-zA-Z]/gi.test(password) ? 'right' : 'error'}
      />
    </>
  );

  const handleInputChange = (key: string) => (event: any) => {
    const setStateFunction: { [key: string]: Function } = {
      password: setPassword,
      confirmPassword: setConfirmPassword,
    };

    // 这个思路不错吧
    setStateFunction[key](event.target.value);
    setStateCode(0);
  };

  const handleChangePassword = () => {
    if (confirmPassword !== password) {
      setStateCode(1);
      return;
    }

    if (!(password.length > 8) || (!/[\d]/gi.test(password) && /[a-zA-Z]/gi.test(password))) {
      return;
    }

    const params = {
      ...changePasswordParams,
      passwd: password, // 密码
      passwdRepeat: confirmPassword, // 确认密码
    };

    changePassword(params).then((result: any) => {
      props.onChangeStep(2);
    });
  };

  return (
    <Form {...layout} labelAlign="left" onFinish={handleChangePassword}>
      <Form.Item label="新密码" name="password">
        <Popover
          placement="right"
          visible={!!password.length && activationCheck}
          content={verificationTipsJSX}
        >
          <Input.Password
            value={password}
            onChange={handleInputChange('password')}
            onFocus={() => setActivationCheck(true)}
            style={{ width: 220 }}
            placeholder="请输入新密码..."
          />
        </Popover>
      </Form.Item>

      <Form.Item label="确认新密码" name="confirmPassword" help={checkResultTips[stateCode]}>
        <Input.Password
          value={confirmPassword}
          onChange={handleInputChange('confirmPassword')}
          style={{ width: 220 }}
          placeholder="请确认新密码..."
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button
          disabled={!password.length || !confirmPassword.length}
          type="primary"
          htmlType="submit"
        >
          确定
        </Button>
      </Form.Item>
    </Form>
  );
}

function Step3(params: any) {
  return (
    <Result
      status="success"
      title="修改成功，请牢记新的密码"
      extra={[
        <Button href="/user/login" type="primary" key="重新登录">
          重新登录
        </Button>,
      ]}
    />
  );
}

export default function (props: any) {
  const [currentStep, setCurrentStep] = useState(0);

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <PageWrap className={S.pageWrap}>
      <Steps current={currentStep}>
        <Step title="验证身份" />
        <Step title="修改密码" />
        <Step title="完成" />
      </Steps>

      <Divider />

      {
        [
          <Step1 key="Step1" onChangeStep={handleChangeStep} />,
          <Step2 key="Step2" onChangeStep={handleChangeStep} />,
          <Step3 key="Step3" onChangeStep={handleChangeStep} />,
        ][currentStep]
      }
    </PageWrap>
  );
}
