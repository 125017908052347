import { GET, POST, prefix } from '@utils/lib-request/request';

// 加载论坛首页
export const getForumHome = (params: any) => GET(`${prefix}/forum/index`, params);

// 积分排行榜
export const getPointRanking = () => GET(`${prefix}/forum/pointRanking`);

// 获取标签列表
export const getTags = () => GET(`${prefix}/forum/typeList`);

// 常用标签
export const getCommonlyusedTags = () => GET(`${prefix}/forum/usedTags`);

// 根据作者id获取作者信息
export const getUserOldDetail = (uid: any) => GET(`${prefix}/forum/userOld/${uid}`);

//根据作者ID获取文章列表
export const getUserOldArticleList = (uid: any, params: any) =>
  GET(`${prefix}/forum/userOld/article/${uid}`, params);

// 查询文章详情
export const getArticleDetail = (id: any, params: any) =>
  GET(`${prefix}/forum/article/${id}`, params);

// 根据作者id获取关注列表
export const getFollowList = (uid: any, params: any) =>
  GET(`${prefix}/forum/userOld/follower/${uid}`, params);

// 根据作者id获取粉丝列表
export const getFansList = (uid: any, params: any) =>
  GET(`${prefix}/forum/userOld/fans/${uid}`, params);

// 关注用户
export const followUser = (uid: any) => POST(`${prefix}/forum/user-rest/follow/${uid}`);

// 取消关注
export const cancelFollowUser = (uid: any) =>
  POST(`${prefix}/forum/user-rest/cancel-follow/${uid}`);

// 更改昵称
export const updateNickname = (params: any) =>
  POST(`${prefix}/forum/user-rest/update-info`, params);

//评论回复
export const createReply = (params: any) => POST(`${prefix}/forum/comment-rest/create`, params);

// 点赞文章
export const postHasApproval = (postsId: any) =>
  POST(`${prefix}/forum/approval-rest/create/${postsId}`);

// 取消点赞文章
export const postNoApproval = (postsId: any) =>
  POST(`${prefix}/forum/approval-rest/delete/${postsId}`);

// 根据标签名获取文章列表
export const getTagNameList = (name: any, params: any) =>
  GET(`${prefix}/forum/tagOld/${name}`, params);

// 获取消息列表
export const getMessageList = (params: any) => GET(`${prefix}/forum/message`, params);

// 获取文章全部分类
export const postArticleAllType = () => POST(`${prefix}/forum/article-rest/editArticleTypes`);

// 获取全部标签
export const postAllTags = () => POST(`${prefix}/forum/tag-rest/all`);

// 编辑的时候获取文章信息
export const postArticleRest = (id: number) => POST(`${prefix}/forum/article-rest/${id}`);

// 保存文章信息
export const saveArticle = (params: any) => POST(`${prefix}/forum/article-rest/save`, params);

// 删除文章
export const deleteArticle = (id: any) => POST(`${prefix}/forum/posts-rest/delete/${id}`);

// 保存文章中的图片
export const saveArticleImg = (params: any) =>
  POST(`${prefix}/forum/file-rest/upload/articleImg`, params);
