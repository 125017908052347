import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb, Form, Radio, Select, Checkbox, Button } from 'antd';
import cx from 'classnames';
import { PageWrap } from '@components/MainLayout/index';
import Pay, {
  payEvent,
  onPaySuccessEventKeyWord,
  onFreePaySuccessEventKeyWord,
} from '@components/Pay';
import { getResourcesList, getTotalPrice } from '@pages/resources/api';
// @ts-ignore
import S from './style.module.less';

// 单个服务器环境
function ConfigItem(props: any) {
  const {
    bandwidth,
    cpuCount,
    cpuType,
    id,
    ipType,
    level, // 等级（0低配 1标配 2高配）
    memory,
    price,
    currServer, // 选中的服务器
    onSelect,
  } = props;

  const onClick = () => {
    if (typeof onSelect === 'function') {
      onSelect({
        id,
        price,
      });
    }
  };

  return (
    <div
      onClick={onClick}
      className={cx(
        S.configItem,
        [S.poor, S.standard, S.rich][level],
        currServer.id === id && S.select,
      )}
    >
      <dl className={S.title}>
        <dt>{`服务器配置-${['低配', '标配', '高配'][level]}版`}</dt>
        <dd>配置满足用户的操作简单一句话说明</dd>
      </dl>

      <div className={S.attributeList}>
        <div className={S.row}>
          <span className={S.attr}>CPU: </span>
          <span className={S.value}>{`${cpuCount}核`}</span>
        </div>

        <div className={S.row}>
          <span className={S.attr}>内存: </span>
          <span className={S.value}>{`${memory}GB`}</span>
        </div>

        <div className={S.row}>
          <span className={S.attr}>带宽: </span>
          <span className={S.value}>{bandwidth}</span>
        </div>

        <div className={S.row}>
          <span className={S.attr}>IP类型:</span>
          <span className={S.value}>{ipType}</span>
        </div>

        <div className={cx(S.row, S.full)}>
          <span className={S.attr}>处理器型号: </span>
          <span className={S.value}>{cpuType}</span>
        </div>
      </div>

      <div className={S.priceWrap}>
        <span className={S.price}>{price}</span>
      </div>
    </div>
  );
}

export default function (props: any) {
  const history = useHistory();
  const [serverList, setServerList] = useState([]); // 服务器列表
  const [blockchainMap, setBlockchainMap] = useState<any>({}); // 链信息 key-value的键值对形式 方便快速查找
  const [currServer, setCurrServer] = useState<any>({}); // 当前选中的服务器信息
  const [blockchainOptions, setBlockchainOptions] = useState<any>([]); // 区块链类型选项
  const [nodeOptions, setNodeOptions] = useState<any>([]); // 区块链加密节点选项
  const [blockchainFrom] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState<any>(); // 服务器总价

  useEffect(() => {
    payEvent.on(onPaySuccessEventKeyWord, handlePaySuccess);
    payEvent.on(onFreePaySuccessEventKeyWord, handlePaySuccess);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getResourcesList().then((result: any) => {
      const nodeOptions = result.nodeCountList.map((item: any) => ({
        label: item,
        value: item,
      }));

      const blockchainOptions = result.blockchainTypeList.map((item: any) => ({
        label: item.typeName,
        value: item.id,
      }));

      const blockchainMap = {};

      result.blockchainTypeList.forEach((item: any) => {
        // @ts-ignore
        blockchainMap[item.id] = item;
      });

      setServerList(result.server);
      setBlockchainMap(blockchainMap);
      setBlockchainOptions(blockchainOptions);
      setNodeOptions(nodeOptions);

      // 默认选中第一个服务器
      setCurrServer(result.server[0]);
    });
    // eslint-disable-next-line
  }, []);

  // 监听当前服务器信息变化  实时计算价格
  useEffect(() => {
    initBlockchain();
    handleTotalPrice();
    // eslint-disable-next-line
  }, [currServer]);

  const handlePaySuccess = () => {
    history.push({
      pathname: '/resources/manage',
    });
  };

  const initBlockchain = () => {
    // 默认选中第一个区块链类型
    blockchainFrom.setFieldsValue({
      blockchainTypeId: blockchainOptions[0]?.value,
    });

    // 默认选中第一个区块链加密节点
    blockchainFrom.setFieldsValue({
      blockchainNodeCount: nodeOptions[0]?.value,
    });
  };

  const onSelect = (serverInfo: any) => {
    setCurrServer(serverInfo);
    initBlockchain();
  };

  const handleTotalPrice = () => {
    const { blockchainTypeId, blockchainNodeCount } = blockchainFrom.getFieldsValue();

    const params = {
      blockchainNodeCount,
      blockchainTypeId,
      serverSpecId: currServer.id,
    };

    if (!blockchainNodeCount || !blockchainTypeId || !currServer.id) {
      return;
    }

    getTotalPrice(params).then((result: any) => {
      setTotalPrice(result);
    });
  };

  return (
    <PageWrap>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/resources/manage">资源管理</a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>资源申请</Breadcrumb.Item>
      </Breadcrumb>

      <Form onValuesChange={handleTotalPrice} onFinish={handleTotalPrice} form={blockchainFrom}>
        <div className={cx(S.moduleItem, S.module1)}>
          <p className={S.moduleTitle}>申请资源</p>

          <article className={S.desc}>产品说啥我写啥</article>
        </div>

        <div className={cx(S.moduleItem, S.module2)}>
          <p className={S.moduleTitle}>服务器环境</p>

          <div className={S.configList}>
            {serverList.map((item: any) => (
              <ConfigItem key={item.id} onSelect={onSelect} currServer={currServer} {...item} />
            ))}
          </div>
        </div>

        <div className={cx(S.moduleItem, S.module3)}>
          <p className={S.moduleTitle}>区块链环境</p>

          <Form.Item
            label="区块链类型"
            name="blockchainTypeId"
            rules={[{ required: true, message: '请选择区块链类型' }]}
          >
            <Select
              options={blockchainOptions}
              placeholder="请选择区块链类型"
              style={{ width: '180px' }}
            />
          </Form.Item>

          <Form.Item dependencies={['blockchainTypeId']} noStyle>
            {() => {
              const blockchainTypeId = blockchainFrom.getFieldsValue().blockchainTypeId;
              const encryptionAlgorithm = blockchainMap[blockchainTypeId]?.encryptionAlgorithm;

              blockchainFrom.setFieldsValue({
                encryptionAlgorithm: encryptionAlgorithm,
              });

              if ([0, 1].includes(encryptionAlgorithm)) {
                return (
                  <Form.Item label="区块链加密算法" name="encryptionAlgorithm">
                    <Radio.Group disabled>
                      <Radio value={0}>Secp256k1</Radio>
                      <Radio value={1}>SM2</Radio>
                    </Radio.Group>
                  </Form.Item>
                );
              }
            }}
          </Form.Item>

          <Form.Item
            label="区块链加密节点"
            name="blockchainNodeCount"
            rules={[{ required: true, message: '请选择区块链加密节点' }]}
          >
            <Select
              options={nodeOptions}
              placeholder="请选择区块链加密节点"
              style={{ width: '200px' }}
            />
          </Form.Item>
        </div>

        <div className={S.confirmInfo}>
          <div className={S.row}>
            <div className={S.priceInfo}>
              <span className={S.attr}>总金额:</span>
              <span className={S.value}>{totalPrice || '计算中...'}</span>
            </div>
          </div>

          <div className={S.row}>
            <p className={S.tips}>注：本课程为虚拟内容服务，购买后不支持退款、转让</p>
          </div>

          <div className={S.row}>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error('需要同意北航实验用户付费协议才可购买!')),
                },
              ]}
            >
              <Checkbox className={S.clause}>
                <span>同意</span>
                <Button type="link">
                  <a href="/agreement/user-register" target="_blank" rel="noopener noreferrer">
                    《北航实验用户付费协议》
                  </a>
                </Button>
              </Checkbox>
            </Form.Item>
          </div>
        </div>

        <div className={S.tools}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Pay
                isFree={totalPrice <= 0}
                stop={!blockchainFrom.getFieldsValue().agreement}
                source={3}
                serverSpecId={currServer.id}
                applyType={0}
                blockchainTypeId={blockchainFrom.getFieldsValue().blockchainTypeId}
                blockchainNodeCount={blockchainFrom.getFieldsValue().blockchainNodeCount}
              />
            )}
          </Form.Item>
        </div>
      </Form>
    </PageWrap>
  );
}
