import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { getFabricUrl } from '../api';
// @ts-ignore
import S from './style.module.less';

interface FabricAreaProps {
  runTime: any;
  learningDocUrl: string;
  fabricChainInfoVoList: any[];
  onCloseExperiment(): void;
}

export default function FabricArea(props: FabricAreaProps) {
  const [option, setOption] = useState<any>();

  const getOption = async () => {
    const result = await getFabricUrl();
    setOption(result);
  };

  useEffect(() => {
    getOption();
  }, []);

  return (
    <div className={S.fabricWrap}>
      {option?.state === 'Running' ? (
        <iframe className={S.fabricArea} title={option?.vsCodeName} src={option?.url} />
      ) : (
        <Empty
          style={{
            width: '100%',
            height: '100%',
          }}
          description="vscode启动中"
        />
      )}
    </div>
  );
}
