import { GET, POST, prefix } from '@utils/lib-request/request';

// 获取考试列表
export const getExaminations = (params: any) => GET(`${prefix}/examinations`, params);

// 获取考试详情
export const getExaminationsInfo = (id: any) => GET(`${prefix}/examinations/${id}`);

// 开始答题
export const startExam = (id: any) => POST(`${prefix}/examinations/${id}/start`);

// 考试提交
export const submitExam = (params: any) => POST(`${prefix}/examinations/submit`, params);

// 获取考试结果
export const getExamResult = (id: any) => GET(`${prefix}/examinations/${id}/result`);
