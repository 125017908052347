import React, { useState, useEffect } from 'react';
import { Select, Tooltip } from 'antd';
import moment from 'moment';
// @ts-ignore
import { CanvasGraph } from 'calendar-graph';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';
import { getRoundNumber } from '@utils/lib-random';
import { getLearningRecords } from '@pages/user/api';
// @ts-ignore
import S from './style.module.less';

const colors = [
  {
    tips: '有效学习时间 < 30分钟',
    color: '#F4F6F9',
  },
  {
    tips: '30分钟 <= 有效学习时间 < 60分钟',
    color: '#E6F7FF',
  },
  {
    tips: '60分钟 <= 有效学习时间 < 90分钟',
    color: '#BAE7FF',
  },
  {
    tips: '90分钟 <= 有效学习时间 < 120分钟',
    color: '#69C0FF',
  },
  {
    tips: '120分钟 <= 有效学习时间 < 150分钟',
    color: '#40A9FF',
  },
  {
    tips: '有效学习时间 >= 150分钟',
    color: '#1890FF',
  },
];

export default function CalendarGraph(props: any) {
  const userInfo = sessionStorage.getItem(userInfoKey);
  const startYear = moment(userInfo.createdAt).year();
  const endYear = moment().year();
  const [yearOptions, setYearOptions] = useState<any[]>([]);
  const [currYear, setCurrYear] = useState(endYear);
  const idSelector = `canvas-root-${getRoundNumber(0, 1000000)}`;
  const [dataSource, setDataSource] = useState<any>([]);
  const [onlineDays, setOnlineDays] = useState<any>();

  // 计算年份列表
  useEffect(() => {
    const options = new Array(endYear - startYear + 1).fill('').map((item, index) => ({
      label: endYear - index,
      value: endYear - index,
    }));

    setYearOptions(options);
  }, [startYear, endYear]);

  // 切换当前年份
  useEffect(() => {
    getLearningRecords(currYear).then((result: any) => {
      const dataSource = result.learningRecordVoList.map((item: any) => ({
        date: item.createdAt,
        count: item.onlineTime,
      }));

      setDataSource(dataSource);
      setOnlineDays(result.onlineDays);
    });
  }, [currYear]);

  // 绘图
  useEffect(() => {
    new CanvasGraph(`#${idSelector}`, dataSource, {
      size: 10,
      space: 1,
      styleOptions: {
        textColor: '#959494',
        fontSize: '12px',
      },
      startDate: new Date(`${currYear}-01-01`),
      endDate: new Date(`${currYear}-12-31`),
      colorFun: (v: any) => {
        // 有效学习时间 >= 150分钟
        if (v.count >= 150) {
          return colors[5].color;
        }

        // 120分钟 <= 有效学习时间 < 150分钟
        if (v.count >= 120) {
          return colors[4].color;
        }

        // 90分钟 <= 有效学习时间 < 120分钟
        if (v.count >= 90) {
          return colors[3].color;
        }

        // 60分钟 <= 有效学习时间 < 90分钟
        if (v.count >= 60) {
          return colors[2].color;
        }

        // 30分钟 <= 有效学习时间 < 60分钟
        if (v.count >= 30) {
          return colors[1].color;
        }

        // 有效学习时间 < 30分钟
        return colors[0].color;
      },
    });
    // eslint-disable-next-line
  }, [dataSource]);

  return (
    <div className={S.calendarGraph}>
      <div className={S.aside}>
        <p className={S.tips}>
          总累计学习 <em>{onlineDays}</em> 天
        </p>

        <Select
          onChange={value => {
            setCurrYear(value);
          }}
          className={S.select}
          bordered={false}
          value={currYear}
          options={yearOptions}
        />
      </div>

      <canvas id={idSelector} />

      <div className={S.footTool}>
        <div className={S.example}>
          {colors.map(item => (
            <Tooltip title={item.tips}>
              <span
                key={item.color}
                style={{ background: `${item.color}` }}
                className={S.item}
              ></span>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}
