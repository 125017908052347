import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';
import { getUserOldDetail } from '@pages/forum/api';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const { className } = props;
  const [userInfo, setUserInfo] = useState<any>();

  useEffect(() => {
    handleGetUserOldDetail();
  }, []);

  const handleGetUserOldDetail = () => {
    const userInfo = sessionStorage.getItem(userInfoKey);

    // 说明是未登入
    if (!userInfo?.id) {
      return;
    }

    getUserOldDetail(userInfo.id).then((result: any) => {
      setUserInfo(result.user);
    });
  };

  if (!userInfo) {
    return null;
  }

  return (
    <div className={cx(S.userOverview, className)}>
      <div className={S.userInfo}>
        <Avatar src={userInfo.avatar} icon={<UserOutlined />} size={48} />

        <div className={S.aside}>
          <p className={S.userName}>{userInfo.nickname || `用户${userInfo.id}`}</p>
          <p>{`${userInfo.point}声望`}</p>
        </div>
      </div>

      <div className={S.asideInfo}>
        <Link to="/forum/my-article" className={S.item}>
          <span className={S.value}>{userInfo.postsCount}</span>
          <span className={S.attr}>文章</span>
        </Link>

        <Link to="/forum/my-follow" className={S.item}>
          <span className={S.value}>{userInfo.followerCount}</span>
          <span className={S.attr}>关注</span>
        </Link>

        <Link to="/forum/my-fans" className={S.item}>
          <span className={S.value}>{userInfo.fansCount}</span>
          <span className={S.attr}>粉丝</span>
        </Link>
      </div>
    </div>
  );
}
