import { PageWrap } from '@components/MainLayout';
import { Affix, Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTagNameList } from '../api';
import ArticleList from '../components/ArticleList';
import ArticleTags from '../components/ArticleTags';
// @ts-ignore
import S from './style.module.less';

export default function TagArticle(props: any) {
  const { tagName } = useParams<any>();

  const [pageRequest, setPageRequest] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [articleList, setArticleList] = useState([]);
  const [total, settotal] = useState();

  const getDataSource = async () => {
    const result: any = await getTagNameList(tagName, {
      ...pageRequest,
    });
    setArticleList(result.postsList);
    settotal(result.pager?.count);
  };

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line
  }, [tagName, pageRequest]);

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{tagName}</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.contentWrap}>
        <div className={S.mainWrap}>
          <div className={S.titleWrap}>
            <p className={S.tips}>
              <em>{tagName}</em>
              <span>共：{total}篇文章</span>
            </p>
          </div>
          <ArticleList
            articleList={articleList}
            total={total}
            pageRequest={pageRequest}
            setPageRequest={setPageRequest}
          />
        </div>

        <Affix offsetTop={64 + 20}>
          <div className={S.asideWrap}>
            <ArticleTags className={S.moduleItem} />
          </div>
        </Affix>
      </div>
    </PageWrap>
  );
}
