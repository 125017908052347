import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { PageWrap } from '@components/MainLayout';
import { ReactComponent as Icon38 } from '@images/icon/icon-38.svg';
import { getPayResult } from '@pages/pay/api';
import { getQueryParams } from '@utils/lib-url';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const [payResult, setPayResult] = useState();
  const path = props.match.path;

  useEffect(() => {
    const params = {
      // @ts-ignore
      orderCode: getQueryParams()['out_trade_no'],

      // @ts-ignore
      platform: {
        '/pay/alipay/result': 'alipay',
        '/pay/wechat/result': 'wx',
      }[path],
    };

    getPayResult(params).then((result: any) => {
      // 支付状态 0待支付 1支付成功 2支付失败
      setPayResult(result.status);
    });
  }, [path]);

  const renderPaySuccess = () => {
    return (
      <Result
        status="success"
        title="支付成功"
        icon={<Icon38 />}
        subTitle="开启你的学习旅程"
        extra={[
          <Button type="primary" key="buy">
            <Link to="/order/manage">查看订单</Link>
          </Button>,
        ]}
      />
    );
  };

  const renderPayError = () => {
    return (
      <Result status="error" title="支付失败" subTitle="订单未能支付成功，你可以稍后重新发起支付" />
    );
  };

  return (
    <PageWrap className={S.pageWrap}>
      {payResult === 1 && renderPaySuccess()}
      {payResult === 2 && renderPayError()}
    </PageWrap>
  );
}
