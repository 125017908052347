import { GET, POST, PUT, prefix } from '@utils/lib-request/request';
import { Key, User } from '../../types/users';

export const register = (params: any) => POST(`${prefix}/regist`, params);
export const login = (params: any) => POST<any, User>(`${prefix}/login`, params);

// 获取用户详情
export const getUsersDetail = () => GET<any, User>(`${prefix}/users/detail`);

// 获取用户课程
export const getUsersCourses = (params: any) => GET<any, User>(`${prefix}/users/courses`, params);

// 获取用户实验列表
export const getUsersExperiments = (params: any) =>
  GET<any, User>(`${prefix}/users/experiments`, params);

// 获取用户考试列表
export const getUsersExaminations = (params: any) =>
  GET<any, User>(`${prefix}/users/examinations`, params);

// 校验验证码
export const checkCode = (params: any) => POST<any, User>(`${prefix}/valid/check_code`, params);

// 忘记密码 和 重置密码
export const changePassword = (params: any) => POST<any, User>(`${prefix}/forget`, params);

export const getKeys = () => POST<any, Key[]>(`${prefix}/find/key`);

// 更新用户头像
export const updateAvatar = (params: any) => PUT<any, User>(`${prefix}/users/avatar`, params);

// 查询学习记录
export const getLearningRecords = (year: any) =>
  GET<any, User>(`${prefix}/learningRecordDetail/${year}`);
