import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Button, Input, Select, Form, message, Row, Col, Space } from 'antd';
import { PageWrap } from '@components/MainLayout';
import Editor from 'for-editor';
// @ts-ignore
import S from './style.module.less';
import {
  postArticleAllType,
  postAllTags,
  postArticleRest,
  saveArticle,
  saveArticleImg,
} from '../api';
import { useHistory, useParams } from 'react-router-dom';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';

// 文章字符数上限
const articleMaxLength = 50000;

export default function (props: any) {
  const { id } = useParams<any>();
  const history = useHistory();
  const editor = useRef<any>();

  const [formInstance] = Form.useForm();
  const [typeList, setTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [reqLoad, setReqLoad] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [markdownContentLength, setMarkdownContentLength] = useState(0);

  // 保存文章
  const handleSavearticle = () => {
    setReqLoad(true);
    formInstance
      .validateFields()
      .then(async (values: any) => {
        const result: any = await saveArticle({
          ...values,
          headImg: '',
          htmlContent: '',
          contentType: 'MARKDOWN',
          id: id || null,
        });
        setReqLoad(false);
        if (result < 0) {
          message.error('保存失败');
          return;
        }
        message.success('保存成功');
        setIsSave(true);
        setTimeout(() => {
          history.push(`/forum/article/${result}`);
        }, 1500);
      })
      .catch(e => {
        setReqLoad(false);
      });
  };

  // 获取文章详情
  const getArticle = async () => {
    if (!id) {
      return;
    }
    const result: any = await postArticleRest(id);
    const { markdownContent, tags, title, typeId } = result || {};
    formInstance.setFieldsValue({
      markdownContent,
      tagIds: tags.map((e: any) => e.id),
      title,
      typeId,
    });
    setMarkdownContentLength(markdownContent.length);
  };

  // 获取可选择的标签列表
  const getTypeList = async () => {
    const result: any = await postArticleAllType();
    setTypeList(result);
  };

  // 获取可选择的全部标签列表
  const getTagList = async () => {
    const result: any = await postAllTags();
    setTagList(result);
  };

  // 上传图片
  const handleChangeImg = async (file: any) => {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('code', '1');
    const result: any = await saveArticleImg(formdata);
    if (result) {
      message.success('图片上传成功');
      editor.current?.$img2Url(file.name, result);
    }
  };

  useEffect(() => {
    const canEdit = sessionStorage.getItem(userInfoKey)?.id;
    if (!canEdit) {
      history.replace('/forum');
      return;
    }
    getTypeList();
    getTagList();
    getArticle();
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>文章</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.contentWrap}>
        <Form form={formInstance} labelCol={{ span: 2 }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="title"
                label="标题"
                rules={[
                  { required: true, message: '请输入标题' },
                  {
                    max: 50,
                    message: '标题最多输入50个字符',
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value.match(/^[ ]*$/)) {
                        callback('标题不允许全部为空');
                      } else {
                        callback();
                      }
                    },
                    message: '标题不允许全部为空',
                  },
                ]}
              >
                <Input max={50} placeholder="请输入文章标题，最多50字" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="typeId"
                label="类型"
                rules={[{ required: true, message: '请选择类型' }]}
                labelCol={{ span: 6 }}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  style={{ width: 200 }}
                  options={(typeList || []).map((item: any) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  placeholder="请选择类型"
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="tagIds"
                label="标签"
                rules={[
                  { required: true, message: '请选择标签' },
                  {
                    validator: (rule, value, callback) => {
                      if (value.length > 5) {
                        callback('最多选择五个标签');
                      } else {
                        callback();
                      }
                    },
                    message: '最多选择五个标签',
                  },
                ]}
                labelCol={{ span: 4 }}
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  placeholder="请选择标签"
                  filterOption={(inputValue: any, option: any) =>
                    option?.label?.includes(inputValue) || option?.children?.includes(inputValue)
                  }
                >
                  {tagList.map((item: any) => {
                    return (
                      <Select.OptGroup label={item.groupName} key={item.groupName}>
                        {item.tagList.map((desc: any) => {
                          return (
                            <Select.Option value={desc.id} key={desc.id}>
                              {desc.name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="文章内容" />
            </Col>
            <Col span={24}>
              <Form.Item
                name="markdownContent"
                rules={[
                  { required: true, message: '请输入文章内容' },
                  { max: articleMaxLength, message: `文章最多输入${articleMaxLength}字` },
                ]}
              >
                <Editor
                  ref={e => (editor.current = e)}
                  lineNum={0}
                  placeholder={`请输入文章内容，最多输入${articleMaxLength}字`}
                  addImg={handleChangeImg}
                  style={{ boxShadow: 'none' }}
                  onChange={e => {
                    setMarkdownContentLength(e.length);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space className={S.contentFooter} size={20}>
            <span>
              {markdownContentLength}/{articleMaxLength}
            </span>
            <Button type="primary" onClick={handleSavearticle} loading={reqLoad} disabled={isSave}>
              保存文章
            </Button>
          </Space>
        </Form>
      </div>
    </PageWrap>
  );
}
