import React, { useEffect, useState } from 'react';
import { Breadcrumb, Affix } from 'antd';
import { PageWrap } from '@components/MainLayout';
import ArticleList from '../components/ArticleList';
import ArticleTags from '../components/ArticleTags';
import { sessionStorage } from '@utils/lib-storage';
// @ts-ignore
import S from './style.module.less';
import { getUserOldArticleList } from '../api';
import { userInfoKey } from '@pages/user/login';

export default function (props: any) {
  const [pageRequest, setPageRequest] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [articleList, setArticleList] = useState([]);
  const [total, settotal] = useState();

  const getDataSource = async () => {
    const userInfo: any = sessionStorage.getItem(userInfoKey);
    const { id } = userInfo;
    if (!id) {
      return null;
    }
    const result: any = await getUserOldArticleList(id, {
      ...pageRequest,
    });
    setArticleList(result.articleList);
    settotal(result.pager?.count);
  };

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line
  }, [pageRequest]);

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>我的文章</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.contentWrap}>
        <div className={S.mainWrap}>
          <div className={S.titleWrap}>
            <p className={S.tips}>
              <em>我的文章</em>
              <span>共：{total}篇文章</span>
            </p>
          </div>
          <ArticleList
            articleList={articleList}
            total={total}
            pageRequest={pageRequest}
            setPageRequest={setPageRequest}
          />
        </div>

        <Affix offsetTop={64 + 20}>
          <div className={S.asideWrap}>
            <ArticleTags className={S.moduleItem} />
          </div>
        </Affix>
      </div>
    </PageWrap>
  );
}
