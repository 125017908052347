/**
 * 获取url上的查询参数（如果参数值是数字类型，会自动转换成number类型，将不再是字符串）
 *
 * example: getQueryParams('https://www.baidu.com/?color=3&name=清华')
 * return: {color: 3, name: '清华'}
 *
 * example: getQueryParams('https://www.baidu.com')
 * return: {}
 *
 * @param {string} url 不传递则表示获取当前url
 * @returns {object} url 参数对象集合
 */
/*eslint-disable*/
export const getQueryParams = (url: string = location.search): object => {
  let params = {};

  if (!url.includes('?')) {
    return params;
  }

  url
    .split('?')[1]
    .split('&')
    .map(item => {
      const [key, value] = item.split('=');

      // @ts-ignore
      params[key] = Number(value).toString() === 'NaN' ? decodeURI(value) : Number(value);
    });

  return params;
};

export const examPageLink = (props: { userResultState: 0 | 1 | 2 | 3; id: number | string }) => {
  const { userResultState, id } = props;

  // @ts-ignore
  const examLink = {
    0: {
      pathname: `/exam/${id}`,
      text: '开 始',
    }, //未进行考试
    1: {
      pathname: `/exam/start/${id}`,
      text: '继 续',
    }, //考试进行中
    2: {
      pathname: ``,
      text: '等待评分',
    }, //考试了，但是没出结果
    3: {
      pathname: `/exam/result/${id}`,
      text: '查看',
    }, //有考试结果
  }[userResultState];

  return examLink || { pathname: '', text: '' };
};
