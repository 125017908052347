module.exports = {
  breakpoints: ['667px', '1000px'],
  space: [8, 12, 16, 24, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 36],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    serif: 'athelas, georgia, times, serif',
    sansSerif:
      '-apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 8, 16, 9999, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    accent: '#249BF9',
    variant: '#52C41A',
    fcMain: '#515151',
    fcSecondary: '#767676',
    black: '#000',
    nearBlack: '#111',
    darkGray: '#202020',
    midGray: '#555',
    gray: ' #777',
    silver: '#999',
    lineGray: '#E6E6E6',
    lightSilver: '#F4F4F4',
    moonGray: '#b9b9b9',
    lightGray: '#EDEEEC',
    nearWhite: '#f4f4f4',
    bg: '#f8f8f8',
    bg2: '#f1f1f1',
    white: '#fff',
    transparent: 'transparent',
    blacks: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)',
    ],
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
    darkRed: '#e7040f',
    red: '#ff4136',
    lightRed: '#ff725c',
    orange: '#ff6300',
    gold: '#ffb700',
    yellow: '#ffd700',
    lightYellow: '#fbf1a9',
    purple: '#5e2ca5',
    lightPurple: '#a463f2',
    darkPink: '#d5008f',
    hotPink: ' #ff41b4',
    pink: '#ff80cc',
    lightPink: '#ffa3d7',
    darkGreen: '#137752',
    green: '#19a974',
    lightGreen: '#9eebcf',
    navy: '#001b44',
    darkBlue: '#00449e',
    blue: '#357edd',
    lightBlue: '#96ccff',
    lightestBlue: '#cdecff',
    washedBlue: '#f6fffe',
    washedGreen: '#e8fdf5',
    washedYellow: '#fffceb',
    washedRed: '#ffdfdf',
  },
};
