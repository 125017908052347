import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { getQueryParams } from '@utils/lib-url';

export default function (props: any) {
  const history = useHistory();

  return (
    <Result
      style={{
        paddingTop: '120px',
      }}
      status="404"
      // @ts-ignore
      subTitle={getQueryParams()?.message}
      extra={
        <Button
          type="primary"
          onClick={() => {
            history.goBack();
          }}
        >
          返回上一页
        </Button>
      }
    />
  );
}
