import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Result, Tooltip } from 'antd';
import SubjectItem from '@components/SubjectItem';
import ExamPanel from '@components/ExamPanel';
import { PageWrap } from '@components/MainLayout/index';
import { startExam } from '@pages/exam/api';
import { sessionStorage } from '@utils/lib-storage';
import { userInfoKey } from '@pages/user/login';
// @ts-ignore
import S from '../result/style.module.less';

export default function (props: any) {
  const [examInfo, setExamInfo] = useState<any>(); // 考试信息
  const examId = props.match.params.id; // 考试id
  const userInfo = sessionStorage.getItem(userInfoKey);
  const questionAnswerKey = `questionAnswerKey-${userInfo?.id}-${examId}`;
  const prevQuestionAnswer = sessionStorage.getItem(questionAnswerKey);
  const [questionAnswer, setQuestionAnswer] = useState<any>(prevQuestionAnswer || {}); // 用户答题

  useEffect(() => {
    startExam(examId).then((result: any) => {
      const {
        endTime, // 考试结束时间
        currentTime, // 服务器当前时间
        examinationQuestionAndAnswerVos, // 试题列表
        name, // 试卷名称
        userResultState, //
        userName,
        studentNumber,
      } = result;

      setExamInfo({
        name,
        userResultState,
        endTime,
        currentTime,
        userName,
        studentNumber,
        questions: examinationQuestionAndAnswerVos,
      });
    });
  }, [examId]);

  // 处理用户答题回调
  const handleAnswer = (record: any) => {
    const { index, id, userAnswers, questionsType } = record;

    questionAnswer[index] = { id, userAnswers };

    // 多选题，如果一个都没选择，则要删除这题的回答记录
    if (questionsType === 2 && userAnswers.length === 0) {
      delete questionAnswer[index];
    }

    // 解答题 如果用户回答为空，则要删除这题的回答记录
    if (questionsType === 4 && userAnswers.length === 0) {
      delete questionAnswer[index];
    }

    setQuestionAnswer({
      ...questionAnswer,
    });

    sessionStorage.setItem(questionAnswerKey, {
      ...questionAnswer,
    });
  };

  const onSubmit = () => {
    setExamInfo({
      ...examInfo,
      userResultState: 2, // 考试了，但是没出结果
    });
  };

  // 接口还没拿到数据
  if (!examInfo) {
    return null;
  }

  const resultJSX = (
    <Result
      className={S.submitResult}
      status="success"
      title={`${examInfo.name}已完成`}
      subTitle={
        <>
          <p>恭喜你，测试已完成，请耐心等待结果。</p>
          <p>分数查询路径：个人中心-我的考试</p>
        </>
      }
      extra={[
        <Button type="primary" key="console">
          <Link to="/exam">返回考试列表</Link>
        </Button>,
      ]}
    />
  );

  const answerJSX = (
    <>
      <div className={S.header}>
        <p className={S.title}>{examInfo.name}</p>

        <dl className={S.desc}>
          <dd>{`姓名：${examInfo.userName}`}</dd>
          {examInfo.studentNumber && <dd>{`学号：${examInfo.studentNumber}`}</dd>}
        </dl>
      </div>

      <div className={S.mainWrap}>
        <div className={S.questions}>
          {examInfo.questions.map((item: any, index: number) => (
            <SubjectItem
              onChange={handleAnswer}
              type="answer"
              key={item.id}
              {...{ ...item, index }}
            />
          ))}

          <p className={S.tips}>已是最后一题</p>
        </div>

        <ExamPanel
          examId={examId}
          onSubmit={onSubmit}
          questions={examInfo.questions}
          questionAnswer={questionAnswer}
          countdownTime={Date.now() + examInfo.endTime - examInfo.currentTime}
          type="answer"
        />
      </div>
    </>
  );

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Tooltip
            title="离开考试之后，仍可再次进来继续考试"
            arrowPointAtCenter
            placement="bottomRight"
          >
            <a href="/exam">在线考试</a>
          </Tooltip>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{examInfo.name}</Breadcrumb.Item>
      </Breadcrumb>

      {[2, 3].includes(examInfo.userResultState) ? resultJSX : answerJSX}
    </PageWrap>
  );
}
