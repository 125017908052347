import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { PageWrap } from '@components/MainLayout/index';
import { getUserResourcesList } from '@pages/resources/api';
import { ReactComponent as Icon5 } from '@images/icon/icon-5.svg';
import { ReactComponent as Icon6 } from '@images/icon/icon-6.svg';
// @ts-ignore
import S from './style.module.less';

// 申请资源
function AddServiceItem() {
  const history = useHistory();

  const handleJump = () => {
    history.push({
      pathname: '/resources/application',
    });
  };

  return (
    <div onClick={handleJump} className={S.addServiceItem}>
      <div className={S.inner}>
        <PlusOutlined />
        <span className={S.text}>申请资源</span>
      </div>
    </div>
  );
}

// 单个资源配置
function ServiceItem(props: any) {
  const {
    createdAt, // 创建时间
    nodeCount, // 节点数量
    serverSpecsVo, // 服务器资源配置
    blockchainTypeVo, // 区块链资源配置
    id,
  } = props;
  const history = useHistory();

  const jumpDetailsPage = () => {
    history.push({
      pathname: `/resources/resources-details/${id}`,
    });
  };

  return (
    <div onClick={jumpDetailsPage} className={S.serviceItem}>
      <dl className={S.descInfo}>
        <dt>
          <Icon5 />
          <span>服务配置</span>
        </dt>
        <dd>
          <span className={S.attr}>CPU: </span>
          <span className={S.value}>{`${serverSpecsVo.cpuCount}核`}</span>
        </dd>
        <dd>
          <span className={S.attr}>内存: </span>
          <span className={S.value}>{`${serverSpecsVo.memory}GB`}</span>
        </dd>
        <dd>
          <span className={S.attr}>带宽: </span>
          <span className={S.value}>{serverSpecsVo.bandwidth}</span>
        </dd>
        <dd>
          <span className={S.attr}>IP类型: </span>
          <span className={S.value}>{serverSpecsVo.ipType}</span>
        </dd>
        <dd className={S.full}>
          <span className={S.attr}>处理器型号: </span>
          <span className={S.value}>{serverSpecsVo.cpuType}</span>
        </dd>
      </dl>

      <dl className={S.descInfo}>
        <dt>
          <Icon6 />
          <span>区块链配置</span>
        </dt>
        <dd className={S.full}>
          <span className={S.attr}>区块链类型:</span>
          <span className={S.value}>{blockchainTypeVo.typeName}</span>
        </dd>
        <dd className={S.full}>
          <span className={S.attr}>区块链加密算法:</span>
          <span className={S.value}>
            {
              // 加密算法 0 Secp256k1 1 SM2
              ['Secp256k1', 'SM2'][blockchainTypeVo.encryptionAlgorithm]
            }
          </span>
        </dd>
        <dd className={S.full}>
          <span className={S.attr}>区块链加密节点:</span>
          <span className={S.value}>{`${nodeCount}个`}</span>
        </dd>
      </dl>

      <div className={S.footer}>
        <time>{`创建时间：${createdAt}`}</time>
        <button>扩容</button>
      </div>
    </div>
  );
}

export default function (props: any) {
  const [serverList, setServerList] = useState([]); // 服务器列表

  useEffect(() => {
    getUserResourcesList().then((result: any) => {
      setServerList(result);
    });
  }, []);

  return (
    <PageWrap className={S.pageWrap}>
      <p className={S.pageTitle}>资源管理</p>

      <div className={S.serviceList}>
        <AddServiceItem />

        {serverList.map((item: any) => (
          <ServiceItem key={item.id} {...item} />
        ))}
      </div>
    </PageWrap>
  );
}
