import { PageWrap } from '@components/MainLayout';
import { Affix, Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserOldArticleList, getUserOldDetail } from '../api';
import ArticleList from '../components/ArticleList';
import ArticleTags from '../components/ArticleTags';
// @ts-ignore
import S from './style.module.less';
import UserItem from '../components/UserItem';

export default function UserArticle(props: any) {
  const [pageRequest, setPageRequest] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [hasFollow, setHasFollow] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();
  const [articleList, setArticleList] = useState([]);
  const [total, settotal] = useState();
  const { userId } = useParams<any>();

  const getDataSource = async () => {
    if (!userId) {
      return null;
    }
    const result: any = await getUserOldArticleList(userId, {
      ...pageRequest,
    });
    setArticleList(result.articleList);
    settotal(result.pager?.count);
  };

  const handleGetUserOldDetail = async () => {
    const result: any = await getUserOldDetail(userId);
    setUserInfo(result.user);
    setHasFollow(result.hasFollow);
  };

  useEffect(() => {
    getDataSource();
    handleGetUserOldDetail();
    // eslint-disable-next-line
  }, [pageRequest, userId]);

  const { avatar, id, point, nickname, status } = userInfo || {};

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{nickname || `用户${id}`}的文章</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.contentWrap}>
        <div className={S.mainWrap}>
          <div className={S.titleWrap}>
            <p className={S.tips}>
              <em>{nickname || `用户${id}`}的文章</em>
              <span>共：{total}篇文章</span>
            </p>
          </div>
          <ArticleList
            articleList={articleList}
            total={total}
            pageRequest={pageRequest}
            setPageRequest={setPageRequest}
          />
        </div>

        <div className={S.asideWrap}>
          <UserItem
            type="fans"
            theme="theme1"
            id={id}
            point={point}
            status={status}
            name={nickname}
            headImg={avatar}
            hasFollow={hasFollow}
            className={S.moduleItem}
          />
          <Affix offsetTop={64 + 20} className={S.moduleItem}>
            <ArticleTags />
          </Affix>
        </div>
      </div>
    </PageWrap>
  );
}
