import React, { FunctionComponent } from 'react';
import { ChapterVo, Experiment } from '../types/experiment';
import styled from 'styled-components';
import { Collapse, Progress } from 'antd';
// import { converter } from '@components/MarkdownEditor';
import { Flex, Span } from '@components/ui/commons';
import { CheckCircleFilled } from '@ant-design/icons';
import { learnChapter } from '@pages/experiment/api';
import 'react-mde/lib/styles/css/react-mde-all.css';
import EditorPreview from './EditorPreview';

const { Panel } = Collapse;
const Wrapper = styled.div`
  font-size: 14px;

  .ant-progress-line {
    width: 95%;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 0;
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box,
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .chapter-wrapper .ant-collapse-header {
    font-size: 14px;
    font-weight: 400;
    color: #383838;
    line-height: 18px;
  }
  .ant-collapse-item-active > .ant-collapse-header {
    color: #515151 !important;
  }
  .section-wrapper {
    position: relative;
    .ant-collapse-header {
      font-size: 14px;
      font-weight: 400;
      color: #898989;
      line-height: 22px;
    }
    .markdown {
      font-size: 14px;
      font-weight: 400;
      color: #858585;
      line-height: 22px;
    }
    .ant-collapse-item {
      padding-left: 22px;
      position: relative;
      &:first-of-type {
        &:before {
          top: 20px;
        }
      }
      &:last-of-type {
        &:before {
          height: 20px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 0px;
        height: 100%;
        width: 1px;
        background: #f2f2f2;
      }
      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #249bf9;
        top: 11px;
        left: 2px;
        transform: translateY(4px);
      }
    }
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #383838;
  line-height: 22px;
  margin-bottom: 8px;
`;

const Subsections = ({ c }: { c: ChapterVo }) => {
  return (
    <Collapse ghost className="section-wrapper" accordion>
      {c.subsectionVos?.map(s => (
        <Panel header={s.name} key={s.id} showArrow={false}>
          <div className="markdown mde-preview">
            {/* <div
              className="mde-preview-content"
              key={s.sequence}
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(s.content) }}
            /> */}
            <EditorPreview value={s.content} readonly height="200px" />
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

const ExperimentChapters: FunctionComponent<{ data: Experiment; refresh: Function }> = ({
  data,
  refresh,
}) => {
  const { chapterVos, learnedChapterNum, totalChapterNum } = data;
  return (
    <Wrapper>
      <Title>{data.name}</Title>
      <Span mb="18px">
        目前已学习{learnedChapterNum} / {totalChapterNum}章节
      </Span>
      <Progress
        percent={parseFloat(((learnedChapterNum / totalChapterNum) * 100).toFixed(2))}
        style={{ marginBottom: '16px' }}
      />
      <Collapse
        ghost
        accordion
        className="chapter-wrapper"
        defaultActiveKey={chapterVos[0]?.id}
        onChange={key => {
          if (key) {
            learnChapter({ chapterId: key as string, experimentId: data.id }).then(() => refresh());
          }
        }}
      >
        {chapterVos?.map(c => (
          <Panel
            header={
              <Flex justifyContent="space-between">
                <Span>{c.name}</Span>
                <Span>
                  {c.isLearned && (
                    <CheckCircleFilled style={{ color: 'rgb(126, 211, 32)', fontSize: '16px' }} />
                  )}
                </Span>
              </Flex>
            }
            key={c.id}
            showArrow={false}
          >
            <Subsections c={c} />
          </Panel>
        ))}
      </Collapse>
    </Wrapper>
  );
};

export default ExperimentChapters;
