import React, { useEffect, useState } from 'react';
import { Checkbox, Tag } from 'antd';
import { getTags } from '@components/TagFilter/api';
import cx from 'classnames';

// @ts-ignore
import S from './style.module.less';

interface TagItem {
  id: number;
  name: string;
}

const CheckboxGroup = Checkbox.Group;

export default function (props: any) {
  const { labels, tagsType, customOptions, onChange } = props;

  // 键值对方便查询数据  key: 一级类目id value: 该一级类目对应的子类目
  const [secondCategory, setSecondCategory] = useState<{
    [index: string]: TagItem[];
  }>({}); // 子类目键值对

  const [firstCategory, setFirstCategory] = useState<TagItem[]>([]); // 当前展示的1级类目
  const [currSecondCategory, setCurrSecondCategory] = useState<TagItem[]>([]); // 当前展示的子类目

  const [selectFirstCategory, setSelectFirstCategory] = useState<number[]>([]); // 当前选中的1级类目
  const [selecSecondCategory, setSelecSecondCategory] = useState<number[]>([]); // 当前选中的2级类目

  const [selectCustomOptions, setCustomOptions] = useState(
    customOptions.map((item: any) => item.value),
  ); // 当前选中的 自定义选项 默认全选
  const [customOptionsSelectAll, setCustomOptionsSelectAll] = useState(true); // 自定义选项 是否全选

  // 获取类目，并加工一下类目数据结构
  useEffect(() => {
    const params = {
      type: tagsType,
      pageNum: 1,
      pageSize: 1000, // 前端不做分页一次性把数据请求完
    };

    // 把一级类目 和 子类目分别提取 单独存放
    const handlerTags = (result: any) => {
      const firstCategory: any = []; // 一级类目
      const secondCategory: any = {}; // 子类目

      // 一级类目 构造一个假数据【全部】
      firstCategory.push({
        id: -Math.random(),
        name: '全部',
      });

      // 一级类目中的【全部】存储所有的子类目
      secondCategory[firstCategory[0].id] = [];
      secondCategory[firstCategory[0].id].push({
        id: -Math.random(),
        name: '全部',
      });

      result.forEach((tagItem: any) => {
        // 提取一级类目
        firstCategory.push({
          id: tagItem.id,
          name: tagItem.name,
        });

        // 当前类目所有的子类目
        const childrenTags = tagItem.childrenTags.map((childrenTags: any) => ({
          id: childrenTags.id,
          name: childrenTags.name,
        }));

        // 把所有子类目用键值对的形式存储，主要是方便查找
        secondCategory[tagItem.id] = childrenTags;
        secondCategory[tagItem.id].unshift({
          id: -Math.random(),
          name: '全部',
        });

        // 搜集所有的子类目，存储在一级类目中的【全部】里面
        secondCategory[firstCategory[0].id].push(...childrenTags);
      });

      // 剔除重复的全部
      // 在一级类目的子类目中，已经构造了一个假的【全部】，所以之后的子类目的全部都要剔除
      // 如果是第一个，则返回  或者 从第二个开始，id要大于0的，因为小于0的是【全部】，要剔除
      secondCategory[firstCategory[0].id] = secondCategory[firstCategory[0].id].filter(
        (item: any, index: number) => index === 0 || (index > 0 && item.id > 0),
      );

      setFirstCategory(firstCategory);
      setSecondCategory(secondCategory);
    };

    // 获取考试类目
    getTags(params).then((result: any) => {
      handlerTags(result.records);
    });
  }, [tagsType]);

  useEffect(() => {
    if (!Object.keys(secondCategory).length) {
      return;
    }

    // 手动触发，默认选择第一个一级类目
    onFirstCategoryChange(firstCategory[0], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondCategory]);

  // 触发回调
  useEffect(() => {
    // 二级类目是单选的，且不存在没选的情况
    // useEffect默认第一次就会执行，此时 selecSecondCategory 还没有值
    if (!String(selecSecondCategory)) {
      return;
    }

    onChange({
      selectFirstCategory: selectFirstCategory[0] < 0 ? [''] : selectFirstCategory,
      selecSecondCategory: selecSecondCategory[0] < 0 ? [''] : selecSecondCategory,
      selectCustomOptions,
    });

    // eslint-disable-next-line
  }, [selectFirstCategory, selecSecondCategory, selectCustomOptions]);

  // 1级类目选项的改变
  const onFirstCategoryChange = (tagItem: TagItem, checked: boolean) => {
    // 这边定义成数组的形式是因为最早说做多选，现在做单选，考虑到拓展性，采用数组结构来存储
    const nextFirstCategorySelect = [tagItem.id]; // 这样写，是只能单选，且不能取消选择
    const nextShowSecondCategory: TagItem[] = [];

    setSelectFirstCategory(nextFirstCategorySelect);

    nextFirstCategorySelect.forEach(id => {
      nextShowSecondCategory.push(...secondCategory[id]);
    });

    setCurrSecondCategory(nextShowSecondCategory);

    // 手动触发，默认选择第一个子类目
    setSelecSecondCategory([nextShowSecondCategory[0].id]);
  };

  // 2级类目选项的改变
  const onSecondCategoryChange = (tagItem: TagItem, checked: boolean) => {
    // 这边定义成数组的形式是因为最早说做多选，现在做单选，考虑到拓展性，采用数组结构来存储
    const nextSecondCategorySelect = [tagItem.id];

    setSelecSecondCategory(nextSecondCategorySelect);
  };

  // 自定义选项的改变
  const onCustomOptionsChange = (selectList: any) => {
    setCustomOptions(selectList);
    setCustomOptionsSelectAll(selectList.length === customOptions.length);
  };

  // 自定义选项的全选和反选
  const onCustomOptionsSelectAllChange = (event: any) => {
    setCustomOptions(event.target.checked ? customOptions.map((item: any) => item.value) : []);
    setCustomOptionsSelectAll(event.target.checked);
  };

  // 避免页面闪烁
  if (!firstCategory.length && !currSecondCategory.length) {
    return null;
  }

  const firstCategoryJSX = firstCategory.map((tagItem: any) => {
    return (
      <Tag.CheckableTag
        key={tagItem.id}
        checked={selectFirstCategory.includes(tagItem.id)}
        onChange={checked => onFirstCategoryChange(tagItem, checked)}
      >
        {tagItem.name}
      </Tag.CheckableTag>
    );
  });

  const secondCategoryJSX = currSecondCategory.map((tagItem: any) => (
    <Tag.CheckableTag
      key={tagItem.id}
      checked={selecSecondCategory.includes(tagItem.id)}
      onChange={checked => onSecondCategoryChange(tagItem, checked)}
    >
      {tagItem.name}
    </Tag.CheckableTag>
  ));

  const customOptionsJSX = (
    <>
      <Checkbox
        indeterminate={
          !!selectCustomOptions.length && selectCustomOptions.length < customOptions.length
        }
        onChange={onCustomOptionsSelectAllChange}
        checked={customOptionsSelectAll}
      >
        全部
      </Checkbox>

      <CheckboxGroup
        options={customOptions}
        value={selectCustomOptions}
        onChange={onCustomOptionsChange}
      />
    </>
  );

  return (
    <div className={S.tagFilter}>
      {[firstCategoryJSX, secondCategoryJSX, customOptionsJSX].map((item, index) => (
        <div className={cx(S.filterWrap, index === 2 && S.alone)} key={index}>
          <div className={S.filterLabel}>{labels[index]}</div>
          <div className={S.filterContent}>{item}</div>
        </div>
      ))}
    </div>
  );
}
