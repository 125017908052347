import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import routes from '../routes';
import { walkRoutes } from '../Routers';

const getTitle = (pathname: string) => {
  const indexName = '区块链教学实验平台';
  const splitter = '-';
  const paths = pathname.split('/').reverse();
  if (paths[0] === '') {
    return `区块链教学实验平台`;
  }
  let result = '';
  paths.forEach(p => {
    const walk = walkRoutes(
      route => {
        if (p === '' && route.path === `/${p}`) {
          result += indexName;
        } else if (route.path === `/${p}`) {
          result += route.text + splitter;
        }
      },
      route => {
        if (route.path === `/${p}`) {
          result += route.text + splitter;
        } else {
          walk(route.children);
        }
      },
    );
    walk(routes);
  });
  return result;
};

const withHelmet = (Component: React.FunctionComponent<RouteComponentProps>) => {
  return (props: RouteComponentProps) => {
    return (
      <>
        <Helmet>
          <title>
            {getTitle(props.location.pathname).replace(/区块链教学实验平台/gi, '') +
              '区块链教学实验平台'}
          </title>
        </Helmet>
        <Component {...props} />
      </>
    );
  };
};

export default withHelmet;
