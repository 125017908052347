import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Empty, Image, Pagination, Spin, Tag } from 'antd';
// @ts-ignore
import S from './style.module.less';

// 单个课程组件
export function CourseItem(props: any) {
  const {
    courseId, // 课程id
    breif, // 简介
    name, // 课程名称
    label, // 课程标签 0必修 1推荐
    thumbnail, // 封面
  } = props;

  const history = useHistory();

  const handleJump = () => {
    history.push({
      pathname: `/course/introduce/${courseId}`,
    });
  };

  return (
    <div onClick={handleJump} className={S.courseItem}>
      <div className={S.coverWrap}>
        <Image className="cover" src={thumbnail} preview={false} placeholder />
      </div>

      <div className={S.titleWrap}>
        {
          [
            <Tag key="必修" className={S.biXiu}>
              必修
            </Tag>,
            <Tag key="选修" className={S.xuanXiu}>
              选修
            </Tag>,
          ][label]
        }
        <p className={S.title}>{name}</p>
      </div>

      <div className={S.describe}>{breif}</div>
    </div>
  );
}

// 课程列表
export function CourseList(props: any) {
  const {
    showPriceInfo = true, // 是否展示价格信息
    params = {}, // 接口请求需要的参数
    getDataSourceFn, // 获取数据的接口
    renderHeader, // 自定义渲染头部
  } = props;

  const [list, setList] = useState<any[]>([]); // 表格数据源
  const [total, setTotal] = useState(1); // 表格总条数，默认不要是0哦
  const [current, setCurrent] = useState(1); // 表格当前页码
  const [loading, setLoading] = useState(true); // 表格是否是加载中

  useEffect(() => {
    const handleParams = {
      ...params,
      pageNum: current,
    };

    setLoading(true);
    getDataSourceFn(handleParams).then((result: any) => {
      setList(result.records);
      setTotal(result.count);
      setLoading(false);
    });
  }, [current, params, getDataSourceFn]);

  // 缺省页判断依据
  // 数据源为空，且总条数也为空 这也是【表格总条数】默认不设置成0的原因
  if (!list.length && !total) {
    return <Empty description="暂无课程" />;
  }

  return (
    <>
      {typeof renderHeader === 'function' && renderHeader({ total })}

      <div className={S.courseListWrap}>
        {loading ? (
          <Spin className="loading" />
        ) : (
          list.map(item => (
            <CourseItem key={item.courseId} showPriceInfo={showPriceInfo} {...item} />
          ))
        )}
      </div>

      <div className={S.paginationTools}>
        <Pagination
          pageSize={params.pageSize}
          current={current}
          total={total}
          onChange={page => {
            setCurrent(page);
          }}
        />
      </div>
    </>
  );
}
