import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Empty, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
// @ts-ignore
import S from './style.module.less';

export default function FabricEnvInfoModal(props: any) {
  const { fabricChainInfoVoList } = props;

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        title="实验环境信息"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        className={S.fabricEnvInfoModal}
        bodyStyle={{
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
        footer={null}
      >
        <Space
          direction="vertical"
          style={{ width: '100%' }}
          split={<Divider style={{ margin: '4px 0' }} />}
        >
          {fabricChainInfoVoList ? (
            fabricChainInfoVoList.map((e: any, index: number) => {
              const {
                certPath,
                chainName,
                chaincodePath,
                channelName,
                podName,
                namespace,
                containerName,
                ordererUrl,
              } = e;
              return (
                <Row key={e.ip} gutter={[4, 8]}>
                  <Col span={24} className={S.title}>
                    <Button
                      type="link"
                      target="_blank"
                      href={`${process.env.REACT_APP_API_URL}/xterm.html?podName=${podName}&namespace=${namespace}&container=${containerName}`}
                    >
                      节点{index}
                    </Button>
                  </Col>
                  <Col span={8} className={S.labal}>
                    链证书目录：
                  </Col>
                  <Col span={16}>{certPath}</Col>
                  <Col span={8} className={S.labal}>
                    链名称：
                  </Col>
                  <Col span={16}>{chainName}</Col>
                  <Col span={8} className={S.labal}>
                    链配置信息目录：
                  </Col>
                  <Col span={16}>{chaincodePath}</Col>
                  <Col span={8} className={S.labal}>
                    通道名称：
                  </Col>
                  <Col span={16}>{channelName}</Col>
                  <Col span={8} className={S.labal}>
                    排序节点url：
                  </Col>
                  <Col span={16}>{ordererUrl}</Col>
                </Row>
              );
            })
          ) : (
            <Empty />
          )}
        </Space>
      </Modal>
      <Button
        onClick={showModal}
        className={S.btnUpload}
        type="text"
        icon={<InfoCircleFilled />}
        ghost
      >
        实验环境信息
      </Button>
    </>
  );
}
