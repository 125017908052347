import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Descriptions, Image, message, Modal, Tabs } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import SplitPane from 'react-split-pane';
// @ts-ignore
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Attachment } from '../../../types/Attachment';
import cx from 'classnames';
import { ReactComponent as Icon1 } from '@images/icon/icon-1.svg';
import { ReactComponent as Icon2 } from '@images/icon/icon-2.svg';
import { PageWrap } from '@components/MainLayout/index';
import MarkdownEditor from '@components/MarkdownEditor';
import EditorPreview from '@components/EditorPreview';
import Attachments from '@components/Attachments';
import {
  getCoursesDetails,
  getCoursesLevelsDetails,
  subscribeCourses,
  unsubscribeCourses,
} from '@pages/course/api';
import { saveNotes } from '@services/notes';
// @ts-ignore
import S from './style.module.less';
import { getQueryParams } from '@utils/lib-url';
import './splitPane.css';
import Watermark from '@components/Watermark';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';

const { TabPane } = Tabs;

const defaultVideoHeight = 500;
const tabsNavMenu = [
  {
    key: 'data',
    icon: <Icon1 className={S.tabsIcon} />,
    label: '资料',
  },
  {
    key: 'note',
    icon: <Icon2 className={S.tabsIcon} />,
    label: '笔记',
  },
];

interface CourseDetails {
  name?: any;
  thumbnail?: any;
  managerName?: any;
  subscribed?: boolean;
  profileVos?: Attachment[];
  breif?: string;
  experimentId?: string;
  examinationId?: string;
  coursewareVo?: {
    type: 1 | 2;
    filePath: string;
    name: string;
  };
  hasPaid?: boolean;
  userNote?: {
    content: string;
  };
  courseId?: string | number;
  details?: string;
  haveExperimentPermission?: boolean;
  haveExaminationPermission?: boolean;
}

export default function () {
  const [courseDetails, setCourseDetails] = useState<CourseDetails>(); // 课程详情
  const [subscribed, setSubscribed] = useState<boolean>();
  const [currTabsKey, setCurrTabsKey] = useState<string>('data'); // 当前选中的选项卡
  const [isFold, setIsFold] = useState<boolean>(false); // 是折叠状态吗 默认不折叠
  const params = useParams<{ id: string }>();
  const { search } = useLocation();
  const videoNode: any = useRef();
  const videoFullscreenNode: any = useRef();
  const [numPages, setNumPages] = useState<number>();
  const [visible, setVisible] = useState(false);
  const [videoHeight, setVideoHeight] = useState(defaultVideoHeight);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const userInfo: any = sessionStorage.getItem(userInfoKey);
  const { courseType }: any = getQueryParams(search) || { courseType: 0 };

  useEffect(() => {
    // 0-主课程  1-子课程
    const getFun = courseType === 0 ? getCoursesDetails : getCoursesLevelsDetails;
    getFun(params.id).then((result: any) => {
      setCourseDetails(result);
      setSubscribed(result.subscribed);
    });
  }, [params.id, courseType]);

  useEffect(() => {
    if (!courseDetails) {
      return;
    }

    let player: any = null; // 播放器实例
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: courseDetails.coursewareVo?.filePath,
          type: 'video/mp4',
        },
      ],
      controlBar: {
        fullscreenToggle: false,
      },
      userActions: {
        doubleClick: handleFullScreen,
      },
    };

    player =
      videoNode.current && videojs(videoNode.current, videoJsOptions, function onPlayerReady() {});

    const myButton =
      player &&
      player.getChild('ControlBar').addChild('button', {
        clickHandler: () => {
          handleFullScreen();
        },
      });
    myButton && myButton.addClass('vjs-fullscreen-control');

    return () => {
      player && player.dispose();
    };
    // eslint-disable-next-line
  }, [courseDetails]);

  // 处理订阅课程
  const handleSubscribeCourses = () => {
    const handleFn = [unsubscribeCourses, subscribeCourses]; // 0取消订阅  1订阅
    const type = +!subscribed; // 进行取反，且转成数字

    handleFn[type](params.id).then(() => {
      setSubscribed(!!type);

      message.success(['课程已取消', '课程已添加成功，可在“我的课程”内查看'][type]);
    });
  };

  // const handleJump = (type: 'experiment' | 'exam') => () => {
  //   const { hasPaid } = courseDetails || {};

  //   //  处理去实验
  //   if (type === 'experiment') {
  //     if (hasPaid) {
  //       window.open(`/experiment/${experimentId}`);
  //       return;
  //     }

  //     Modal.warning({
  //       title: '您暂无该实验权限',
  //       icon: <QuestionCircleFilled />,
  //       content: '请联系客服电话 0570-1234567',
  //     });

  //     return;
  //   }

  //   //  处理去考试
  //   if (type === 'exam') {
  //     if (hasPaid) {
  //       window.open(`/exam/${examinationId}`);
  //       return;
  //     }

  //     Modal.warning({
  //       title: '您暂无该考试权限',
  //       icon: <QuestionCircleFilled />,
  //       content: '请联系客服电话 0570-1234567',
  //     });
  //     return;
  //   }
  // };

  const handleFullScreen = () => {
    const playerLive = videoFullscreenNode.current;

    if (!document.fullscreenElement) {
      changeVideoJsControl(window.screen.height);
      if (playerLive.requestFullscreen) {
        playerLive.requestFullscreen();
        return;
      }
      if (playerLive.webkitRequestFullScreen) {
        playerLive.webkitRequestFullScreen();
        return;
      }
      if (playerLive.mozRequestFullScreen) {
        playerLive.mozRequestFullScreen();
        return;
      }
      if (playerLive.msRequestFullscreen) {
        playerLive.msRequestFullscreen();
        return;
      }
    }
    if (document.fullscreenElement) {
      changeVideoJsControl(defaultVideoHeight);
      if (document.exitFullscreen) {
        document?.exitFullscreen();
      }
    }
  };

  const changeVideoJsControl = React.useCallback((newHeight: any) => {
    setVideoHeight(newHeight);
    // 在手动拖拽的时候动态的改变高度;使video.js的控制台能够展示
    const ele1 = document.getElementsByClassName('vjs-control-bar');
    ele1 && ele1[0] && ele1[0].setAttribute('style', `top:${newHeight - 50}px`);

    const ele2 = document.getElementsByClassName('vjs-big-play-button');
    ele2 && ele2[0] && ele2[0].setAttribute('style', `top:${newHeight / 2}px`);
  }, []);

  if (courseDetails && !Object.keys(courseDetails).length) {
    return null;
  }

  const {
    name,
    breif,
    thumbnail,
    managerName,
    // experimentId, // 实验id
    // examinationId, // 考试id
  } = courseDetails || {};

  const renderTabBar = () => (
    <>
      <div className={S.tabsNavWrap}>
        {tabsNavMenu.map(item => (
          <div
            className={cx(S.tabsNavItem, item.key === currTabsKey && S.active)}
            onClick={() => {
              setCurrTabsKey(item.key);
            }}
            key={item.key}
          >
            {item.icon}
            <div className={S.tabsNavName}>{item.label}</div>
          </div>
        ))}
      </div>

      <button className={cx(S.toggleButton, isFold && S.fold)} onClick={() => setIsFold(!isFold)}>
        <LeftOutlined />
      </button>
    </>
  );

  if (!courseDetails || !courseDetails?.courseId) {
    return null;
  }

  return (
    <PageWrap className={S.pageWrap}>
      <div className={S.header}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/course">在线课程</a>
          </Breadcrumb.Item>

          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>

        <div className={S.descriptionWrap}>
          <Image src={thumbnail} className={S.courseCover} preview={false} placeholder />

          <div className={S.mainInfoWrap}>
            {/* <div className={S.titleWrap}>
              <div className={S.courseTitle}>{name}</div>
              {(experimentId || examinationId) && (
                <div className={S.toolsWrap}>
                  {experimentId && <Button onClick={handleJump('experiment')}>去实验</Button>}

                  {examinationId && <Button onClick={handleJump('exam')}>去考试</Button>}
                </div>
              )}
            </div> */}

            <Descriptions>
              <Descriptions.Item label="教师">{managerName}</Descriptions.Item>
            </Descriptions>

            <div className={S.article}>{breif}</div>

            <div className={S.editWrap}>
              {courseType === 0 && (
                <Button onClick={handleSubscribeCourses} type="primary">
                  {subscribed ? '取消课程' : '添加课程'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      {courseDetails && (
        <div className={S.mainWrap}>
          <Tabs
            activeKey={currTabsKey}
            renderTabBar={renderTabBar}
            tabPosition="left"
            className={isFold ? 'fold' : ''}
          >
            <TabPane key="data">
              <Attachments data={courseDetails.profileVos!} />
            </TabPane>

            <TabPane key="note">
              <MarkdownEditor
                content={courseDetails.userNote!.content || ''}
                onSave={(v: string) => {
                  saveNotes({
                    content: v,
                    type: 'course',
                    typeId: params.id,
                  }).then(res => message.success('保存成功'));
                }}
              />
            </TabPane>
          </Tabs>

          <div
            className={S.videoWrap}
            onContextMenu={e => {
              e.preventDefault();
              setVisible(true);
              return false;
            }}
            style={{
              height: videoHeight + 11,
            }}
          >
            {courseDetails && courseDetails.coursewareVo?.type === 1 ? (
              <Document
                className={S.pdfWrap}
                file={courseDetails.coursewareVo.filePath}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            ) : (
              <div ref={videoFullscreenNode}>
                <SplitPane
                  split="horizontal"
                  minSize={500}
                  defaultSize={defaultVideoHeight}
                  className="Resizer"
                  onChange={e => {
                    changeVideoJsControl(e);
                  }}
                  size={videoHeight}
                >
                  <video
                    ref={videoNode}
                    className="video-js vjs-big-play-centered vjs-default-skin vjs-fluid"
                    style={{
                      height: videoHeight - 11,
                    }}
                  />
                  <div />
                </SplitPane>
                <Watermark text={userInfo.phone} />
              </div>
            )}
          </div>
        </div>
      )}

      {courseDetails && courseDetails?.details && (
        <div className={S.courseDetails}>
          <p className={S.courseTitle}>内容介绍</p>

          <EditorPreview value={courseDetails?.details || ''} readonly height="100%" />
        </div>
      )}
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        maskClosable={false}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
              }}
            >
              确定
            </Button>
          </div>
        }
      >
        <h2 style={{ textAlign: 'center' }}>欢迎使用区块链教学实验平台！</h2>
      </Modal>
    </PageWrap>
  );
}
