import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Checkbox, Image, Radio, Tag } from 'antd';
import { sessionStorage } from '@utils/lib-storage';
import { userInfoKey } from '@pages/user/login';
import { Event } from '@utils/lib-event';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

export const subjectItemEvent = new Event();
export const addActiveStatetKeyWord = 'addActiveStatetKeyWord'; // 添加高亮状态
export const removeActiveStatetKeyWord = 'removeActiveStatetKeyWord'; // 移除高亮状态

// 锚点不能用数字开头
export const prefix = 'exam';

const { TextArea } = Input;

export default function (props: any) {
  const {
    id, // 试题编号
    questionsContent, //  试题内容
    questionsOption, // 试题选项
    questionsAnalysis, // 试题答案
    questionsAnswer, // 答题解析
    userAnswers, // 用户作答
    userScore, // 考生得分
    questionsScore, // 试题分数
    questionsType, // 试题类型 1单选  2多选  3判断   4解答
    questionsImage, // 图片地址
    type, // 试题类型  answer作答   lookOver查看
    index, // 序号
    onChange, // 答题回调，这个是自定义的函数
  } = props;
  const params = useParams<{ id: string }>();
  const userInfo = sessionStorage.getItem(userInfoKey);
  const questionAnswerKey = `questionAnswerKey-${userInfo?.id}-${params.id}`;
  const prevCurrUserAnswer = (sessionStorage.getItem(questionAnswerKey) || {})[index]?.userAnswers;
  const [currUserAnswer, setCurrUserAnswer] = useState<any>(prevCurrUserAnswer || null); // 用户作答
  const [isActive, setIsActive] = useState(false); // 是否高亮显示
  const idString = `${prefix}${index}`;
  const isWrong = type === 'lookOver' && userScore !== questionsScore;

  useEffect(() => {
    subjectItemEvent.on(addActiveStatetKeyWord, addActiveState);
    subjectItemEvent.on(removeActiveStatetKeyWord, removeActiveState);

    return () => {
      subjectItemEvent.remove(addActiveStatetKeyWord, addActiveState);
      subjectItemEvent.remove(removeActiveStatetKeyWord, removeActiveState);
    };

    // eslint-disable-next-line
  }, [idString]);

  const addActiveState = (currSubjectItem: any) => {
    if (currSubjectItem.idString !== idString) {
      return;
    }

    subjectItemEvent.emit(removeActiveStatetKeyWord);
    setIsActive(true);
    setTimeout(() => {
      removeActiveState();
    }, 3 * 1000);
  };

  const removeActiveState = () => {
    setIsActive(false);
  };

  // 用户作答回调
  const handleAnswer = (value: any) => {
    setCurrUserAnswer(value);

    if (typeof onChange === 'function') {
      onChange({ index, id, userAnswers: value, questionsType });
    }
  };

  // 渲染试题类型
  const renderType = () => {
    if (questionsType === 1) {
      return <Tag key="单选">单选</Tag>;
    }

    if (questionsType === 2) {
      return <Tag key="多选">多选</Tag>;
    }

    if (questionsType === 3) {
      return <Tag key="判断">判断</Tag>;
    }

    if (questionsType === 4) {
      return <Tag key="问答">问答</Tag>;
    }
  };

  // 渲染回答选项
  const renderOptions = () => {
    const handleOptions = (questionsOption || []).map((item: any) => ({
      label: `${item.name}. ${item.desc}`,
      value: item.name,
    }));

    const disabled = type === 'lookOver';
    let value = currUserAnswer;

    if (questionsType === 1) {
      if (disabled) {
        value = (userAnswers && userAnswers[0]) || undefined;
      }

      return (
        <Radio.Group
          onChange={event => handleAnswer(event.target.value)}
          disabled={disabled}
          options={handleOptions}
          value={value}
        />
      );
    }

    if (questionsType === 2) {
      if (disabled) {
        value = userAnswers || undefined;
      }

      return (
        <Checkbox.Group
          onChange={checkedValue => handleAnswer(checkedValue)}
          disabled={disabled}
          options={handleOptions}
          value={value}
        />
      );
    }

    if (questionsType === 3) {
      if (disabled) {
        value = (userAnswers && userAnswers[0]) || undefined;
      }

      return (
        <Radio.Group
          onChange={event => handleAnswer(event.target.value)}
          disabled={disabled}
          options={[
            {
              label: '正确',
              value: '正确',
            },
            {
              label: '错误',
              value: '错误',
            },
          ]}
          value={value}
        />
      );
    }

    if (questionsType === 4) {
      if (disabled) {
        value = (userAnswers && userAnswers[0]) || undefined;
      }

      return (
        <TextArea
          disabled={disabled}
          onChange={event => handleAnswer(event.target.value)}
          value={value}
          maxLength={2000}
          rows={10}
          placeholder="请输入"
        />
      );
    }
  };

  // 渲染试题答案解析
  const renderExposition = () => {
    return (
      <div className={S.exposition}>
        {questionsAnalysis && (
          <div className={S.answer}>{`正确答案: ${questionsAnalysis?.toString()}`}</div>
        )}
        <div className={S.wrap}>
          <p className={S.title}>答案解析</p>
          <p className={S.content}>{`${questionsAnswer}`}</p>
        </div>
      </div>
    );
  };

  return (
    <div
      id={idString}
      className={cx(S.subjectItem, isWrong && S.answerError, isActive && S.active)}
    >
      {renderType()}

      <p className={S.questionTitle}>
        <span className={S.text}>{`${index + 1}. ${questionsContent}`}</span>
        <span className={S.score}>{`(${questionsScore}分)`}</span>
      </p>

      {questionsImage && <Image height={150} src={questionsImage} />}

      {renderOptions()}

      {isWrong && renderExposition()}
    </div>
  );
}
