import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton, Image } from 'antd';
import { PageWrap, MainWidth } from '@components/MainLayout';
import { payInfokey } from '@components/Pay';
import { ReactComponent as Icon35 } from '@images/icon/icon-35.svg';
import { ReactComponent as Icon37 } from '@images/icon/icon-37.svg';
import { sessionStorage } from '@utils/lib-storage';
import { getPayResult } from '@pages/pay/api';
// @ts-ignore
import S from './style.module.less';

import payIcon from '../../../images/icon/icon-36.png';

export default function (props: any) {
  const payInfo = sessionStorage.getItem(payInfokey);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [payType, setPayType] = useState<number>(); // 0微信 1支付宝
  const path = props.match.path;
  const history = useHistory();
  const timer = useRef<any>();

  // 处理界面渲染
  useEffect(() => {
    const payTypeEnum = {
      '/pay/wechat-pay': 0,
      '/pay/alipay': 1,
    };

    // @ts-ignore
    setPayType(payTypeEnum[path]);

    // 微信支付
    if (path === '/pay/wechat-pay') {
      handleWeChatPay();
    }

    // 支付宝支付
    if (path === '/pay/alipay') {
      handleweAlipay();
    }

    setIsLoad(true);
    // eslint-disable-next-line
  }, []);

  // 轮询支付结果
  useEffect(() => {
    // 只有微信支付才要轮询
    if (payType !== 0) {
      return;
    }

    timer.current = setInterval(getWeChatPayResult, 3 * 1000);

    // 避免一直轮询
    setTimeout(() => {
      clearInterval(timer.current);
    }, 40 * 1000);

    return () => {
      clearInterval(timer.current);
    };

    // eslint-disable-next-line
  }, [payType]);

  const getWeChatPayResult = () => {
    const params = {
      // @ts-ignore
      orderCode: payInfo.outTradeNo,

      // @ts-ignore
      platform: {
        '/pay/alipay': 'alipay',
        '/pay/wechat-pay': 'wx',
      }[path],
    };

    getPayResult(params).then((result: any) => {
      // 支付状态 0待支付 1支付成功 2支付失败
      if ([1, 2].includes(result.status)) {
        clearInterval(timer.current);

        history.push({
          pathname: '/pay/wechat/result',
          search: `?out_trade_no=${payInfo.outTradeNo}`,
        });
      }
    });
  };

  const handleWeChatPay = () => {
    console.log(777, payInfo);
  };

  const handleweAlipay = () => {
    const body = document.querySelector('body');
    const div = document.createElement('div');

    div.innerHTML = payInfo.body;
    body?.appendChild(div);
    div.querySelector('form');

    const formId = div.querySelector('form')?.id || '';
    const form = document.getElementById(formId);

    if (formId) {
      // @ts-ignore
      form?.submit();
    }
  };

  const renderWeChatPay = () => {
    return (
      <div className={S.wechatPay}>
        <div className={S.header}>
          <MainWidth className={S.innerWrap}>
            <Icon35 />
            <p className={S.payName}>微信支付</p>
          </MainWidth>
        </div>

        <MainWidth>
          <div className={S.titleWrap}>
            <div className={S.titleInfo}>
              <p className={S.title}>正在使用即时到账交易，请您尽快付款！</p>
              <p className={S.subTitle}>{`订单信息：${payInfo.orderInfo}`}</p>
            </div>

            <div className={S.priceInfo}>
              <span className={S.text}>应付金额</span>
              <span className={S.price}>{payInfo.orderAmount}</span>
              <span className={S.text}>元</span>
            </div>
          </div>

          <div className={S.mainWrap}>
            <div className={S.innerWrap}>
              <p className={S.title}>待支付金额</p>
              <p className={S.price}>{`¥ ${payInfo.orderAmount}`}</p>
              <img className={S.tipsImage} src={payIcon} alt="" />
              <Image width={160} src={payInfo.image} preview={false} />

              <div className={S.tips}>
                <Icon37 />

                <p className={S.text}>
                  打开手机微信
                  <br />
                  扫描此二维码支付
                </p>
              </div>
            </div>
          </div>
        </MainWidth>
      </div>
    );
  };

  if (!isLoad) {
    return (
      <PageWrap>
        <Skeleton
          active
          paragraph={{
            rows: 20,
          }}
        />
      </PageWrap>
    );
  }

  return <PageWrap className={S.pageWrap}>{payType === 0 && renderWeChatPay()}</PageWrap>;
}
