import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tag, Tabs, Affix } from 'antd';
import { PageWrap, MainWidth } from '@components/MainLayout/index';
import Pay, {
  payEvent,
  onPaySuccessEventKeyWord,
  onFreePaySuccessEventKeyWord,
} from '@components/Pay';
import cx from 'classnames';
import { getExperimentsIntroduction } from '@pages/experiment/api';
// @ts-ignore
import S from '@pages/course/courseIntroduce/style.module.less';
import EditorPreview from '@components/EditorPreview';

const { TabPane } = Tabs;

export default function (props: any) {
  const [experimentInfo, setExperimentInfo] = useState<any>();
  const [isAffix, setIsAffix] = useState(false);
  const experimentId = props.match.params.id;

  useEffect(() => {
    payEvent.on(onPaySuccessEventKeyWord, handlePaySuccess);
    payEvent.on(onFreePaySuccessEventKeyWord, handlePaySuccess);
  }, []);

  useEffect(() => {
    getExperimentsIntroduction(experimentId).then((result: any) => {
      setExperimentInfo({
        name: result.name, // 名称
        brief: result.brief, // 简介
        blockChainType: result.blockChainType, // 资源类型
        knowledgePoint: result.knowledgePoint, // 知识点
        userCount: result.userCount, // 学过的用户人数
        price: result.price, // 价格
        managerName: result.managerName, // 实验教师
        hasPaid: result.hasPaid, // 是否购买
        introduce: result.introduce, // 详情  实验介绍
      });
    });
  }, [experimentId]);

  const handlePaySuccess = () => {
    setExperimentInfo((prevExperimentInfo: any) => ({
      ...prevExperimentInfo,
      hasPaid: true,
    }));
  };

  if (!experimentInfo) {
    return null;
  }

  const startLink = () => {
    if (experimentInfo.blockChainType === null) {
      return (
        <Link to={`/experiment/noResource/${experimentId}`} className={S.btnStudy} type="primary">
          立即学习
        </Link>
      );
    }
    return (
      <Link to={`/experiment/${experimentId}`} className={S.btnStudy} type="primary">
        立即学习
      </Link>
    );
  };

  const renderDescription = () => {
    return (
      <div className={S.descriptionWrap}>
        <div className={S.mainInfoWrap}>
          <div>
            <div className={S.titleWrap}>
              {
                [<Tag className={S.bixiu}>必修</Tag>, <Tag className={S.xuanxiu}>选修</Tag>][
                  experimentInfo.label
                ]
              }
              <p>{experimentInfo.name}</p>
            </div>

            <div className={S.attribute}>
              <span className={S.item}>{`教师：${experimentInfo.managerName}`}</span>
              <span className={S.item}>{`${experimentInfo.userCount}人学过`}</span>
            </div>
          </div>

          <article>{experimentInfo.brief}</article>

          <div className={S.asideWrap}>
            {/*<span className={S.price}>{Number(experimentInfo.price)?experimentInfo.price:'免费'}</span>*/}
            <span className={S.price}>
              {Number(experimentInfo.price) ? experimentInfo.price : ' '}
            </span>
            {experimentInfo.hasPaid ? (
              startLink()
            ) : (
              <Pay
                isFree={experimentInfo.price <= 0}
                source={1}
                goodsId={experimentId}
                applyType={1}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageWrap className={S.PageWrap}>
      <header className={S.header}>
        <MainWidth>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/experiment">在线实验</a>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{experimentInfo.name}</Breadcrumb.Item>
          </Breadcrumb>

          {renderDescription()}
        </MainWidth>
      </header>

      <Affix
        offsetTop={64}
        onChange={(affixed: any) => setIsAffix(!!affixed)}
        className={cx(S.header, S.affixHeaderWrap, isAffix && S.affix)}
      >
        {renderDescription()}
      </Affix>

      <MainWidth>
        <div className={cx(S.moduleItem)}>
          <p className={S.moduleTitle}>你将学到的</p>

          <div className={S.knowledgePoints}>
            {experimentInfo.knowledgePoint.map((item: any) => (
              <span key={item} className={S.item}>
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className={cx(S.moduleItem)}>
          <Tabs>
            <TabPane tab="实验介绍" key="实验介绍">
              <EditorPreview value={experimentInfo?.introduce || ''} readonly height="100%" />
            </TabPane>
          </Tabs>
        </div>
      </MainWidth>
    </PageWrap>
  );
}
