import React from 'react';
import Header from '@components/Header/index';
import Footer from '@components/Footer/index';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

// 这个组件用于页面根组件
export const PageWrap = function (props: any) {
  return (
    <div id="pageWrap" className={cx(S.pageWrap, S.mainWidth, props.className)}>
      {props.children}
    </div>
  );
};

// 当使用了PageWrap组件时，还需要全局主宽度组件时使用该组件
export const MainWidth = function (props: any) {
  return <div className={cx(S.mainWidth, props.className)}>{props.children}</div>;
};

export default function (props: any) {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
}
