import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '@utils/lib-scrollTop';
import routes from './routes';
import withHelmet from './hoc/withHelmet';
import MainLayout from './components/MainLayout/index';
import NotFound from '@components/NotFound';

const Components: { [key: string]: FunctionComponent<any> } = {};

export interface RouteType {
  path: string;
  text: string;
  component?: string;
  category?: string;
  children?: RouteType[];
}

export interface RouteLeafType {
  path: string;
  text: string;
  component: string;
  category?: string;
}

export interface RouteParentType {
  path: string;
  text: string;
  children: RouteType[];
}

export const walkRoutes =
  (
    renderRouteLeaf: (route: RouteLeafType, prefix: string) => any,
    renderRouteParent: (route: RouteParentType, prefix: string) => any,
  ) =>
  (r: RouteType[], prefix = '') => {
    return r.map((route: RouteType) => {
      if (!route.children) {
        return renderRouteLeaf(route as RouteLeafType, prefix);
      }
      return renderRouteParent(route as RouteParentType, prefix);
    });
  };

const renderRouteLeaf = (route: RouteLeafType, prefix: string) => {
  return (
    <Route
      exact
      path={prefix + route.path}
      key={prefix + route.path}
      component={Components[route.component]}
    />
  );
};

const renderRouteParent = (route: RouteParentType, prefix: string) => {
  return walk(route.children, prefix + route.path);
};

const walk = walkRoutes(renderRouteLeaf, renderRouteParent);

const routeLeafToComponents = (route: RouteLeafType, prefix: string) => {
  Components[route.component] = withHelmet(require(`./pages${prefix}/${route.component}`).default);
};

const routeParentToComponents = (route: RouteParentType, prefix: string) => {
  return walkToComponents(route.children, prefix + route.path);
};

const walkToComponents = walkRoutes(routeLeafToComponents, routeParentToComponents);
walkToComponents(routes);

const Routers: FunctionComponent = () => {
  return (
    <Router>
      <ScrollToTop />
      <MainLayout>
        <Switch>
          {walk(routes)}
          <Route path="*" component={NotFound} />
        </Switch>
      </MainLayout>
    </Router>
  );
};

export default Routers;
