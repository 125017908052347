import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Affix, Button } from 'antd';
import { PageWrap } from '@components/MainLayout';
import TagFilter from '../components/TagFilter';
import ArticleList from '../components/ArticleList';
import UserOverview from '../components/UserOverview';
import ArticleTags from '../components/ArticleTags';
import Prestige from '../components/Prestige';
// @ts-ignore
import S from './style.module.less';
import { getForumHome } from '../api';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';

export default function (props: any) {
  const [pageRequest, setPageRequest] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [articleList, setArticleList] = useState([]);
  const [total, settotal] = useState();
  const [type, setType] = useState(null);

  const getDataSource = async () => {
    const result: any = await getForumHome({
      ...pageRequest,
      type,
    });
    setArticleList(result.articleList);
    settotal(result.pager?.count);
  };

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line
  }, [pageRequest, type]);

  const canEdit = sessionStorage.getItem(userInfoKey)?.id;

  return (
    <PageWrap className={S.pageWrap}>
      <div className={S.mainWrap}>
        <div className={S.titleWrap}>
          <p className={S.tips}>共：{total}篇文章</p>
          {canEdit && (
            <Button className={S.btnSubmit} type="primary">
              <Link to="/forum/article/add">发帖</Link>
            </Button>
          )}
        </div>

        <TagFilter type={type} setType={setType} setPageRequest={setPageRequest} />
        <ArticleList
          articleList={articleList}
          total={total}
          pageRequest={pageRequest}
          setPageRequest={setPageRequest}
        />
      </div>

      <div className={S.asideWrap}>
        <UserOverview className={S.moduleItem} />
        <Prestige className={S.moduleItem} />
        <Affix offsetTop={64 + 20} className={S.moduleItem}>
          <ArticleTags />
        </Affix>
      </div>
    </PageWrap>
  );
}
