import React, { useState, useEffect } from 'react';
import { Avatar, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { followUser, cancelFollowUser } from '@pages/forum/api';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

export default function UserItem(props: any) {
  const {
    type, // fans 粉丝   follow 关注
    headImg,
    id,
    hasFollow,
    name,
    point,
    theme, // 样式主题
    status, // 用户状态 1:已删除
  } = props;

  const [isFollow, setIsFollow] = useState(false);

  useEffect(() => {
    if (type === 'follow') {
      setIsFollow(true);
    }

    if (type === 'fans') {
      setIsFollow(hasFollow);
    }
    // eslint-disable-next-line
  }, [hasFollow]);

  const handleFollow = () => {
    const api = isFollow ? cancelFollowUser : followUser;
    const tips = isFollow ? '取消关注成功' : '关注成功';

    api(id).then(() => {
      message.success(`${tips}`);
      setIsFollow(isFollow ? false : true);
    });
  };

  const { run } = useRequest(handleFollow, {
    debounceInterval: 500,
    manual: true,
  });

  return (
    <div className={cx(S.userItem, S[theme], S[type], isFollow && S.have)}>
      <div className={S.userInfo}>
        <Avatar src={headImg} icon={<UserOutlined />} size={48} />

        <div className={S.wrap}>
          <p className={S.userName}>{name || `用户${id}`}</p>
          <p className={S.extra}>{`声望：${status === 1 ? '-' : point}`}</p>
        </div>
      </div>

      {status !== 1 && <button onClick={() => run()} className={S.btnItem}></button>}
    </div>
  );
}
