import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ReactComponent as Icon16 } from '@images/icon/icon-16.svg';
import { ReactComponent as Icon17 } from '@images/icon/icon-17.svg';
import { ReactComponent as Icon18 } from '@images/icon/icon-18.svg';
import { ReactComponent as Icon19 } from '@images/icon/icon-19.svg';
import { ReactComponent as Icon20 } from '@images/icon/icon-20.svg';
import { ReactComponent as Icon21 } from '@images/icon/icon-21.svg';
import { ReactComponent as Icon22 } from '@images/icon/icon-22.svg';
import { ReactComponent as Icon23 } from '@images/icon/icon-23.svg';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const history = useHistory();
  const { article } = props;
  const {
    title,
    desc,
    createdAt,
    authorId,
    authorName,
    authorHeadImg,
    approvals,
    views,
    comments,
    marrow,
    top,
    official,
    tagList,
    id,
  } = article || {};

  const goUserArticleList = () => {
    history.push(`/forum/user-article-list/${authorId}`);
  };

  return (
    <div className={S.articleItem}>
      <div className={S.titleWrap}>
        {official && <Icon16 className={S.icon} />}
        {marrow && <Icon17 className={S.icon} />}
        {top && <Icon18 className={S.icon} />}

        <p className={S.title}>
          <Link to={`/forum/article/${id}`}>{title}</Link>
        </p>
      </div>

      <article>
        <Link to={`/forum/article/${id}`}>
          {
            desc
              .replace(/(.*\[.*])\(.*\)/g, '$1...') //将超链接中的链接在简介中替换掉
              .replace(/(.*!\[.*])(\(.*\))/g, '$1...') //较短的图片语法中的链接在简介中去掉
              .replace(/(.*!\[.*])(\(.*)/g, '$1...') //超长的图片语法中的链接在简介中去掉
          }
        </Link>
      </article>

      <div className={S.asideWrap}>
        <div className={S.userInfo} onClick={goUserArticleList}>
          <Avatar src={authorHeadImg} icon={<UserOutlined />} size={20} />
          <span className={S.userName}>{authorName || `用户${authorId}`}</span>
        </div>

        <div className={S.overView}>
          <div className={S.item}>
            <Icon19 className={S.icon} />
            <span>{createdAt}</span>
          </div>

          <div className={S.item}>
            <Icon20 className={S.icon} />
            <span>{views}</span>
          </div>

          <div className={S.item}>
            <Icon21 className={S.icon} />
            <span>{approvals}</span>
          </div>

          <div className={S.item}>
            <Icon22 className={S.icon} />
            <span>{comments}</span>
          </div>

          <div className={S.item}>
            <Icon23 className={S.icon} />

            {tagList &&
              tagList.slice(0, 5).map((item: any) => (
                <Link to={`/forum/tag-article/${item.name}`} key={item.id}>
                  <span style={{ cursor: 'pointer' }}>{item.name}</span>
                </Link>
              ))}
            {tagList.length > 5 && <span key="...">...</span>}
          </div>
        </div>
      </div>
    </div>
  );
}
