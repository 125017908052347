import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message, Modal, notification } from 'antd';
import { sessionStorage } from '@utils/lib-storage';
import { userInfoKey } from '@pages/user/login';

let isTips = false;

const prefix = '/api/v1';
const LOGIN_PATH = '/user/login';
const { location } = window;

interface Result<T> {
  code: number;
  message: string;
  exception: string;
  data: T;
}

axios.interceptors.request.use(
  config => {
    return config;
  },
  (err: any) => {
    console.error('请求超时');
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  res => {
    if (res.data.code > 200) {
      return Promise.reject(res.data);
    }
    return res;
  },
  (err: any) => {
    console.error(err);
    return Promise.reject(err);
  },
);
/**
 * encapsulate axios
 * @param url {string}
 * @param params {object}
 * @param opts {object}
 * @genericType T request type
 * @genericType R response Type
 */
function _FETCH<T, R>(url: string, opts: AxiosRequestConfig, params?: T): Promise<R> {
  return new Promise(async (resolve, reject) => {
    const aoh_opts = axiosOptsHandler<T>(process.env.REACT_APP_API_URL + url, opts, params);
    axios
      .request(aoh_opts)
      .then((res: AxiosResponse<Result<R>>) => {
        const { rh_res, rh_err } = responseHandler<R>(res, url);
        if (rh_err) {
          reject(rh_err);
        }
        if (rh_res) {
          resolve(rh_res.data);
        }
      })
      .catch(error => {
        console.error(error);

        function showError() {
          // 登入接口单独处理  不报错
          if (url === '/api/v1/login') {
            return;
          }

          notification.error({
            message: '出错',
            description: error.message,
          });
        }

        if (![401, 433, 434, 403, 1016, 1017].includes(error.code)) {
          showError();
        }

        if (error.code === 403) {
          location.replace(`/403?message=${error.message}`);
          return;
        }

        // 账户被锁定
        if (error.code === 433) {
          Modal.warning({
            title: '您的账号已被锁定',
            content: '如需解锁请联系客服电话 0570-1234567',
            onOk: () => {
              if (location.pathname !== LOGIN_PATH && location.href.indexOf('?redirect') < 0) {
                location.href = `${LOGIN_PATH}?redirect=${location.pathname}`;
                sessionStorage.removeItem(userInfoKey);
              }
            },
          });

          return;
        }

        // 用户使用的是默认密码
        if (error.code === 434) {
          Modal.warning({
            title: '请先使用手机号进行重置密码',
            content: '请前去忘记密码页面，选择使用手机号进行重置密码',
            onOk: () => {
              if (
                location.pathname !== '/change-password' &&
                location.href.indexOf('?redirect') < 0
              ) {
                location.href = `/user/change-password?redirect=${location.pathname}&code=434`;
                sessionStorage.removeItem(userInfoKey);
              }
            },
          });

          return;
        }

        if (error.code === 401) {
          if (location.pathname !== LOGIN_PATH && location.href.indexOf('?redirect') < 0) {
            if (!isTips) {
              isTips = true;
              message.info('请先登录');
            }

            setTimeout(() => {
              isTips = false;
            }, 2000);

            setTimeout(() => {
              location.href = `${LOGIN_PATH}?redirect=${location.pathname}`;
              sessionStorage.removeItem(userInfoKey);
            }, 2000);
          }
        }

        // 1016:考试已下架  1017:找不到考试
        if ([1016, 1017].includes(error.code)) {
          Modal.error({
            title: error.message,
            okText: '回到列表',
            onOk: () => {
              location.href = '/exam';
            },
            maskClosable: false,
          });
        }

        reject(error);
      });
  });
}

/**
 * axios options handler
 * @param url {string}
 * @param params {object}
 * @param opts {object}
 * @returns {Promise<{aoh_err: *}|{aoh_opts: {headers: {}, url: *}}>}
 */
function axiosOptsHandler<T>(
  url: string,
  opts: AxiosRequestConfig,
  params?: T,
): AxiosRequestConfig {
  const user = sessionStorage.getItem(userInfoKey);
  const aoh_opts = {
    headers: {
      'X-Authorization': user?.token ?? '',
    },
    url,
    ...opts,
  };
  if (opts.method === 'GET') {
    aoh_opts.params = params;
    aoh_opts.headers = {
      ...aoh_opts.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  } else {
    aoh_opts.data = params;
    aoh_opts.headers = {
      ...aoh_opts.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }
  return aoh_opts;
}

/**
 * axios response handler
 * @param res
 * @param url
 * @returns {{rh_err: *}|{rh_res: *}}
 */
function responseHandler<R>(
  res: AxiosResponse<Result<R>>,
  url: string,
): { rh_res?: Result<R>; rh_err?: string } {
  if (res.status >= 400) {
    console.error(`${url}:${res.data.exception}`);
    return {
      rh_res: undefined,
      rh_err: res.data.message,
    };
  }
  return {
    rh_res: res.data,
    rh_err: undefined,
  };
}

/**
 * get
 * @param url {string}
 * @param params {object}
 * @param opts {object}
 * @returns {*}
 */
function GET<T, R>(url: string, params?: T, opts = {}) {
  return _FETCH<T, R>(url, { ...opts, ...{ method: 'GET' } }, params);
}

/**
 * post
 * @param url {string}
 * @param params {object}
 * @param opts {object}
 * @returns {*}
 */
function POST<T, R>(url: string, params?: T, opts = {}) {
  return _FETCH<T, R>(url, { ...opts, ...{ method: 'POST' } }, params);
}

/**
 * put
 * @param url {string}
 * @param params {object}
 * @param opts {object}
 * @returns {*}
 */
function PUT<T, R>(url: string, params?: T, opts = {}) {
  return _FETCH<T, R>(url, { ...opts, ...{ method: 'PUT' } }, params);
}

function DELETE<T, R>(url: string, params?: T, opts = {}) {
  return _FETCH<T, R>(url, { ...opts, ...{ method: 'DELETE' } }, params);
}

function PATCH<T, R>(url: string, params?: T, opts = {}) {
  return _FETCH<T, R>(url, { ...opts, ...{ method: 'PATCH' } }, params);
}

export { GET, POST, PUT, DELETE, PATCH, prefix };
