import { GET, POST, prefix } from '@utils/lib-request/request';

// 查询课程列表
export const getCourses = (params: any) => GET(`${prefix}/courses`, params);

// 获取课程详情
export const getCoursesDetails = (id: any) => GET(`${prefix}/courses/${id}`);

// 获取子课程详情
export const getCoursesLevelsDetails = (id: any) => GET(`${prefix}/levels/${id}`);

// 订阅课程
export const subscribeCourses = (id: string) => POST(`${prefix}/courses/${id}/subscribe`);

// 取消订阅课程
export const unsubscribeCourses = (id: string) => POST(`${prefix}/courses/${id}/unsubscribe`);

// 获取课程介绍
export const getCoursesIntroduction = (id: any) => GET(`${prefix}/courses/introduction/${id}`);
