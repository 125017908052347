import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { getExamResult } from '@pages/exam/api';
import { PageWrap } from '@components/MainLayout/index';
import SubjectItem from '@components/SubjectItem';
import ExamPanel from '@components/ExamPanel';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const [examInfo, setExamInfo] = useState<any>(); // 考试信息
  const examId = props.match.params.id; // 考试id
  const [questionAnswer, setQuestionAnswer] = useState<any>({}); // 用户答题

  useEffect(() => {
    getExamResult(examId).then((result: any) => {
      const { name, userName, score, studentNumber, errorCount, examinationQuestionAndAnswerVos } =
        result;

      examinationQuestionAndAnswerVos.forEach((item: any, index: any) => {
        questionAnswer[index] = {
          id: item.id,
          userAnswers: item.userAnswers,
          questionsType: item.questionsType,
          questionsScore: item.questionsScore,
          userScore: item.userScore,
        };
      });

      setQuestionAnswer({
        ...questionAnswer,
      });
      setExamInfo({
        name,
        score,
        errorCount,
        userName,
        studentNumber,
        questions: examinationQuestionAndAnswerVos,
      });
    });
    // eslint-disable-next-line
  }, [examId]);

  // 接口还没拿到数据
  if (!examInfo) {
    return null;
  }

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/exam">在线考试</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{examInfo.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.header}>
        <p className={S.title}>{examInfo.name}</p>

        <dl className={S.desc}>
          <dd>{`姓名：${examInfo.userName}`}</dd>
          {examInfo.studentNumber && <dd>{`学号：${examInfo.studentNumber}`}</dd>}
        </dl>
      </div>

      <div className={S.mainWrap}>
        <div className={S.questions}>
          {examInfo.questions.map((item: any, index: number) => (
            <SubjectItem key={item.id} type="lookOver" {...{ ...item, index }} />
          ))}

          <p className={S.tips}>已是最后一题</p>
        </div>

        <ExamPanel
          examId={examId}
          score={examInfo.score}
          errorCount={examInfo.errorCount}
          questions={examInfo.questions}
          questionAnswer={questionAnswer}
          type="lookOver"
        />
      </div>
    </PageWrap>
  );
}
