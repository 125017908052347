import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Breadcrumb,
  Image,
  Tag,
  Tabs,
  Affix,
  Space,
  Empty,
  message,
  notification,
} from 'antd';
import { PageWrap, MainWidth } from '@components/MainLayout/index';
import Pay, {
  payEvent,
  onPaySuccessEventKeyWord,
  onFreePaySuccessEventKeyWord,
} from '@components/Pay';
import cx from 'classnames';
import { ReactComponent as Icon8 } from '@images/icon/icon-8.svg';
import { ReactComponent as Icon9 } from '@images/icon/icon-9.svg';
import { ReactComponent as Icon11 } from '@images/icon/icon-11.svg';
import { ReactComponent as Icon12 } from '@images/icon/icon-12.svg';
import { getCoursesIntroduction, subscribeCourses } from '@pages/course/api';
// @ts-ignore
import S from './style.module.less';
import EditorPreview from '@components/EditorPreview';
import { examPageLink } from '@utils/lib-url';
import { createFreePayOrder } from '@components/Pay/api';

const { TabPane } = Tabs;

function IntroduceItem(props: any) {
  const { extraInfo, name, id, managerName, timeLimit, brief, userResultState, details, subtitle } =
    props;

  const { text, pathname } = examPageLink({ userResultState, id });

  // @ts-ignore
  const btnTool = {
    experiment: (
      <div onClick={() => window.open(`/experiment/introduce/${id}`)} className={S.btnLink}>
        开始学习
      </div>
    ),
    examination: (
      <div onClick={() => pathname && window.open(pathname)} className={S.btnLink}>
        {text}
      </div>
    ),
    catalogue: (
      <div
        onClick={() => {
          if (extraInfo.hasPaid) {
            window.open(`/course/${id}?courseType=1`);
          }
          if (!extraInfo.hasPaid) {
            if (extraInfo.isFree)
              createFreePayOrder({
                type: 2,
                isFree: true,
                source: 0,
                goodsId: extraInfo.courseId,
              }).then(result => {
                payEvent.emit(onFreePaySuccessEventKeyWord, result);
                window.open(`/course/${id}?courseType=1`);
              });
            else message.info('请购买该课程后开始学习');
          }
        }}
        className={S.btnLink}
      >
        打开学习
      </div>
    ),
  }[extraInfo.type];

  // @ts-ignore
  const content = {
    experiment: <article>{brief}</article>,
    examination: (
      <div className={S.attributes}>
        <div className={S.item}>
          <Icon11 />
          <span>{`出题人员: ${managerName}`}</span>
        </div>

        <div className={S.item}>
          <Icon12 />
          <span>{`考试时间: ${timeLimit}分钟`}</span>
        </div>
      </div>
    ),
    catalogue: <article>{details}</article>,
  }[extraInfo.type];

  // @ts-ignore
  const typeText = {
    experiment: '实验',
    examination: '考试',
    catalogue: '课程',
  }[extraInfo.type];

  return (
    <div className={S.introduce}>
      <div className={S.titleWrap}>
        <Space>
          <b>
            {subtitle}
            {`${typeText}${extraInfo.index + 1}`}
          </b>
          {name}
        </Space>
        {btnTool}
      </div>

      <div className={S.content}>{content}</div>
    </div>
  );
}

export default function (props: any) {
  const history = useHistory();
  const [courseInfo, setCourseInfo] = useState<any>();
  const [isAffix, setIsAffix] = useState(false);
  const courseId = props.match.params.id;

  useEffect(() => {
    payEvent.on(onPaySuccessEventKeyWord, handlePaySuccess);
    payEvent.on(onFreePaySuccessEventKeyWord, handlePaySuccess);
    return (): void => {
      payEvent.remove(onPaySuccessEventKeyWord, handlePaySuccess);
      payEvent.remove(onFreePaySuccessEventKeyWord, handlePaySuccess);
    };
  }, []);

  useEffect(() => {
    getCoursesIntroduction(courseId).then((result: any) => {
      if (result.id) {
        const { levelVos } = result;
        let subExamination: any[] = [];
        let subExperiment: any[] = [];
        levelVos
          ?.filter((item: any) => item.examinationVos)
          .forEach((e: any) => {
            subExamination = [
              ...subExamination,
              ...e.examinationVos.map((item: any) => ({
                ...item,
                subtitle: `${e.name}-`,
              })),
            ];
          });
        levelVos
          ?.filter((item: any) => item.experimentVos)
          .forEach((e: any) => {
            subExperiment = [
              ...subExperiment,
              ...e.experimentVos.map((item: any) => ({
                ...item,
                subtitle: `${e.name}-`,
              })),
            ];
          });
        setCourseInfo({
          name: result.name, // 名称
          breif: result.breif, // 简介
          knowledgePoint: result.knowledgePoint, // 知识点
          userCount: result.userCount, // 学过的用户人数
          price: result.price, // 价格
          thumbnail: result.thumbnail, // 缩略图
          label: result.label, // 课程类型: 0, 必修课; 1, 推荐课
          managerName: result.managerName, // 课程教师
          hasPaid: result.hasPaid, // 是否购买
          details: result.details, // 详情  课程介绍
          examination: [...(result.examinationVos || []), ...subExamination], // 考试信息
          experiment: [...(result.experimentVos || []), ...subExperiment], // 实验信息
          levelVos: result.levelVos || [], //课程目录
        });
      }
    });
  }, [courseId]);

  const handlePaySuccess = (): void => {
    setCourseInfo((prevCourseInfo?: any) => {
      if (prevCourseInfo?.levelVos?.length) {
        notification.open({
          message: '选修课程成功',
          description: '点击课程目录开始学习',
        });
      }
      setImmediate(() => {
        if (
          prevCourseInfo?.price &&
          prevCourseInfo?.price <= 0 &&
          !prevCourseInfo?.levelVos?.length
        )
          history.push(`/course/${courseId}?courseType=0`);
        subscribeCourses(courseId);
      });
      return {
        ...prevCourseInfo,
        hasPaid: true,
      };
    });
  };
  if (!courseInfo) {
    return <Empty style={{ height: '100vh' }} />;
  }

  const renderDescription = () => {
    return (
      <div className={S.descriptionWrap}>
        <Image src={courseInfo.thumbnail} className={S.courseCover} preview={false} placeholder />

        <div className={S.mainInfoWrap}>
          <div>
            <div className={S.titleWrap}>
              {
                [<Tag className={S.bixiu}>必修</Tag>, <Tag className={S.xuanxiu}>选修</Tag>][
                  courseInfo.label
                ]
              }

              <p>{courseInfo.name}</p>
            </div>

            <div className={S.attribute}>
              <span className={S.item}>{`教师：${courseInfo.managerName}`}</span>
              <span className={S.item}>{`${courseInfo.userCount}人学过`}</span>
              <span className={S.item}>
                <Icon8 className={S.icon} />
                {`${courseInfo.experiment.length}个动手实验`}
              </span>
              <span className={S.item}>
                <Icon9 className={S.icon} />
                {`${courseInfo.examination.length}个考试试卷`}
              </span>
            </div>
          </div>

          <article>{courseInfo.breif}</article>

          <div className={S.asideWrap}>
            {Number(courseInfo.price) ? (
              <span className={S.price}>{courseInfo.price}</span>
            ) : (
              <span className={S.zeroPrice}> </span>
            )}
            {courseInfo.hasPaid ? (
              courseInfo.levelVos.length ? (
                <Button className={S.btnStudying} disabled>
                  正在学习
                </Button>
              ) : (
                <Link to={`/course/${courseId}?courseType=0`} className={S.btnStudy} type="primary">
                  立即学习
                </Link>
              )
            ) : (
              <Pay
                isFree={courseInfo.price <= 0}
                source={0}
                goodsId={courseId}
                defaultButtonText={courseInfo.price <= 0 ? '开始学习' : '创建资源'}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageWrap className={S.PageWrap}>
      <header className={cx(S.header, isAffix && S.affix)}>
        <MainWidth>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/course">在线课程</a>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{courseInfo.name}</Breadcrumb.Item>
          </Breadcrumb>

          {renderDescription()}
        </MainWidth>
      </header>

      <Affix
        offsetTop={64}
        onChange={(affixed: any) => setIsAffix(!!affixed)}
        className={cx(S.header, S.affixHeaderWrap, isAffix && S.affix)}
      >
        {renderDescription()}
      </Affix>

      <MainWidth>
        <div className={cx(S.moduleItem)}>
          <p className={S.moduleTitle}>你将学到的</p>

          <div className={S.knowledgePoints}>
            {courseInfo.knowledgePoint.map((item: any) => (
              <span key={item} className={S.item}>
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className={cx(S.moduleItem)}>
          <Tabs>
            <TabPane tab="课程介绍" key="课程介绍">
              <EditorPreview value={courseInfo?.details || ''} readonly height="100%" />
            </TabPane>

            <TabPane tab="课程内容" key="课程内容">
              {courseInfo.experiment.length === 0 && courseInfo.examination.length === 0 && (
                <Empty />
              )}

              {courseInfo.experiment.map((item: any, index: any) => (
                <IntroduceItem key={item.id} extraInfo={{ type: 'experiment', index }} {...item} />
              ))}

              {courseInfo.examination.map((item: any, index: any) => (
                <IntroduceItem key={item.id} extraInfo={{ type: 'examination', index }} {...item} />
              ))}
            </TabPane>

            {courseInfo.levelVos.length !== 0 && (
              <TabPane tab="课程目录" key="课程目录">
                {/* {courseInfo.levelVos.length === 0 && <Empty />} */}
                {courseInfo.levelVos?.map((item: any, index: any) => (
                  <IntroduceItem
                    key={item.id}
                    extraInfo={{
                      type: 'catalogue',
                      index,
                      hasPaid: courseInfo.hasPaid,
                      isFree: courseInfo.price <= 1e-3,
                      courseId,
                    }}
                    {...item}
                  />
                ))}
              </TabPane>
            )}
          </Tabs>
        </div>
      </MainWidth>
    </PageWrap>
  );
}
