/**
 * 获取某个日期对应的时间戳
 *
 * example: getTimeStamp('2014/07/10 10:21:12')
 * return: 1558598298166
 *
 * example: getTimeStamp('2014-07-10 10:21:12')
 * return: 1558598298166
 *
 * example: getTimeStamp()
 * return: 1558598298166
 *
 * @param {string} date 要获取的日期, 默认为当前日期
 * @returns {number} 该日期对应的时间戳 单位毫秒
 */
export const getTimeStamp = (date: string): number => {
  // ios 不支持 '2014-07-10 10:21:12' 这样的格式
  const dateString = new Date(date ? date.replace(/-/g, '/') : new Date());

  // @ts-ignore
  return Date.parse(dateString);
};

/**
 * 格式化倒计时时间
 *
 * example: formatCountDown(642567521)
 * return: { day: 7, hour: 10, minute: 29, second: 27, millisecond: 521 }
 *
 * example: formatCountDown(-123)
 * return: { day: 0, hour: 0, minute: 0, second: 0, millisecond: 0 }
 *
 * @param {number} timeStamp 时间戳 (两个时间戳的差值) 单位毫秒
 * @returns {object} 被格式化后的对象
 */
export const formatCountDown = (timeStamp: number) => {
  if (timeStamp <= 0) {
    return {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    };
  }

  const dayStamp = 24 * 60 * 60 * 1000;
  const hourStamp = 60 * 60 * 1000;
  const minuteStamp = 60 * 1000;
  const secondStamp = 1000;
  const millisecondStamp = 1000;

  const day = (timeStamp / dayStamp) | 0;
  const hour = ((timeStamp % dayStamp) / hourStamp) | 0;
  const minute = (((timeStamp % dayStamp) % hourStamp) / minuteStamp) | 0;
  const second = ((((timeStamp % dayStamp) % hourStamp) % minuteStamp) / secondStamp) | 0;
  const millisecond = (((timeStamp % dayStamp) % hourStamp) % minuteStamp) % millisecondStamp;

  return { day, hour, minute, second, millisecond };
};
