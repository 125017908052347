import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    padding: 0;
    margin: 0 auto;
    min-width: 1440px;
    overflow-x: scroll;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media (min-width : 667px) {
  html{font-size: 10px; -webkit-text-size-adjust:none;}
  }
  @media (max-width: 667px) {
    html{font-size: 5px; -webkit-text-size-adjust:none;}
  }

  * {
    box-sizing: border-box;
    margin: 0; padding: 0;
    font-family: sans-serif;
  }
  a {
   text-decoration: none;
  }
  :focus {
    outline: none;
  }
  .pointer {
    cursor: pointer
  }
  .hoverUp{
    transition: all .1s ease-in-out;
    :hover {
      transform: translateY(-10px) !important;
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .interactive {
    &:hover {
      background: #fafafa;
    }
  }
  .form-result {
    .ant-modal-confirm-body {
      > span {
        display: block;
        margin-right: 0 !important;
        text-align: center;
        float: none;
        margin-bottom: 24px;
        > svg {
          font-size: 72px;
        }
      }
      .ant-modal-confirm-content {
        margin-left: 0 !important;
        text-align: center;
      }
    }
  }
`;
