import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';
import { userInfoKey } from '@pages/user/login';
import UserItem from '@pages/forum/components/UserItem';
import { sessionStorage } from '@utils/lib-storage';
import { getFollowList, getFansList } from '@pages/forum/api';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const {
    type, // fans 粉丝   follow 关注
    title,
  } = props;

  // eslint-disable-next-line
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState();

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line
  }, []);

  const getDataSource = (params?: any) => {
    const userInfo = sessionStorage.getItem(userInfoKey);

    // @ts-ignore
    const api = {
      fans: getFansList,
      follow: getFollowList,
    }[type];

    const defaultParams = {
      uid: 1,
      pageNum: 1,
      pageSize: 10,
    };

    api(userInfo?.id, { ...defaultParams, ...params }).then((result: any) => {
      setDataSource(result.fansOrFollowerList);
      setTotal(result.pager.count);
    });
  };

  return (
    <div className={cx(S.userList, S[type])}>
      <div className={S.titleWrap}>
        <p className={S.tips}>
          <em>{title}</em>
          <span>{`共：${total}位`}</span>
        </p>
      </div>

      {dataSource.map((item: any) => (
        <UserItem key={item.id} type={type} {...item} />
      ))}

      <Pagination
        onChange={(page, pageSize) => {
          getDataSource({
            pageNum: page,
            pageSize: pageSize,
          });
        }}
        total={total}
        className={S.pagination}
      />
    </div>
  );
}
