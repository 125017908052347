import React from 'react';

interface WatermarkProps {
  text?: string;
  fontSize?: number;
  fillOpacity?: number;
  fillColor?: string;
}

export default function Watermark(props: WatermarkProps) {
  const { text = 'waterMark', fontSize = 16, fillOpacity = '0.2', fillColor = '#000' } = props;
  const res = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="300px" height="300px" viewBox="0 0 300 300">
      <text x="-100" y="-30" fill='${fillColor}'  transform = "rotate(-35 220 -220)" fill-opacity='${fillOpacity}' font-size='${fontSize}'> ${text}</text>
    </svg>`;

  const blob = new Blob([res], {
    type: 'image/svg+xml',
  });

  const url = URL.createObjectURL(blob);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${url})`,
        top: 0,
        left: 0,
        zIndex: 999,
        pointerEvents: 'none', //点击穿透
      }}
    ></div>
  );
}
