import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Attachment } from '../types/Attachment';
import { Flex, Span } from '@components/ui/commons';
// eslint-disable-next-line
import { Checkbox, Empty } from 'antd';

const Wrapper = styled.div`
  padding-top: 12px;
`;

const Attachments: FunctionComponent<{ data: Attachment[] }> = ({ data }) => {
  const plainOptions = data.map((item: any) => ({
    label: item.fileName,
    value: item.filePath,
  })); // 选项列表

  const [checkedList, setCheckedList] = useState<string[]>([]); // 当前已经选择的
  // eslint-disable-next-line
  const [checkAll, setCheckAll] = useState(false); // 是否全选
  // eslint-disable-next-line
  const [indeterminate, setIndeterminate] = useState(false);

  const onChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  // eslint-disable-next-line
  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions.map(i => i.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  // eslint-disable-next-line
  const download = () => {};

  if (data.length <= 0) {
    return <Empty />;
  }

  return (
    <Wrapper>
      {/* <Flex
        justifyContent="space-between"
        alignItems="center"
        height="30px"
      >
        <Span>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            全选
          </Checkbox>
        </Span>

        <Button onClick={download} type="primary" size="small">下载</Button>
      </Flex> */}

      <Checkbox.Group value={checkedList} onChange={onChange} style={{ width: '100%' }}>
        {data.map(d => (
          <Flex
            key={d.id}
            justifyContent="space-between"
            height="32px"
            alignItems="center"
            className="interactive"
            paddingX="12px"
          >
            <Span>{d.fileName}</Span>
            <a href={d.filePath} download={d.fileName} target="_blank" rel="noopener noreferrer">
              下载
            </a>
          </Flex>
        ))}
      </Checkbox.Group>
    </Wrapper>
  );
};

export default Attachments;
