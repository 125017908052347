import React from 'react';
import { Breadcrumb } from 'antd';
import { PageWrap } from '@components/MainLayout';
import UserList from '@pages/forum/components/UserList';

export default function (props: any) {
  return (
    <PageWrap>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>关注列表</Breadcrumb.Item>
      </Breadcrumb>

      <UserList title="我的关注" type="follow" />
    </PageWrap>
  );
}
