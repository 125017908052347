import React, { useEffect, useRef } from 'react';
import { Button, Modal, Form, Radio, Checkbox } from 'antd';
import { createPayOrder, createFreePayOrder } from '@components/Pay/api';
import { getPayResult } from '@pages/pay/api';
import { Event } from '@utils/lib-event';
import { sessionStorage } from '@utils/lib-storage';
import { ReactComponent as Icon33 } from '@images/icon/icon-33.svg';
import { ReactComponent as Icon34 } from '@images/icon/icon-34.svg';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

export const payEvent = new Event(); // 支付事件中心
export const onPaySuccessEventKeyWord = 'onPaySuccessEventKeyWord'; // 支付成功事件 要钱的支付
export const onFreePaySuccessEventKeyWord = 'onFreePaySuccessEventKeyWord'; // 支付成功事件 不要钱的支付
export const closePayModalEventKeyWord = 'closePayModalEventKeyWord'; // 关闭支付弹窗
export const payInfokey = 'payInfokey';

export default function (props: any) {
  const {
    stop = false, // 是否中断支付，
    isFree = false, // 是否是免费的
    defaultButtonText = '创建资源', //免费购买触发按钮文案
    source, // 来源 0课程 1实验 2考试 3资源
    applyType, // 资源申请类型 ：0-大型资源申请；1-实验环境申请；2- 资源扩容；3- 资源续期
    goodsId, // 商品主键 传考试，实验，课程的主键
    serverSpecId, // 服务器规格主键
    blockchainTypeId, // 区块链类型主键
    blockchainNodeCount, // 区块链节点个数
  } = props;
  const [payFrom] = Form.useForm();
  const payModal = useRef<any>(); // 支付弹窗实例对象
  const payResultModal = useRef<any>(); // 支付结果弹窗实例对象

  useEffect(() => {
    payEvent.on(closePayModalEventKeyWord, closePayModal);

    return () => {
      payEvent.remove(closePayModalEventKeyWord, closePayModal);
    };

    // eslint-disable-next-line
  }, []);

  const handlePayResult = () => {
    const payInfo = sessionStorage.getItem(payInfokey);
    const params = {
      orderCode: payInfo.outTradeNo,
      platform: payInfo.payPlatformEnum,
    };

    getPayResult(params).then((result: any) => {
      // 支付状态 0待支付 1支付成功 2支付失败
      if (result.status === 1) {
        payEvent.emit(onPaySuccessEventKeyWord, result);
      }
    });
  };

  const submit = (values?: any) => {
    const params = {
      type: 2, //  0微信 1支付宝,2 免费支付
      source,
      applyType,
      goodsId,
      serverSpecId,
      blockchainTypeId,
      blockchainNodeCount,
      ...values,
    };

    // 免费的
    if (isFree) {
      createFreePayOrder(params).then(result => {
        payEvent.emit(onFreePaySuccessEventKeyWord, result);
      });

      return;
    }

    createPayOrder(params).then((result: any) => {
      sessionStorage.setItem(payInfokey, result);

      // 微信支付
      if (params.type === 0) {
        window.open('/pay/wechat-pay', '_blank');
      }

      // 支付宝支付
      if (params.type === 1) {
        window.open('/pay/alipay', '_blank');
      }

      payEvent.emit(closePayModalEventKeyWord);
      renderResultPayModal();
    });
  };

  const closePayModal = () => {
    payModal && payModal.current && payModal.current.destroy();
  };

  const closeResultPayModal = () => {
    payResultModal.current.destroy();
  };

  const renderResultPayModal = () => {
    payResultModal.current = Modal.confirm({
      width: 400,
      content: '您是否已支付成功？',
      closable: true,
      okText: '支付成功',
      cancelText: '支付失败',
      className: S.payResultModal,
      onOk: () => {
        handlePayResult();
        closeResultPayModal();
      },
      onCancel: () => {
        handlePayResult();
        closeResultPayModal();
      },
    });
  };

  const renderPayModal = () => {
    if (stop) {
      return;
    }

    const content = (
      <Form
        form={payFrom}
        className={S.payFrom}
        labelAlign="left"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{
          type: 0,
        }}
        onFinish={submit}
      >
        <Form.Item label="支付方式" name="type">
          <Radio.Group>
            <Radio value={0}>
              <div className={cx(S.payItem, 'payItem')}>
                <Icon33 />
                <span className={S.text}>微信支付</span>
              </div>
            </Radio>

            <Radio value={1}>
              <div className={cx(S.payItem, 'payItem')}>
                <Icon34 />
                <span className={S.text}>支付宝支付</span>
              </div>
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 4, span: 20 }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error('需要同意北航实验用户付费协议才可购买!')),
            },
          ]}
        >
          <Checkbox>
            <span>同意</span>
            <Button type="link">
              <a href="/agreement/user-register" target="_blank" rel="noopener noreferrer">
                《北航实验用户付费协议》
              </a>
            </Button>
          </Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <div className={S.tools}>
            <Button type="primary" htmlType="submit">
              确定
            </Button>

            <Button onClick={closePayModal}>取消</Button>
          </div>
        </Form.Item>
      </Form>
    );

    payModal.current = Modal.info({
      width: 500,
      title: '支付',
      closable: true,
      icon: null,
      content,
      className: S.payModal,
    });

    payEvent.on(closePayModalEventKeyWord, closePayModal);
  };

  return (
    <button
      onClick={() => {
        isFree ? submit() : renderPayModal();
      }}
      className={S.btnBuy}
      type="submit"
      style={{
        background: isFree && defaultButtonText !== '创建资源' && '#249bf9',
      }}
    >
      {isFree ? defaultButtonText : '立即购买'}
    </button>
  );
}
