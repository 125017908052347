import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, Tag, Tooltip, Rate } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { getExperiments } from '@pages/experiment/api';
import { PageWrap } from '@components/MainLayout/index';
import TagFilter from '@components/TagFilter';
import { headerEvent, searchEventKeyWord, updateSearchPlaceholderWord } from '@components/Header';
// @ts-ignore
import S from './style.module.less';

const customOptions = [
  {
    label: '简单',
    value: 0,
  },
  {
    label: '普通',
    value: 1,
  },
  {
    label: '困难',
    value: 2,
  },
];

export const RenderExperimentState = (props: any) => {
  const { record } = props;
  const history = useHistory();

  const handJump = (record: any) => () => {
    history.push({
      pathname: `/experiment/introduce/${record.id}`,
    });
  };

  return (
    <Button onClick={handJump(record)} type="link" size="small">
      {
        // @ts-ignore
        {
          0: '继 续',
          1: '查 看',
          2: '开 始',
        }[record.userResultState]
      }
    </Button>
  );
};

export default function () {
  const [searchKeyWord, setSearchKeyWord] = useState(''); // 搜索值

  const [firstCategory, setFirstCategory] = useState([]); // 选中的1级类目
  const [secondCategory, setSecondCategory] = useState([]); // 选中的二级类目
  const [customOption, setCustomOption] = useState([]); // 选中的自定义选项

  const [list, setList] = useState([]); // 表格数据源
  const [total, setTotal] = useState(0); // 表格总条数
  const [currPage, setCurrpage] = useState(1); // 表格当前页码
  const [loading, setLoading] = useState(true); // 表格是否是加载中

  const pageSize = 15;
  const paginationParams = {
    showSizeChanger: false,
    total: total,
    pageSize,
    current: currPage,
    onChange: (page: any) => {
      setCurrpage(page);
    },
  };

  useEffect(() => {
    const handleSearch = (searchKeyWord: string) => {
      setSearchKeyWord(searchKeyWord);
    };

    headerEvent.on(searchEventKeyWord, handleSearch);
    headerEvent.emit(updateSearchPlaceholderWord, '搜索实验');

    return () => {
      headerEvent.remove(searchEventKeyWord, handleSearch);
    };
  });

  // 获取实验列表
  useEffect(() => {
    // 选中的二级类目 如果为空则不请求数据
    // 说明回调还没触发
    if (!secondCategory.length) {
      return;
    }

    const params = {
      firstTagIds: String(firstCategory),
      tagIds: String(secondCategory),
      difficulties: String(customOption),
      name: searchKeyWord,
      pageSize,
      pageNum: currPage,
    };

    setLoading(true);
    getExperiments(params).then((result: any) => {
      setList(result.records);
      setTotal(result.count);
      setLoading(false);
    });
  }, [searchKeyWord, firstCategory, secondCategory, customOption, currPage]);

  const onCategoryChange = ({
    selectFirstCategory = [],
    selecSecondCategory = [],
    selectCustomOptions = [],
  }) => {
    setFirstCategory(selectFirstCategory);
    setSecondCategory(selecSecondCategory);
    setCustomOption(selectCustomOptions);
  };

  // 表格数据域
  const columns = [
    {
      title: '实验名称',
      dataIndex: 'name',
      render: (text: any, record: any) => {
        return (
          <div className={S.experimentNameWrap}>
            {
              [
                <Tooltip key="已保存" title="已保存">
                  <PauseCircleOutlined style={{ color: '#fc7510' }} />
                </Tooltip>,

                <Tooltip key="已提交" title="已提交">
                  <CheckCircleOutlined style={{ color: '#1DC11D' }} />
                </Tooltip>,

                <Tooltip key="未开始" title="未开始">
                  <ClockCircleOutlined style={{ color: '#9d9d9d' }} />
                </Tooltip>,
              ][record.userResultState] // userResultState  0已保存   1已提交  2未开始
            }

            <div className={S.experimentName}>{text}</div>

            {
              [
                <Tag key="简单" color="success">
                  简单
                </Tag>,
                <Tag key="普通" color="warning">
                  普通
                </Tag>,
                <Tag key="困难" color="error">
                  困难
                </Tag>,
              ][record.difficulty] // difficulty 难度  0简单  1普通  2困难
            }
          </div>
        );
      },
    },
    {
      title: '实验评分',
      dataIndex: 'score',
      render: (text: any, record: any) => {
        return <Rate disabled value={record.score} />;
      },
    },
    {
      title: '建议时长',
      dataIndex: 'suggestedDuration',
      render: (text: any, record: any) => `${record.suggestedDuration}分钟`,
    },
    // {
    //   title: '价格',
    //   dataIndex: 'price',
    //   render: (text: any, record: any) => {
    //     return (
    //       <span className={S.price}>{Number(record.price) ? `¥ ${record.price}` : '免费'}</span>
    //     );
    //   },
    // },
    {
      title: '操作',
      dataIndex: 'action',
      width: '120px',
      render: (text: any, record: any) => {
        return <RenderExperimentState record={record} />;
      },
    },
  ];

  return (
    <PageWrap>
      <TagFilter
        labels={['学习方向：', '实验标签：', '实验难度：']}
        tagsType="experiment"
        customOptions={customOptions}
        onChange={onCategoryChange}
      />

      <Table
        className={S.experimenTable}
        title={() => {
          return (
            <div className={S.experimenTitleWrap}>
              <p className={S.experimenTitle}>{`共 ${total} 门课程`}</p>
            </div>
          );
        }}
        rowKey="id"
        dataSource={list}
        columns={columns}
        pagination={paginationParams}
        loading={loading}
      />
    </PageWrap>
  );
}
