import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Input, DatePicker, Badge, Modal, Radio, Space, message } from 'antd';
import moment from 'moment';
import { PageWrap } from '@components/MainLayout/index';
import { getOrder, createReceipt } from './api';
// @ts-ignore
import S from './style.module.less';

const { RangePicker } = DatePicker;

export default function (props: any) {
  const [dataSource, setDataSource] = useState<any>([]);
  const [total, setTotal] = useState();
  const [receiptFrom] = Form.useForm();
  const pageSize = 10;
  let invoiceModal: any = null; // 发票弹窗实例对象

  useEffect(() => {
    getDataSource();
  }, []);

  const getDataSource = async (params?: any) => {
    const result: any = await getOrder({
      pageNum: params?.current || 1,
      pageSize: params?.pageSize || pageSize,
      name: params?.name,
      source: params?.source?.toString(),
      beginTime: params?.beginTime,
      endTime: params?.endTime,
      field: 'updated_at',
      order: 'desc',
    });

    setTotal(result.count);
    setDataSource(result.records);
  };

  const handleTableSearch = (values: any) => {
    getDataSource({
      name: values.name,
      beginTime: (values.time && moment(values.time[0]).format('YYYY-MM-DD')) || '',
      endTime: (values.time && moment(values.time[1]).format('YYYY-MM-DD')) || '',
    });
  };

  const onInvoiceModalSubmit = (values: any) => {
    createReceipt(values)
      .then(() => {
        message.success('发票开成功！');
      })
      .finally(() => {
        getDataSource();
        onInvoiceModalCancel();
      });
  };

  const onInvoiceModalCancel = () => {
    invoiceModal.destroy();
    receiptFrom.resetFields();
  };

  const renderInvoiceModal = (id: any) => () => {
    const initialValues = {
      type: 0,
      orderId: id,
      headerType: 0,
    };

    const showModal = () => {
      const content = (
        <Form
          form={receiptFrom}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          onFinish={onInvoiceModalSubmit}
        >
          <Form.Item hidden label="订单主键" name="orderId">
            <Input type="text" />
          </Form.Item>

          <Form.Item
            label="发票类型"
            name="type"
            rules={[{ required: true, message: '请选择发票类型' }]}
          >
            <Radio.Group>
              <Radio value={0}>普通发票</Radio>
              <Radio value={1}>增值税专用发票</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="抬头类型"
            name="headerType"
            rules={[{ required: true, message: '请选择抬头类型' }]}
          >
            <Radio.Group>
              <Radio value={0}>个人或事业单位</Radio>
              <Radio value={1}>企业</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="发票抬头"
            name="header"
            rules={[
              { required: true, message: '请输入发票抬头' },
              {
                pattern: /^[\u4E00-\u9FFF]+$/g,
                message: '抬头中只可填写纯汉字',
              },
              { min: 2, max: 16, message: '发票抬头只可输入2-16位' },
            ]}
          >
            <Input type="text" placeholder="请输入发票抬头" autoComplete="off" />
          </Form.Item>

          <Form.Item dependencies={['headerType']} noStyle>
            {() => {
              if (receiptFrom.getFieldsValue().headerType === 1) {
                return (
                  <>
                    <Form.Item
                      label="税号"
                      name="taxCode"
                      rules={[
                        { required: true, message: '请输入税号' },
                        { pattern: /^[0-9]+$/g, message: '银行账户请输入纯数字' },
                        { min: 18, max: 18, message: '税号只可输入18位' },
                      ]}
                    >
                      <Input type="text" placeholder="请输入税号" />
                    </Form.Item>
                    <Form.Item
                      label="开户银行"
                      name="bank"
                      rules={[
                        { required: true, message: '请输入开户银行' },
                        { pattern: /^[\u4E00-\u9FFF]+$/g, message: '开户银行请输入纯汉字' },
                        { min: 4, max: 20, message: '开户银行只可输入20位' },
                      ]}
                    >
                      <Input type="text" placeholder="请输入开户银行" />
                    </Form.Item>

                    <Form.Item
                      label="银行账户"
                      name="bankAccount"
                      rules={[
                        { required: true, message: '请输入银行账户' },
                        { pattern: /^[0-9]+$/g, message: '银行账户请输入纯数字' },
                        { min: 19, max: 19, message: '银行账户只可输入19位' },
                      ]}
                    >
                      <Input type="text" placeholder="请输入银行账户" />
                    </Form.Item>
                  </>
                );
              }
            }}
          </Form.Item>

          <Form.Item
            label="邮箱地址"
            name="email"
            rules={[
              { required: true, message: '请输入邮箱地址' },
              {
                pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                message: '请输入正确格式的邮箱地址',
              },
              { max: 30, message: '邮箱地址最多输入30位' },
            ]}
          >
            <Input type="email" placeholder="请输入邮箱地址" />
          </Form.Item>

          <Form.Item
            label="联系电话"
            name="phone"
            rules={[
              { required: true, message: '请输入联系电话' },
              { pattern: /^[0-9]+$/g, message: '联系电话只可输入纯数字' },
              { min: 11, max: 11, message: '联系电话只可输入11位' },
            ]}
          >
            <Input type="tel" placeholder="请输入联系电话" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                提交
              </Button>

              <Button onClick={onInvoiceModalCancel}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      );

      invoiceModal = Modal.info({
        width: 460,
        title: '开具发票',
        closable: true,
        icon: null,
        content,
        onCancel: onInvoiceModalCancel,
        className: S.invoiceModal,
      });
    };

    showModal();
  };

  const columns = [
    {
      title: '订单编号',
      dataIndex: 'code',
      ellipsis: true,
    },
    {
      title: '产品类型',
      dataIndex: 'source',
      width: '120px',
      filterMultiple: false,
      filters: [
        {
          text: '课程',
          value: 0,
        },
        {
          text: '实验',
          value: 1,
        },
        {
          text: '考试',
          value: 2,
        },
        {
          text: '资源',
          value: 3,
        },
      ],
      render: (text: any, record: any) => record.sourceName,
    },
    {
      title: '产品名称',
      dataIndex: 'name',
      width: '240px',
    },
    {
      title: '订单金额',
      dataIndex: 'price',
      width: '100px',
      render: (_: any, record: any) => (Number(record.price) ? `￥${record.price}` : '免费'),
    },
    {
      title: '支付方式',
      dataIndex: 'type',
      width: '100px',
      render: (_: any, record: any) => ['微信', '支付宝'][record.type],
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      width: '120px',
      render: (_: any, record: any) =>
        [
          <Badge status="warning" text="待支付" />,
          <Badge status="success" text="支付成功" />,
          <Badge status="error" text="支付失败" />,
        ][record.status],
    },
    {
      title: '订单时间',
      dataIndex: 'createdAt',
      width: '180px',
    },
    {
      title: '操作',
      width: '80px',
      fixed: 'right',
      render: (text: any, record: any) => (
        <Button disabled={record.receiptId} onClick={renderInvoiceModal(record.id)} type="link">
          开具发票
        </Button>
      ),
    },
  ];

  return (
    <PageWrap className={S.pageWrap}>
      <p className={S.tableTitle}>订单管理</p>

      <Form onFinish={handleTableSearch} layout="inline">
        <Form.Item label="产品名称" name="name">
          <Input allowClear placeholder="请输入产品名称" autoComplete="off" />
        </Form.Item>

        <Form.Item label="订单时间" name="time">
          <RangePicker allowClear format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>

      <Table
        rowKey="id"
        // @ts-ignore
        columns={columns}
        dataSource={dataSource}
        pagination={{
          total: total,
          pageSize: pageSize,
        }}
        onChange={(pagination, filters) => {
          getDataSource({
            current: pagination.current,
            pageSize: pagination.pageSize,
            source: filters?.source,
          });
        }}
        className={S.orderTable}
        scroll={{
          x: 1200,
        }}
      />
    </PageWrap>
  );
}
