import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import 'antd/dist/antd.less';
import theme from './css/theme';
import Global from './css/global';
import Routers from './Routers';
import './css/_commonReset.less';

const manifest = window.localStorage.getItem('asset-manifest.json');
fetch('/asset-manifest.json', {
  cache: 'reload',
})
  .then(res => res.text())
  .then(remoteManifest => {
    if (manifest && manifest !== remoteManifest) window.location.reload();
    window.localStorage.setItem('asset-manifest.json', remoteManifest);
  });

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ConfigProvider locale={locale}>
      <ErrorBoundary>
        <Global />
        <Routers />
      </ErrorBoundary>
    </ConfigProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
