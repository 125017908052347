import React, { useRef } from 'react';
import Editor from 'for-editor';
// @ts-ignore
import S from './style.module.less';
import { POST } from '@services/request';
import { Empty, message } from 'antd';
import classNames from 'classnames';

export default function (props: any) {
  const { readonly, height, value, onChange, action, defaultToolbar, maxCount } = props;
  const editor = useRef<any>();

  // 上传图片
  const handleChangeImg = async (file: any) => {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('code', '1');

    const result: any = await POST(action, formdata);

    if (result) {
      message.success('图片上传成功');
      editor.current?.$img2Url(file.name, result);
    }
  };

  const toolbar = {
    h1: true, // h1
    h2: true, // h2
    h3: true, // h3
    h4: true, // h4
    img: true, // 图片
    link: true, // 链接
    code: true, // 代码块
    preview: true, // 预览
    expand: true, // 全屏
    undo: true, // 撤销
    redo: true, // 重做
    save: false, // 保存
    subfield: true, // 单双栏模式
  };

  if (readonly && !value) {
    return (
      <div className={S.editorCard}>
        <Empty style={{ maxHeight: height || '100%', paddingTop: height ? 0 : '50%' }} />
      </div>
    );
  }

  return (
    <div className={classNames([S.editorCard, readonly ? S.isReadonly : ''])}>
      <Editor
        lineNum={0}
        addImg={handleChangeImg}
        height={height || '300px'}
        value={value}
        preview={readonly}
        toolbar={readonly ? {} : { ...toolbar, ...defaultToolbar }}
        onChange={e => {
          onChange && onChange(maxCount ? e.substring(0, maxCount) : e);
        }}
        ref={(e: any) => (editor.current = e)}
      />
    </div>
  );
}
