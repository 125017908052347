import React from 'react';
import { Button, Tag, Space, message, Popconfirm } from 'antd';
import { getRoundNumber } from '@utils/lib-random';
import { ReactComponent as Icon16 } from '@images/icon/icon-16.svg';
import { ReactComponent as Icon17 } from '@images/icon/icon-17.svg';
import { ReactComponent as Icon18 } from '@images/icon/icon-18.svg';
import { ReactComponent as Icon20 } from '@images/icon/icon-20.svg';
import { ReactComponent as Icon21 } from '@images/icon/icon-21.svg';
import { ReactComponent as Icon22 } from '@images/icon/icon-22.svg';
import { ReactComponent as Icon31 } from '@images/icon/icon-31.svg';
import { ReactComponent as Icon32 } from '@images/icon/icon-32.svg';
import { colors } from '@pages/forum/components/ArticleTags';
// @ts-ignore
import S from './style.module.less';
import { postHasApproval, postNoApproval, deleteArticle } from '@pages/forum/api';
import CommentArea from './CommentArea';
import { Link, useHistory } from 'react-router-dom';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';
import EditorPreview from '@components/EditorPreview';

export default function (props: any) {
  const { dataSource, commentsList, hasApproval, page, setpage, getDataSource } = props;

  const history = useHistory();

  const {
    authorName,
    tags,
    title,
    markdownContent,
    createAt,
    comments,
    approvals,
    views,
    top,
    official,
    marrow,
    authorId,
    id,
  } = dataSource || {};

  const canEdit = sessionStorage.getItem(userInfoKey)?.id === authorId;

  // 点赞/取消点赞
  const changeHasApproval = async (flag: boolean) => {
    const api = flag ? postHasApproval : postNoApproval;
    await api(id);
    message.success(hasApproval ? '取消成功' : '点赞成功');
    getDataSource();
  };

  // 删除文章
  const handleDeleteArticle = async () => {
    await deleteArticle(id);
    message.success('删除成功');
    setTimeout(() => {
      history.push('/forum');
    }, 1500);
  };

  const goTagArticle = (tagName: any) => {
    history.push(`/forum/tag-article/${tagName}`);
  };

  const goUserArticleList = () => {
    history.push(`/forum/user-article-list/${authorId}`);
  };

  return (
    <div>
      <div className={S.articleDetails}>
        {canEdit && (
          <div className={S.editWrap}>
            <Space>
              <Button type="primary">
                <Link to={`/forum/article/edit/${id}`}>编辑</Link>
              </Button>
              <Popconfirm
                title="您确认要删除这篇文章吗？"
                onConfirm={handleDeleteArticle}
                okText="确定"
                cancelText="取消"
              >
                <Button>删除</Button>
              </Popconfirm>
            </Space>
          </div>
        )}

        <div className={S.extraWrap}>
          <div className={S.extraTitle}>
            {official && <Icon16 className={S.icon} />}
            {marrow && <Icon17 className={S.icon} />}
            {top && <Icon18 className={S.icon} />}

            <span className={S.userNmae} onClick={goUserArticleList}>
              {authorName || `用户${authorId}`}
            </span>
            <span>发表于</span>
            <time>{createAt}</time>
          </div>

          <div className={S.overView}>
            <div className={S.item}>
              <Icon20 className={S.icon} />
              <span>{views}</span>
            </div>

            <div className={S.item}>
              <Icon21 className={S.icon} />
              <span>{approvals}</span>
            </div>

            <div className={S.item}>
              <Icon22 className={S.icon} />
              <span>{comments}</span>
            </div>
          </div>
        </div>

        <p className={S.articleTitle}>{title}</p>

        <article>
          <EditorPreview value={markdownContent} height="100%" readonly />
        </article>

        <div className={S.asideWrap}>
          <div className={S.tagWrap}>
            <span className={S.title}>文章标签：</span>
            <div className={S.list}>
              {(tags || []).map((item: any) => {
                const colorIndex = getRoundNumber(0, colors.length - 1) as number;
                return (
                  <Tag
                    color={colors[colorIndex]}
                    onClick={() => {
                      goTagArticle(item.name);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.name}
                  </Tag>
                );
              })}
            </div>
          </div>

          <div className={S.tools}>
            <div className={S.toolItem}>
              {hasApproval ? (
                <Icon32 onClick={() => changeHasApproval(false)} />
              ) : (
                <Icon31 onClick={() => changeHasApproval(true)} />
              )}
              <p className={S.text}>{approvals}人已点赞</p>
            </div>
          </div>
        </div>
      </div>

      {/* 评论区 */}
      <CommentArea
        commentsList={commentsList}
        getDataSource={getDataSource}
        page={page}
        setpage={setpage}
        total={comments}
      />
    </div>
  );
}
