import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Statistic, Button, Progress, message, Modal, Anchor } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { subjectItemEvent, addActiveStatetKeyWord, prefix } from '@components/SubjectItem';
import { submitExam } from '@pages/exam/api';
// @ts-ignore
import S from './style.module.less';

let { Countdown } = Statistic;

// @ts-ignore
Countdown = React.memo(Countdown, (prevProps, nextProps) => {
  if (prevProps.value !== nextProps.value) {
    return true;
  }

  return false;
});

message.config({
  top: 70,
});

// 获取区间范围下标
const getRangeIndex = (total: number, size: number, index: number) => ({
  startIndex: 1 + size * index,
  endIndex: Math.min(total, size * (index + 1)),
});

export default function (props: any) {
  const {
    examId,
    questions,
    questionAnswer,
    countdownTime,
    errorCount,
    score,
    type, // 'answer' 作答   'lookOver' 查看,
    onSubmit, // 交卷回调
  } = props;
  const [isdanger, setIsdanger] = useState(false);
  const [currTabsIndex, setCurrTabsIndex] = useState(0);
  const isShowDangerTips = useRef<any>(); // 小于10分钟的提示弹窗是否展示过

  const doCount = Object.keys(questionAnswer).length;
  const total = questions.length;
  const percentage = (doCount / total) * 100;
  let size = 40; // 单个选项卡一共40题

  // 试题过多，避免超过4个分页
  if (total > 160) {
    size = 60;
  }

  // 准备交卷
  const confirmSubmit = () => {
    Modal.confirm({
      title: '考试提示',
      icon: null,
      content: (
        <div className={S.confirmSubmit}>
          <p className="tips">
            共 <strong>{total}</strong> 题， 已答
            <strong>{doCount}</strong>题， 未答
            <strong>{total - doCount}</strong>题
          </p>
          <p className="tips">交卷后将无法修改，是否确认交卷？</p>
        </div>
      ),
      onOk() {
        submit();
      },
    });
  };

  // 交卷
  const submit = () => {
    // 后端需要所有的试题答题结果，但是前端只有存储用户答过的记录
    // 所以先构造一个空的答题记录，然后进行追加覆盖
    const subjectContentTemp: any = {};

    questions.forEach((item: any, index: any) => {
      subjectContentTemp[index] = {
        id: item.id,
        userAnswers: [],
      };
    });

    // 覆盖
    const handleSubjectContent = {
      ...subjectContentTemp,
      ...questionAnswer,
    };

    const subjectContent = Object.keys(handleSubjectContent).map((key: any) => ({
      questionId: handleSubjectContent[key].id,

      // 后端统一要数组
      userAnswers:
        handleSubjectContent[key].userAnswers instanceof Array
          ? handleSubjectContent[key].userAnswers
          : [handleSubjectContent[key].userAnswers],
    }));
    const params = {
      examinationId: examId,
      subjectContent: subjectContent,
    };

    submitExam(params)
      .then((result: any) => {
        // 成功交卷要清除本地答题记录
        // setLocalStorage(examPageUserAnswersKey, {});
        message.success('成功交卷');
      })
      .finally(() => {
        if (typeof onSubmit === 'function') {
          onSubmit();
        }
      });
  };

  const onCountdownChange = (value: any) => {
    const timeLeft = value / 1000; // 单位秒

    // 小于10分钟
    if (timeLeft < 10 * 60) {
      setIsdanger(true);

      if (isShowDangerTips.current !== true) {
        message.warning({
          icon: <ExclamationCircleOutlined className={S.icon} />,
          content: (
            <>
              <p className={S.tips1}>请注意!</p>
              <p className={S.tips2}>
                距离考试结束时间还剩10分钟，请注意答题时间，达到限制时间将无法再进行答题。
              </p>
            </>
          ),
          className: S.countdownWarningTips,
          type: 'warning',
          duration: 5,
        });
      }

      isShowDangerTips.current = true;
    }
  };

  const onCountdownFinish = () => {
    const doCount = Object.keys(questionAnswer).length;
    const total = questions.length;

    Modal.info({
      title: '考试提示',
      icon: null,
      content: (
        <div className={S.confirmSubmit}>
          <p className="tips">
            共 <strong>{total}</strong> 题， 已答
            <strong>{doCount}</strong>题， 未答
            <strong>{total - doCount}</strong>题
          </p>
          <p className="tips">已达考试限制时间，无法再进行答题，已自动交卷</p>
        </div>
      ),
      okText: '知道了',
      onOk() {
        // 清空本地存储
        // 设置有考试结果
        submit();
      },
    });
  };

  const changeCurrTabsIndex = (index: number) => {
    setCurrTabsIndex(index);
  };

  const onOrderItemClick = (currSubjectItem: any) => () => {
    subjectItemEvent.emit(addActiveStatetKeyWord, currSubjectItem);
  };

  const renderResult = () => {
    return (
      <div className={S.result}>
        <p className={S.title}>考试分数</p>
        <p className={S.scroe}>{score}</p>
        <p className={S.overView}>
          共<em>{total}</em>题，答错<em>{errorCount}</em>道
        </p>
        <Button className={S.btnTool} type="primary" block>
          <Link to="/exam">返回考试列表</Link>
        </Button>
      </div>
    );
  };

  const renderCountdown = () => {
    return (
      <div className={S.result}>
        <p className={S.title}>考试时间倒计时</p>

        <Countdown
          value={countdownTime}
          className={cx(S.countdownItem, isdanger && S.danger)}
          onChange={onCountdownChange}
          onFinish={onCountdownFinish}
        />

        <Progress percent={percentage} showInfo={false} />

        <div className={S.tips}>
          <span className={S.item1}>{`已完成${doCount}题`}</span>
          <span className={S.item2}>{`共${total}题`}</span>
        </div>

        <Button onClick={confirmSubmit} className={S.btnTool} type="primary" block>
          提交试题
        </Button>
      </div>
    );
  };

  const renderOrderList = () => {
    const { startIndex, endIndex } = getRangeIndex(total, size, currTabsIndex);

    return (
      <div className={S.orderList}>
        {new Array(endIndex - startIndex + 1).fill('').map((_, index) => {
          const currIndex = index + currTabsIndex * size;
          let className = null;

          if (type === 'answer') {
            className = currIndex in questionAnswer ? S.state1 : S.state2;
          }

          if (type === 'lookOver') {
            const { userScore, questionsScore, questionsType } = questionAnswer[currIndex];
            const isRight = userScore === questionsScore || (questionsType === 4 && userScore > 0);

            className = isRight ? S.state1 : S.state2;
          }

          return (
            <Anchor.Link
              key={`#${prefix}${currIndex}`}
              href={`#${prefix}${currIndex}`}
              title={
                <span
                  onClick={onOrderItemClick({ idString: `${prefix}${currIndex}` })}
                  key={currIndex}
                  className={cx(S.orderItem, className)}
                >
                  {currIndex + 1}
                </span>
              }
            />
          );
        })}
      </div>
    );
  };

  return (
    <Anchor offsetTop={64 + 20} targetOffset={75} className={S.anchor}>
      <div className={cx(S.examPanel, S[type])}>
        {
          // @ts-ignore
          {
            answer: renderCountdown(),
            lookOver: renderResult(),
          }[type]
        }

        <div className={S.answerSheet}>
          <div className={S.titleWrap}>
            <span className={S.title}>答题卡</span>

            <div className={S.mark}>
              <span className={cx(S.item, S.state1)} />
              <span className={cx(S.item, S.state2)} />
            </div>
          </div>

          <div className={S.tabsMenu}>
            {new Array(Math.ceil(total / size)).fill('').map((_, index) => {
              const { startIndex, endIndex } = getRangeIndex(total, size, index);
              const text = `${startIndex}-${endIndex}题`;
              const className = (index === currTabsIndex && S.active) || '';

              return (
                <button onClick={() => changeCurrTabsIndex(index)} key={text} className={className}>
                  {text}
                </button>
              );
            })}
          </div>

          {renderOrderList()}
        </div>
      </div>
    </Anchor>
  );
}
