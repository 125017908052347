import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageWrap } from '@components/MainLayout/index';
import { Tabs, message } from 'antd';
import { register } from '@pages/user/api';
import RegisterForm from '@components/RegisterForm/index';
// @ts-ignore
import S from './style.module.less';

const { TabPane } = Tabs;

export default () => {
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    await register(values);
    message.success('注册成功！去登录吧');

    setTimeout(() => {
      history.push('/user/login');
    }, 2000);
  };

  return (
    <PageWrap className={S.pageWrap}>
      <div className={S.registerWrap}>
        <p className={S.title}>欢迎注册区块链实验平台</p>

        <Tabs defaultActiveKey="phone" className={S.tabs}>
          <TabPane tab="手机注册" key="phone">
            <RegisterForm type="phone" handleSubmit={handleSubmit} />
          </TabPane>

          <TabPane tab="邮箱注册" key="email">
            <RegisterForm type="email" handleSubmit={handleSubmit} />
          </TabPane>
        </Tabs>
      </div>

      <aside className={S.aside}>
        <Link to="/user/login">登录已有账号</Link>
      </aside>
    </PageWrap>
  );
};
