import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { getCommonlyusedTags } from '@pages/forum/api';
import { getRoundNumber } from '@utils/lib-random';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';
import { useHistory } from 'react-router-dom';

export const colors = ['cyan', 'green', 'orange', 'pink', 'purple', 'red'];

export default function (props: any) {
  const { className } = props;

  const history = useHistory();

  // eslint-disable-next-line
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    handleGetTags();
  }, []);

  const handleGetTags = () => {
    getCommonlyusedTags().then((result: any) => {
      const dataSource = result.map((item: any) => ({
        label: item.name,
        value: item.name,
      }));

      setDataSource(dataSource);
    });
  };

  const goTagArticle = (tagName: any) => {
    history.push(`/forum/tag-article/${tagName}`);
  };

  if (!dataSource.length) {
    return null;
  }

  return (
    <dl className={cx(S.tags, className)}>
      <dt>标签</dt>
      <dd>
        {dataSource.map((item: any) => {
          const colorIndex = getRoundNumber(0, colors.length - 1) as number;

          return (
            <Tag
              key={item.value}
              color={colors[colorIndex]}
              onClick={() => {
                goTagArticle(item.label);
              }}
            >
              {item.label}
            </Tag>
          );
        })}
      </dd>
    </dl>
  );
}
