import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumb, Button, message, Modal, Tabs, Spin, Skeleton, Alert, Upload } from 'antd';
import {
  CheckCircleOutlined,
  LeftOutlined,
  InfoCircleFilled,
  UploadOutlined,
  FileTextFilled,
} from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Icon1 } from '@images/icon/icon-1.svg';
import { ReactComponent as Icon2 } from '@images/icon/icon-2.svg';
import { ReactComponent as Icon3 } from '@images/icon/icon-3.svg';
import { PageWrap } from '@components/MainLayout/index';
import {
  getResourcesChainDetails,
  getExperiment,
  saveExperiment,
  submitExperiment,
  saveResultProfiles,
} from '@pages/experiment/api';
import { useBoolean } from 'ahooks';
import ExperimentChapters from '@components/ExperimentChapters';
import MarkdownEditor from '@components/MarkdownEditor';
import { saveNotes } from '@services/notes';
import Attachments from '@components/Attachments';
import { sessionStorage } from '@utils/lib-storage';
import { formatCountDown } from '@utils/lib-time';
import { prefix } from '@utils/lib-request/request';
import { userInfoKey } from '@pages/user/login';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';
import FabricArea from './fabricArea';
import FabricEnvInfoModal from './fabricEnvInfoModal';

import SplitPane from 'react-split-pane';
import './splitPane.css';

const { TabPane } = Tabs;
const { confirm } = Modal;

const defaultIframeHeight = 370;
const tabsNavMenu = [
  {
    key: 'chapter',
    icon: <Icon1 className={S.tabsIcon} />,
    label: '章节',
  },
  {
    key: 'note',
    icon: <Icon2 className={S.tabsIcon} />,
    label: '笔记',
  },
  {
    key: 'download',
    icon: <Icon3 className={S.tabsIcon} />,
    label: '下载',
  },
];

export default function (props: any) {
  const [loading, { toggle: toggleLoading }] = useBoolean(false);

  const [currTabsKey, setCurrTabsKey] = useState<string>('chapter'); // 当前选中的选项卡
  const [isFold, setIsFold] = useState<boolean>(false); // 是折叠状态吗 默认不折叠
  const [experiment, setExperiments] = useState<any>();
  const [blockChainType, setBlockChainType] = useState<any>();
  const [serviceName, setServiceName] = useState();
  const [servicePort, setServicePort] = useState();
  const [podName, setPodName] = useState();
  const [containerName, setContainerName] = useState();
  const [namespace, setNamespace] = useState();
  const [keys, setKeys] = useState<{ [key: string]: string }>();
  const [runTime, setRunTime] = useState<any>(); // 已用时间
  const params = useParams<{ id: string }>();
  const ide = useRef<any>();
  const [, { toggle }] = useBoolean(false);
  const [userInfo] = useState(sessionStorage.getItem(userInfoKey));
  const [iframeHeight1, setIframeHeight1] = useState(defaultIframeHeight);
  const [iframeHeight2, setIframeHeight2] = useState(defaultIframeHeight);
  const [noChangeHeight, setNoChangeHeight] = useState(false);
  const changeRef = useRef<any>();
  const history = useHistory();
  const experimentId = params.id;

  const [fabricChainInfoVoList, setFabricChainInfoVoList] = useState([]); //fabric环境信息
  const [learningDocUrl, setLearningDocUrl] = useState(''); //fabric实验说明链接

  // 实验没有环境的时候
  const noEnv = window.location.pathname.includes('/noResource');

  // 获取服务启动信息
  useEffect(() => {
    if (noEnv) {
      return;
    }

    toggleLoading(true);
    const maxRunCount = 5; // 后端说限制轮询的次数
    let currRunCount = 0;

    const getDetails = () => {
      const params = {
        id: experimentId,
        type: 1, // 0: 大型资源, 1为实验
      };

      getResourcesChainDetails(params)
        .then((result: any) => {
          currRunCount++;

          if (currRunCount >= maxRunCount) {
            clearInterval(timer);
          }

          if (result.isStart) {
            const keys = result.keys ? result.keys[0] : {};

            setFabricChainInfoVoList(result.fabricChainInfoVoList);
            setLearningDocUrl(result.learningDocUrl);

            setBlockChainType(result.blockChainType);
            setServiceName(result.serviceName);
            setServicePort(result.servicePort);
            setKeys(keys);
            setPodName(result.podName);
            setContainerName(result.containerName);
            setNamespace(result.namespace);
            clearInterval(timer);
          }
        })
        .finally(() => {
          toggleLoading(false);
        });
    };

    const timer = setInterval(getDetails, 4 * 1000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  }, [noEnv]);

  // 获取实验详情
  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  // 获取实验详情
  const refresh = useCallback(() => {
    getExperiment(experimentId).then(result => {
      const currTime = result.currentTime;
      const startTime = result.experimentStartDate;
      const runTime = currTime - startTime;

      setExperiments(result);
      setRunTime(runTime);
    });
  }, [experimentId]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRunTime((prevrunTime: any) => prevrunTime + 1000);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [runTime]);

  // 没有IDE，只有终端
  useEffect(() => {
    if ((blockChainType && isInIDE() === true) || !experiment?.envFile?.filePath) {
      return;
    }

    saveExperiment({
      experimentId: experimentId,
      filePath: experiment?.envFile?.filePath ? window.atob(experiment?.envFile?.filePath) : '',
    });

    // eslint-disable-next-line
  }, [blockChainType, experimentId, experiment?.envFile?.filePath]);

  // 事件消息监听 有IDE
  useEffect(() => {
    if (blockChainType && isInIDE() === false) {
      return;
    }

    const action = (e: any) => {
      if (typeof e.data !== 'string') {
        return;
      }

      const payload = JSON.parse(e.data);

      toggle();

      if (payload.action === 'saveWithoutMessage') {
        const params = {
          experimentId,
          filePath: payload.data,
        };

        if (experiment?.userResultState === 2) {
          saveExperiment(params).finally(() => {
            toggle();
          });

          return;
        }

        toggle();
      }

      if (payload.action === 'save') {
        saveExperiment({
          experimentId: experimentId,
          filePath: payload.data,
        }).finally(() => {
          message.success('保存实验成功');
          refresh();
          toggle();
        });
      }

      if (payload.action === 'submit') {
        submitExperiment({
          experimentId: experimentId,
          filePath: payload.data,
        }).finally(() => {
          message.success('提交实验成功');
          refresh();
          toggle();
        });
      }
    };

    window.addEventListener('message', action, false);

    return () => {
      window.removeEventListener('message', action, false);
    };

    // eslint-disable-next-line
  }, [blockChainType, experimentId, refresh, toggle, experiment]);

  const isInIDE = () => {
    return [0, 1].includes(blockChainType);
  };

  const isSubmit = () => {
    return experiment?.userResultState === 1;
  };

  const isFabric = useMemo(() => {
    return fabricChainInfoVoList?.length > 0;
  }, [fabricChainInfoVoList]);

  const contentWrapperHeight = () => {
    if (!isFabric) {
      if (isInIDE()) {
        return iframeHeight1 + 75 + 600;
      }
      return iframeHeight1 + 75;
    }
    if (isFabric) {
      return '100%';
    }
  };

  const postMessage = (msg: string) => {
    ide.current.contentWindow.postMessage(msg, '*');
  };

  const changeVideoJsControl = (newHeight: any) => {
    changeRef.current && clearTimeout(changeRef.current);
    setNoChangeHeight(true);
    setIframeHeight1(newHeight);
    changeRef.current = setTimeout(() => {
      setNoChangeHeight(false);
      setIframeHeight2(newHeight);
    }, 300);
  };

  const onSaveExperiment = (callBack?: () => void) => {
    if (isInIDE()) {
      postMessage('save');
      callBack && callBack();
      return;
    }

    saveExperiment({
      experimentId: experimentId,
      filePath: experiment?.envFile?.filePath ? window.atob(experiment?.envFile?.filePath) : '',
    }).then(() => {
      !callBack && message.success('保存实验成功');
      refresh();
      callBack && callBack();
    });
  };

  const onSubmitExperiment = () => {
    // @ts-ignore
    const { learnedChapterNum, totalChapterNum } = experiment;

    confirm({
      title: '实验提示',
      icon: null,
      content: (
        <div className={S.submitTips}>
          {isInIDE() && (
            <p className="tips">
              共<strong>{totalChapterNum}</strong>个章节， 已学习
              <strong>{learnedChapterNum}</strong>个章节， 还有
              <strong>{totalChapterNum - learnedChapterNum}</strong>个章节未学习
            </p>
          )}
          <p className={S.tips}>请确认是否提交实验结果，提交后将不能再次实验</p>
        </div>
      ),
      onOk() {
        if (isInIDE()) {
          postMessage('submit');
          return;
        }

        submitExperiment({
          experimentId: experimentId,
          filePath: '',
        }).then(() => {
          message.success('提交实验成功');
          refresh();
        });
      },
    });
  };

  const onCloseExperiment = () => {
    confirm({
      title: '关闭实验',
      icon: null,
      content: '确定要关闭当前实验吗？关闭后仍可进入',
      onOk() {
        history.push({
          pathname: '/experiment',
        });
      },
    });
  };

  // @ts-ignore
  const onExperimentalResultUpload = async ({ fileList: newFileList }) => {
    // 说明还没上传完成
    if (!newFileList[0].response && newFileList[0].status !== 'done') {
      return;
    }

    const params = {
      experimentId: experimentId,
      profileList: [
        {
          fileName: newFileList[0].name,
          filePath: newFileList[0].response.data,
        },
      ],
    };

    saveResultProfiles(params).then(() => {
      message.success('实验结果附件保存成功');
    });
  };

  const renderTabBar = () => (
    <>
      <div className={S.tabsNavWrap}>
        {tabsNavMenu.map(item => (
          <div
            key={item.key}
            onClick={() => {
              setCurrTabsKey(item.key);
            }}
            className={cx(S.tabsNavItem, item.key === currTabsKey && S.active)}
          >
            {item.icon}
            <span className={S.tabsNavName}>{item.label}</span>
          </div>
        ))}
      </div>

      <div className={cx(S.toggleButton, isFold && S.fold)} onClick={() => setIsFold(!isFold)}>
        <LeftOutlined />
      </div>
    </>
  );

  const tools1 = (
    <div className={S.result}>
      <CheckCircleOutlined /> 已提交
    </div>
  );

  const tools2 = (
    <>
      <Upload
        action={`${process.env.REACT_APP_API_URL}${prefix}/upload/experimentalResult`}
        listType="picture"
        itemRender={() => ''}
        name={'file'}
        headers={{
          'X-Authorization': sessionStorage.getItem(userInfoKey)?.token || '',
        }}
        maxCount={1}
        onChange={onExperimentalResultUpload}
      >
        <Button className={S.btnUpload} type="text" icon={<UploadOutlined />}>
          上传实验结果
        </Button>
      </Upload>

      <Button
        onClick={() => {
          onSaveExperiment();
        }}
        ghost
      >
        保存实验
      </Button>
      {/* {!isFabric && ( */}
      {
        <Button onClick={() => onSaveExperiment(onSubmitExperiment)} type="primary">
          提交实验
        </Button>
      }
      <Button onClick={onCloseExperiment} type="primary" danger>
        关闭实验
      </Button>
    </>
  );

  if (!experiment) {
    return (
      <PageWrap className={S.pageWrap}>
        <Skeleton active paragraph={{ rows: 20 }} />
      </PageWrap>
    );
  }

  const ideLoadUrl =
    experiment?.userResultState === 2
      ? experiment.envFile?.filePath
      : experiment?.experimentResultDownloadLink;
  const token = `token=${userInfo?.token}`;
  const downloadUrl = `downloadUrl=${ideLoadUrl}`;
  const uploadUrl = `uploadUrl=${process.env.REACT_APP_API_URL}`;
  const ideProjectUrl = '/api/v1/upload/ide-project';
  const privateKey = `privateKey=${keys?.privateKey}`;
  const type = `blockChainType=${blockChainType}`;
  const cryptoTx = `cryptoTx=${keys?.cryptoTx}`;
  const apiUrl = `apiUrl=${process.env.REACT_APP_API_URL}/api/v1/chain/jsonRpc/${serviceName}/${servicePort}`;
  const nounce = `nounce=123`;
  const uuid = `uuid=${userInfo?.id}-${experimentId}`;

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/experiment">在线实验</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{experiment.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.mainWrap}>
        <Tabs
          activeKey={currTabsKey}
          renderTabBar={renderTabBar}
          tabPosition="left"
          className={isFold ? 'fold' : ''}
        >
          <TabPane key="chapter">
            <ExperimentChapters data={experiment} refresh={refresh} />
          </TabPane>

          <TabPane key="note">
            <MarkdownEditor
              content={experiment.userNote?.content || ''}
              onSave={(v: string) => {
                saveNotes({
                  content: v,
                  type: 'experiment',
                  typeId: experimentId,
                }).then(res => message.success('保存成功'));
              }}
            />
          </TabPane>

          <TabPane key="download">
            <Attachments data={experiment.profileVos} />
          </TabPane>
        </Tabs>

        <div
          className={S.contentWrapper}
          style={{
            height: contentWrapperHeight(),
          }}
        >
          <Spin
            spinning={loading}
            style={{
              height: iframeHeight1 + 75,
            }}
          >
            <div className={S.toolsWrap}>
              {!isSubmit() && (
                <div className={S.tips}>
                  {!isFabric && (
                    <>
                      <InfoCircleFilled />
                      <span className={S.text1}>实验环境信息</span>
                    </>
                  )}
                  <span className={S.text2}>已用时间</span>
                  <span className={S.text1}>
                    {`${formatCountDown(runTime).day}天${formatCountDown(runTime).hour}小时${
                      formatCountDown(runTime).minute
                    }分${formatCountDown(runTime).second}秒`}
                  </span>
                </div>
              )}

              <div className={S.btnWrap}>
                {isFabric && (
                  <>
                    <Button
                      className={S.btnUpload}
                      type="text"
                      icon={<FileTextFilled />}
                      href={learningDocUrl}
                      target="_blank"
                      ghost
                    >
                      fabric实验说明
                    </Button>
                    <FabricEnvInfoModal fabricChainInfoVoList={fabricChainInfoVoList} />
                  </>
                )}
                {isSubmit() ? tools1 : tools2}
              </div>
            </div>

            {!noEnv ? (
              <>
                {!isFabric && (
                  <div
                    className={S.experimentWrapper}
                    style={{
                      height: iframeHeight1 + 25,
                    }}
                  >
                    {isInIDE() && (
                      <div className={S.ideWrap}>
                        <div className={S.loading}>
                          <Spin tip="IDE启动中..." />
                        </div>

                        <iframe
                          title="bei-hang-ide"
                          ref={ide}
                          src={`${process.env.REACT_APP_IDE_URL}#${token}&${downloadUrl}&${uploadUrl}${ideProjectUrl}&${privateKey}&${type}&${cryptoTx}&${apiUrl}&${nounce}&${uuid}`}
                        />
                      </div>
                    )}

                    <SplitPane
                      split="horizontal"
                      className="Resizer"
                      minSize={defaultIframeHeight}
                      onChange={e => {
                        changeVideoJsControl(e);
                      }}
                      size={iframeHeight1}
                      style={{
                        position: 'relative',
                      }}
                    >
                      <div
                        className={S.terminalWrap}
                        style={{
                          height: iframeHeight1 - 50,
                          pointerEvents: noChangeHeight ? 'none' : 'auto',
                        }}
                      >
                        <div className={S.loading}>
                          {!noChangeHeight && <Spin tip="终端启动中..." />}
                        </div>

                        <Alert message="终端" type="info" />

                        <iframe
                          title="bei-hang-terminal"
                          src={`${process.env.REACT_APP_API_URL}/xterm.html?podName=${podName}&namespace=${namespace}&container=${containerName}`}
                          style={{
                            height: noChangeHeight ? defaultIframeHeight - 50 : iframeHeight2 - 50,
                          }}
                        />
                      </div>
                      <div />
                    </SplitPane>
                  </div>
                )}
                {isFabric && (
                  <FabricArea
                    runTime={runTime}
                    learningDocUrl={learningDocUrl}
                    onCloseExperiment={onCloseExperiment}
                    fabricChainInfoVoList={fabricChainInfoVoList}
                  />
                )}
              </>
            ) : (
              <div className={cx(S.experimentWrapper, S.noEnv)}>暂无相关环境</div>
            )}
          </Spin>
        </div>
      </div>
    </PageWrap>
  );
}
