import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { getCourses } from '@pages/course/api';
import { PageWrap } from '@components/MainLayout/index';
import TagFilter from '@components/TagFilter';
import { CourseList } from '@components/Course/index';
//import SideTools from '@components/SideTools';
import { headerEvent, searchEventKeyWord, updateSearchPlaceholderWord } from '@components/Header';
// @ts-ignore
import S from './style.module.less';

const customOptions = [
  {
    label: '必修',
    value: 0,
  },
  {
    label: '选修',
    value: 1,
  },
];

export default function () {
  const [searchKeyWord, setSearchKeyWord] = useState(''); // 搜索值
  const [firstCategory, setFirstCategory] = useState([]); // 选中的1级类目
  const [secondCategory, setSecondCategory] = useState([]); // 选中的二级类目
  const [customOption, setCustomOption] = useState([]); // 选中的自定义选项
  const [sort, setSort] = useState('updated_at'); // updated_at上架时间  label标签，即选修和必须

  useEffect(() => {
    const handleSearch = (searchKeyWord: string) => {
      setSearchKeyWord(searchKeyWord);
    };

    headerEvent.on(searchEventKeyWord, handleSearch);
    headerEvent.emit(updateSearchPlaceholderWord, '搜索课程');

    return () => {
      headerEvent.remove(searchEventKeyWord, handleSearch);
    };
  });

  const onCategoryChange = ({
    selectFirstCategory = [],
    selecSecondCategory = [],
    selectCustomOptions = [],
  }) => {
    setFirstCategory(selectFirstCategory);
    setSecondCategory(selecSecondCategory);
    setCustomOption(selectCustomOptions);
  };

  const onSortChange = (event: any) => {
    setSort(event.target.value);
  };

  const renderCourseHeader = ({ total = '' }) => {
    return (
      <div className={S.courseTitleWrap}>
        <p className={S.courseTitle}>{`共 ${total} 门课程`}</p>

        <div className={S.courseFilter}>
          <label className={S.courseFilterLabel}>排序：</label>

          <Radio.Group onChange={onSortChange} value={sort}>
            <Radio value="label">类型</Radio>
            <Radio value="updated_at">时间</Radio>
          </Radio.Group>
        </div>
      </div>
    );
  };

  const courseListParams = {
    firstTagIds: String(firstCategory),
    tagIds: String(secondCategory), // 二级类目
    types: String(customOption), // 自定义选项
    pageSize: 20, // 分页大小
    name: searchKeyWord, // 课程名称
    field: sort, // updated_at上架时间  label标签，即选修和必须
    order: 'desc', // asc正序,  desc倒叙
  };

  return (
    <PageWrap>
      <TagFilter
        labels={['学习方向：', '课程标签：', '课程类型：']}
        tagsType="course"
        customOptions={customOptions}
        onChange={onCategoryChange}
      />

      {
        // 2级类目 是必传的，不可能为空
        // 需要收到2级类目的值才可以进行渲染触发接口请求
        !!secondCategory.length && (
          <div className={S.mainWrap}>
            <CourseList
              getDataSourceFn={getCourses}
              params={courseListParams}
              renderHeader={renderCourseHeader}
            />
          </div>
        )
      }

      {/*<SideTools />*/}
    </PageWrap>
  );
}
