import { UserOutlined } from '@ant-design/icons';
import { createReply } from '@pages/forum/api';
import {
  Avatar,
  Input,
  Comment,
  Button,
  Space,
  message,
  Image,
  Pagination,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// @ts-ignore
import S from './style.module.less';

export default function CommentArea(props: any) {
  const { commentsList, getDataSource, total, page, setpage } = props;
  const { id } = useParams<any>();
  const [requestFlag, setRequestFlag] = useState(false);

  const [value, setValue] = useState<any>('');

  const [replyId, setReplyId] = useState(-1);
  const [replyValue, setReplyValue] = useState<any>('');

  // 提交回复
  const handleSubmit = async (type: number) => {
    if (isNaN(Number(id))) {
      return;
    }

    //type:0:回复文章  1:回复别人的评论
    setRequestFlag(true);
    let body: any = {
      content: '',
      postsId: id,
      replyId: null,
    };
    if (type === 0) {
      body = {
        content: value,
        postsId: id,
        replyId: null,
      };
    }
    if (type === 1) {
      body = {
        content: replyValue,
        postsId: id,
        replyId: replyId,
      };
    }
    if (body?.content?.match(/^[ ]*$/)) {
      message.error('请填写评论内容');
      return;
    }
    await createReply(body);
    message.success('评论成功');
    setReplyId(-1);
    setReplyValue('');
    setValue('');
    setRequestFlag(false);
    getDataSource();
  };

  const changePage = (pageNum: any, pageSize: any) => {
    setpage({
      pageNum,
      pageSize,
    });
  };

  return (
    <div className={S.commentArea}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input.TextArea
          rows={4}
          onChange={e => {
            setReplyId(-1);
            setReplyValue('');
            setValue(e.target.value);
          }}
          maxLength={100}
          value={value}
        />
        <Space className={S.submitButton} size={20}>
          <span>{value.length}/100</span>
          <Button disabled={!value} type="primary" onClick={() => handleSubmit(0)}>
            提交评论
          </Button>
        </Space>
      </Space>
      {commentsList.map((item: any) => {
        const { nickname } = item.commentator;
        return (
          <Comment
            key={item.id}
            actions={[
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div key={S.commentTime}>发布于 {item.createAt}</div>
                <Button
                  type="link"
                  onClick={() => {
                    setReplyValue('');
                    setValue('');
                    setReplyId(item.id);
                  }}
                >
                  回复
                </Button>
              </div>,
              replyId === item.id && (
                <Space size={8} direction="vertical" style={{ width: '100%' }}>
                  <Input.TextArea
                    placeholder={`回复${nickname}:`}
                    rows={4}
                    onChange={e => {
                      setReplyValue(e.target.value);
                    }}
                    maxLength={100}
                    value={replyValue}
                  />
                  <Space className={S.submitButton} size={20}>
                    <span>{replyValue.length}/100</span>
                    <Button
                      disabled={!replyValue}
                      loading={requestFlag}
                      type="primary"
                      onClick={() => handleSubmit(1)}
                    >
                      提交评论
                    </Button>
                  </Space>
                </Space>
              ),
            ]}
            author={nickname || `用户${item.commentator.id}`}
            avatar={
              item.commentator.avatar ? (
                <Image preview={false} src={item.commentator.avatar} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )
            }
            content={
              <Typography.Paragraph
                ellipsis={{ rows: 5, expandable: true, symbol: '展开' }}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {item.content}
              </Typography.Paragraph>
            }
            className={S.commenListItem}
          >
            {(item.replies || []).map((desc: any) => {
              return (
                <Comment
                  actions={[
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div key={S.commentTime}>发布于{desc.createAt}</div>
                      <Button
                        type="link"
                        onClick={() => {
                          setReplyId(desc.id);
                        }}
                      >
                        回复
                      </Button>
                    </div>,
                    replyId === desc.id && (
                      <Space size={8} direction="vertical" style={{ width: '100%' }}>
                        <Input.TextArea
                          placeholder={`回复${desc.commentator.nickname}:`}
                          rows={4}
                          onChange={e => {
                            setReplyValue(e.target.value);
                          }}
                          maxLength={100}
                          value={replyValue}
                        />
                        <Space className={S.submitButton} size={20}>
                          <span>{replyValue.length}/100</span>
                          <Button
                            loading={requestFlag}
                            disabled={!replyValue}
                            type="primary"
                            onClick={() => handleSubmit(1)}
                          >
                            提交评论
                          </Button>
                        </Space>
                      </Space>
                    ),
                  ]}
                  author={
                    <span>
                      {desc.commentator.nickname}&nbsp;
                      {desc.respondent.id ? `回复了  ${desc.respondent.nickname}` : '回复了楼主'}
                    </span>
                  }
                  avatar={
                    desc.commentator.avatar ? (
                      <Image preview={false} src={desc.commentator.avatar} />
                    ) : (
                      <Avatar icon={<UserOutlined />} />
                    )
                  }
                  content={
                    <Typography.Paragraph
                      ellipsis={{ rows: 5, expandable: true, symbol: '展开' }}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {desc.content}
                    </Typography.Paragraph>
                  }
                  className={S.commenListItemChild}
                />
              );
            })}
          </Comment>
        );
      })}
      <div className={S.submitButton} style={{ marginTop: 8 }}>
        <Pagination
          current={page.pageNum}
          pageSize={page.pageSize}
          total={total}
          onChange={changePage}
        />
      </div>
    </div>
  );
}
