import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import { PageWrap } from '@components/MainLayout/index';
import { getExaminationsInfo } from '@pages/exam/api';
import Pay, {
  payEvent,
  onPaySuccessEventKeyWord,
  onFreePaySuccessEventKeyWord,
} from '@components/Pay';
// @ts-ignore
import S from './style.module.less';

export default function Ready(props: any) {
  const [examInfo, setExamInfo] = useState<any>({}); // 考试详细信息
  const examId = props.match.params.id; // 考试id

  useEffect(() => {
    payEvent.on(onPaySuccessEventKeyWord, handlePaySuccess);
    payEvent.on(onFreePaySuccessEventKeyWord, handlePaySuccess);
  }, []);

  useEffect(() => {
    getExaminationsInfo(examId).then((result: any) => {
      const {
        name, // 试卷名称
        subjectCount, // 题数
        totalScore, // 总分
        timeLimit, // 时限
        hasPaid, //是否付费
        price, //价格
      } = result;

      setExamInfo({
        name,
        subjectCount,
        totalScore,
        timeLimit,
        hasPaid,
        price,
      });
    });
  }, [examId]);

  const handlePaySuccess = () => {
    setExamInfo((prevExamInfo: any) => ({
      ...prevExamInfo,
      hasPaid: true,
    }));
  };

  if (!Object.keys(examInfo).length) {
    return null;
  }

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/exam">在线考试</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>区块链基础标题课程</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.mainWrap}>
        <p className={S.examTitle}>{examInfo.name}</p>

        <p className={S.subTitle}>
          <span className={S.item}>{`共${examInfo.subjectCount}道题`}</span>
          <span className={S.item}>{`满分：${examInfo.totalScore}`}</span>
          <span className={S.item}>{`限时：${examInfo.timeLimit}分钟`}</span>
        </p>

        <p className={S.describe}>来测测这些知识你掌握了多少吧！</p>

        <dl className={S.rule}>
          <dt className={S.ruleTitle}>答题开始即开始计时，中途不可暂停，如超时则自动提交。</dt>

          <dd className={S.ruleItem}>
            {`考试共（${examInfo.subjectCount}）道题，总分${examInfo.totalScore}分，及格分数60分`}
          </dd>
          <dd className={S.ruleItem}>我是考试规则第二条，比如考试时间需要合理安排之类的</dd>
          <dd className={S.ruleItem}>我是考试规则第三条，是否需要推荐固定浏览器之类的。</dd>
        </dl>

        <div className={S.tools}>
          {examInfo.hasPaid ? (
            <Button className={S.btnStart} type="primary" size="large">
              <Link to={`/exam/start/${props.match.params.id}`}>去考试</Link>
            </Button>
          ) : (
            <Pay isFree={examInfo.price <= 0} source={2} goodsId={props.match.params.id} />
          )}
        </div>
      </div>
    </PageWrap>
  );
}
