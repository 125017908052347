import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'antd';
import { getExaminations } from '@pages/exam/api';
import { PageWrap } from '@components/MainLayout/index';
import TagFilter from '@components/TagFilter';
import { headerEvent, searchEventKeyWord, updateSearchPlaceholderWord } from '@components/Header';
// @ts-ignore
import S from './style.module.less';
import { examPageLink } from '@utils/lib-url';

const customOptions = [
  {
    label: '随堂考试',
    value: 1,
  },
  {
    label: '期中考试',
    value: 2,
  },
  {
    label: '期末考试',
    value: 3,
  },
];

export const RenderExamState = (props: any) => {
  const { record } = props;
  const history = useHistory();

  const { text, pathname } = examPageLink({
    userResultState: record.userResultState,
    id: record.id,
  });

  const handJump = (record: any) => () => {
    /**
     * 未进行考试
     * 考试进行中
     * 考试了，但是没出结果
     * 有考试结果
     *
     * 前往的相应页面在examPageLink方法中，这个方法在课程的简介页面也有用到
     * 方法路径：src/utils/lib-url/index.ts
     */
    if (pathname) {
      history.push({
        pathname,
      });
    }
  };

  return (
    <Button onClick={handJump(record)} type="link" size="small">
      {text}
    </Button>
  );
};

export default function () {
  const [searchKeyWord, setSearchKeyWord] = useState(''); // 搜索值
  const [firstCategory, setFirstCategory] = useState([]); // 选中的1级类目
  const [secondCategory, setSecondCategory] = useState([]); // 选中的二级类目
  const [customOption, setCustomOption] = useState([]); // 选中的自定义选项
  const [list, setList] = useState([]); // 表格数据源
  const [total, setTotal] = useState(0); // 表格总条数
  const [currPage, setCurrpage] = useState(1); // 表格当前页码
  const [loading, setLoading] = useState(true); // 表格是否是加载中
  const pageSize = 15;
  const paginationParams = {
    showSizeChanger: false,
    total: total,
    pageSize,
    current: currPage,
    onChange: (page: any) => {
      setCurrpage(page);
    },
  };

  useEffect(() => {
    const handleSearch = (searchKeyWord: string) => {
      setSearchKeyWord(searchKeyWord);
    };

    headerEvent.on(searchEventKeyWord, handleSearch);
    headerEvent.emit(updateSearchPlaceholderWord, '搜索考试');

    return () => {
      headerEvent.remove(searchEventKeyWord, handleSearch);
    };
  });

  // 获取考试列表
  useEffect(() => {
    // 选中的二级类目 如果为空则不请求数据
    // 说明回调还没触发
    if (!secondCategory.length) {
      return;
    }

    const params = {
      firstTagIds: String(firstCategory),
      tagIds: String(secondCategory),
      types: String(customOption),
      name: searchKeyWord,
      pageSize,
      pageNum: currPage,
    };

    setLoading(true);
    getExaminations(params).then((result: any) => {
      setList(result.records);
      setTotal(result.count);
      setLoading(false);
    });
  }, [searchKeyWord, firstCategory, secondCategory, customOption, currPage]);

  const onCategoryChange = ({
    selectFirstCategory = [],
    selecSecondCategory = [],
    selectCustomOptions = [],
  }) => {
    setFirstCategory(selectFirstCategory);
    setSecondCategory(selecSecondCategory);
    setCustomOption(selectCustomOptions);
  };

  // 表格数据域
  const columns = [
    {
      ellipsis: true,
      title: '试卷名称',
      dataIndex: 'name',
    },
    {
      width: '100px',
      title: '总分',
      dataIndex: 'totalScore',
    },
    {
      width: '100px',
      title: '题数',
      dataIndex: 'subjectCount',
    },
    {
      width: '100px',
      title: '时限',
      dataIndex: 'timeLimit',
      render: (text: any, record: any, index: any) => {
        return `${text}分钟`;
      },
    },
    {
      title: '出卷时间',
      dataIndex: 'createdAt',
    },
    // {
    //   title: '价格',
    //   dataIndex: 'price',
    //   render: (text: any, record: any) => {
    //     return (
    //       <span className={S.price}>{Number(record.price) ? `¥ ${record.price}` : '免费'}</span>
    //     );
    //   },
    // },
    {
      width: '140px',
      title: '出卷老师',
      dataIndex: 'managerName',
    },
    {
      width: '100px',
      title: '操作',
      dataIndex: 'action',
      render: (text: any, record: any, index: any) => {
        return <RenderExamState record={record} />;
      },
    },
  ];

  return (
    <PageWrap>
      <TagFilter
        labels={['学习方向：', '考试标签：', '考试类型：']}
        tagsType="examination"
        customOptions={customOptions}
        onChange={onCategoryChange}
      />

      <Table
        className={S.examTable}
        rowKey="id"
        dataSource={list}
        columns={columns}
        pagination={paginationParams}
        loading={loading}
      />
    </PageWrap>
  );
}
