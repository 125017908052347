import { GET, POST, prefix } from '@utils/lib-request/request';

// 查询订单
export const getOrder = (params: any) => GET(`${prefix}/order`, params);

// 创建发票
export const createReceipt = (params: any) => POST(`${prefix}/receipt`, params);

// 查询发票详情
export const getReceiptDetail = (params: any) => GET(`${prefix}/receipt/detail`, params);
