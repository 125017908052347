import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Affix, Button, Input, Modal, Popover, Avatar, Badge } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { ReactComponent as Icon4 } from '@images/icon/icon-4.svg';
import { logout } from '@services/user';
import { getMessageUnRead } from './api';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';
import { Event } from '@utils/lib-event';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';

export const headerEvent = new Event();
export const searchEventKeyWord = 'search'; // 输入框搜索事件
export const updateSearchPlaceholderWord = 'updateSearchPlaceholder'; // 更新输入框提示语
export const updateUserInfoEventKeyWord = 'updateUserInfo'; // 更新用户信息
export const getMessageUnReadEventKeyWord = 'getMessageUnRead'; // 获取未读消息数量

const navMenus = [
  {
    href: '/course',
    label: '在线课程',
  },
  {
    href: '/experiment',
    label: '在线实验',
  },
  {
    href: '/exam',
    label: '在线考试',
  },
  {
    href: '/forum',
    label: '论坛',
  },
];

// 不透出【登入】和【注册】入口的页面
const routeShield1 = ['/user/login', '/user/register'];

// 透出全局搜索框的页面
const routeShield2 = ['/course', '/experiment', '/exam'];

export default function (props: any) {
  const [searchKeyWord, setSearchKeyWord] = useState(''); // 搜索值
  const [searchPlaceholder, setSearchPlaceholder] = useState('按下回车键进行搜索');
  const [unReadMessage, setUnReadMessage] = useState(0);
  const [userInfo, setUserInfo] = useState(sessionStorage.getItem(userInfoKey));
  const account = userInfo?.phone || userInfo?.email;

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    headerEvent.on(updateUserInfoEventKeyWord, updateUserInfo);
    headerEvent.on(updateSearchPlaceholderWord, updateSearchPlaceholder);
    headerEvent.on(getMessageUnReadEventKeyWord, getMessage);

    return () => {
      headerEvent.remove(updateUserInfoEventKeyWord, updateUserInfo);
      headerEvent.remove(updateSearchPlaceholderWord, updateSearchPlaceholder);
      headerEvent.remove(getMessageUnReadEventKeyWord, getMessage);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMessage();
    // eslint-disable-next-line
  }, []);

  // 更新用户信息
  const updateUserInfo = (nextUserInfo: any) => {
    if (!nextUserInfo) {
      sessionStorage.removeItem(userInfoKey);
      setUserInfo(null);

      return;
    }

    const newUserInfo = {
      ...userInfo,
      ...nextUserInfo,
    };

    setUserInfo(newUserInfo);
    sessionStorage.setItem(userInfoKey, newUserInfo);
  };

  // 更新搜索框提示语
  const updateSearchPlaceholder = (placeholder: string) => {
    setSearchPlaceholder(placeholder);
  };

  // 获取未读消息数量
  const getMessage = () => {
    if (!userInfo?.id) {
      return;
    }

    getMessageUnRead().then((result: any) => {
      setUnReadMessage(result.data);
    });
  };

  const handleSearch = () => {
    headerEvent.emit(searchEventKeyWord, searchKeyWord);
  };

  // 退出确认提示
  const confirmExitTips = () => {
    Modal.confirm({
      content: '确定要退出登录吗？',
      onOk() {
        logout().then(() => {
          headerEvent.emit(updateUserInfoEventKeyWord, null);
          history.push({
            pathname: '/user/login',
          });
        });
      },
    });
  };

  const UserInfoTips = (
    <>
      <p className={S.userInfoTipItem}>{`账号：${account}`}</p>

      <p className={S.userInfoTipItem}>
        <Link to="/user/home">个人中心</Link>
      </p>

      {/*<p className={S.userInfoTipItem}>*/}
      {/*  <Link to="/resources/manage">资源管理</Link>*/}
      {/*</p>*/}

      {/*<p className={S.userInfoTipItem}>*/}
      {/*  <Link to="/order/manage">订单管理</Link>*/}
      {/*</p>*/}

      <p onClick={confirmExitTips} className={S.userInfoTipItem}>
        退出
      </p>
    </>
  );

  const userInfoJSX = userInfo && (
    <div className={S.userInfoWrap}>
      <Popover content={UserInfoTips} placement="bottomRight" arrowPointAtCenter>
        <Avatar className={S.userImage} src={userInfo.avatar} size={22} icon={<UserOutlined />} />
      </Popover>
      <span className={S.userName}>{userInfo.name}</span>
    </div>
  );

  const loginToolJSX = !routeShield1.includes(location.pathname) && (
    <div>
      <Button className={S.btnLogin} type="text">
        <Link to="/user/login">登录</Link>
      </Button>

      <Button className={S.btnRegister} type="text">
        <Link to="/user/register">注册</Link>
      </Button>
    </div>
  );

  return (
    <Affix>
      <div className={S.headerWrap}>
        <div className={S.logoWrap}>
          <Link to="/">
            <span className={S.logoName}>区块链实验平台</span>
          </Link>
        </div>

        <div className={S.menuItemWrap}>
          {navMenus.map(item => (
            <div
              className={cx(S.menuItem, location.pathname.includes(item.href) && S.active)}
              key={item.label}
            >
              <Link to={item.href}>{item.label}</Link>
            </div>
          ))}
        </div>

        <div className={S.asideWrap}>
          {routeShield2.includes(location.pathname) && (
            <Input
              className={S.searchInput}
              placeholder={searchPlaceholder}
              value={searchKeyWord}
              maxLength={20}
              onChange={e => {
                setSearchKeyWord(e.target.value);
              }}
              onPressEnter={handleSearch}
              suffix={
                <SearchOutlined
                  style={{
                    padding: '10px 0',
                    color: '#C1C4C6',
                  }}
                  onClick={() => {
                    handleSearch();
                  }}
                />
              }
            />
          )}

          {userInfo?.id && (
            <Link to="/forum/message">
              <Badge count={unReadMessage} size="small" offset={[-5, 6]} className={S.remind}>
                <Icon4 />
              </Badge>
            </Link>
          )}

          {userInfo?.id ? userInfoJSX : loginToolJSX}
        </div>
      </div>
    </Affix>
  );
}
