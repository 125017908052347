import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { getTags } from '@pages/forum/api';
// @ts-ignore
import S from './style.module.less';

const { CheckableTag } = Tag;

export default function (props: any) {
  const { setType, setPageRequest } = props;
  // eslint-disable-next-line
  const [dataSource, setDataSource] = useState([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  useEffect(() => {
    handleGetTags();
  }, []);

  const handleGetTags = () => {
    getTags().then((result: any) => {
      const dataSource = result.map((item: any) => ({
        label: item.name,
        value: item.name,
      }));

      setDataSource(dataSource);
      setSelectedTags(dataSource[0].value);
    });
  };

  const onChange = (checked: boolean, tagId: any) => {
    setSelectedTags([tagId]);
    setType(tagId);
    setPageRequest({
      pageNum: 1,
      pageSize: 10,
    });
  };

  return (
    <div className={S.tagFilter}>
      {dataSource.map((tag: any) => (
        <CheckableTag
          key={tag.value}
          onChange={checked => onChange(checked, tag.value)}
          checked={selectedTags.includes(tag.value)}
        >
          {tag.label}
        </CheckableTag>
      ))}
    </div>
  );
}
