import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Avatar, Button, Image, message, Tag, Upload, Modal, Input, Form } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UserOutlined } from '@ant-design/icons';
// @ts-ignore
import { sessionStorage } from '@utils/lib-storage';
import CalendarGraph from './CalendarGraph';
import { userInfoKey } from '@pages/user/login';
import { headerEvent, updateUserInfoEventKeyWord } from '@components/Header';
import { getUsersDetail, updateAvatar } from '@pages/user/api';
import { updateNickname } from '@pages/forum/api';
import { ReactComponent as Icon29 } from '@images/icon/icon-29.svg';
import { prefix } from '@utils/lib-request/request';

// @ts-ignore
import S from './style.module.less';

// 用户信息
export default function UserInfo() {
  const [userInfo, setUserInfo] = useState<any>({}); // 用户信息
  const [describe, setDescribe] = useState<any>([]); // 用户属性  学号 手机号....

  const defaultUserImage = <Avatar className={S.userImage} size={64} icon={<UserOutlined />} />;
  const userImage = <Image className={S.userImage} preview={false} src={userInfo.avatar} />;

  let nicknameModal: any = null; // 修改昵称弹窗实例对象

  useEffect(() => {
    getUsersDetail().then((result: any) => {
      const {
        id,
        avatar, // 头像
        email, // 邮箱
        name, // 用户名
        passwd, // 密码
        phone, // 手机号
        realName, // 真实姓名
        studentNumber, // 学号
        nickname,
        type,
      } = result;

      setUserInfo({
        id,
        avatar,
        email,
        name,
        passwd,
        phone,
        realName,
        studentNumber,
        nickname,
        type,
      });
    });
  }, []);

  useEffect(() => {
    const describeEnum = [
      {
        label: (
          <>
            <span>学</span>
            <span>号：</span>
          </>
        ),
        value: userInfo.studentNumber,
      },
      // {
      //   label: '姓名：',
      //   value: userInfo.name,
      // },
      {
        label: '手机号码:',
        value: userInfo.phone,
      },
      {
        label: '邮箱号码:',
        value: userInfo.email,
      },
      {
        label: (
          <>
            <span>密</span>
            <span>码：</span>
          </>
        ),
        value: userInfo.passwd,
        extra: (
          <Button href="/user/change-password" type="link">
            修改
          </Button>
        ),
      },
    ];

    setDescribe(describeEnum);
  }, [userInfo]);

  const showNicknameModal = () => {
    const content = (
      <Form initialValues={{ nickname: userInfo.nickname }} onFinish={handleUpdateNickname}>
        <Form.Item name="nickname" rules={[{ required: true, message: '请输入新的昵称!' }]}>
          <Input allowClear maxLength={10} placeholder="请输入新的昵称" />
        </Form.Item>

        <Form.Item>
          <div className={S.tools}>
            <Button
              onClick={() => {
                nicknameModal.destroy();
              }}
            >
              取消
            </Button>

            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    );

    nicknameModal = Modal.confirm({
      width: 360,
      title: '修改昵称',
      icon: null,
      closable: true,
      className: S.confirmNicknameModal,
      content: content,
    });
  };

  const handleUpdateNickname = (values: any) => {
    const params = {
      id: userInfo.id,
      nickname: values.nickname,
    };

    updateNickname(params)
      .then(() => {
        message.success('昵称修改成功!');

        setUserInfo({
          ...userInfo,
          nickname: values.nickname,
        });

        headerEvent.emit(updateUserInfoEventKeyWord, {
          nickname: values.nickname,
        });
      })
      .finally(() => {
        nicknameModal.destroy();
      });
  };

  // @ts-ignore
  const onAvatarUpload = ({ fileList: newFileList }) => {
    // 说明还没上传完成
    if (!newFileList[0].response) {
      return;
    }

    updateAvatar({
      avatar: newFileList[0].response.data,
    }).then(() => {
      const nextUserInfo = {
        ...userInfo,
        ...sessionStorage.getItem(userInfoKey), // 主要是为了获取token
        avatar: newFileList[0].response.data,
      };

      setUserInfo(nextUserInfo);
      headerEvent.emit(updateUserInfoEventKeyWord, nextUserInfo);
      message.success('头像修改成功');
    });
  };

  if (!Object.keys(userInfo).length) {
    return null;
  }

  return (
    <div className={S.userInfoModule}>
      <div className={S.user}>
        <div className={S.imageWrap}>
          <ImgCrop rotate>
            <Upload
              // @ts-ignore
              action={`${process.env.REACT_APP_API_URL}${prefix}/upload/updateAvatar`}
              listType="picture"
              // @ts-ignore
              accept=".png,.jpg,.jpeg"
              maxCount={1}
              itemRender={() => ''}
              name={'file'}
              headers={{
                // @ts-ignore
                'X-Authorization': sessionStorage.getItem(userInfoKey)?.token || '',
              }}
              onChange={onAvatarUpload}
            >
              {userInfo.avatar ? userImage : defaultUserImage}

              <button className={S.btn}>更换头像</button>
            </Upload>
          </ImgCrop>
        </div>

        <p onClick={showNicknameModal} className={S.userName}>
          {userInfo.nickname || `用户${userInfo.id}`}

          <Icon29 className={S.icon} />
        </p>
        {[<Tag color="#FA8C16">校内</Tag>, <Tag color="#36CFC9">校外</Tag>][userInfo.type]}
      </div>

      <div className={S.describe}>
        {describe.map((item: any) => (
          <div key={item.value} className={S.row}>
            <span className={S.attr}>{item.label}</span>
            <span className={S.value}>
              {item.value || '-'} {item.extra}
            </span>
          </div>
        ))}
      </div>

      <CalendarGraph />
    </div>
  );
}
