import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Input, message, Image } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { login } from '@pages/user/api';
import { sessionStorage } from '@utils/lib-storage';
import { headerEvent, updateUserInfoEventKeyWord } from '@components/Header';
import { getQueryParams } from '@utils/lib-url';
import { PageWrap, MainWidth } from '@components/MainLayout';
// @ts-ignore
import S from './style.module.less';

import loginIll from '../../../static/images/login_ill.svg';
import loginIcon from '../../../static/images/login_icon.svg';

// 用户信息本地存储key
export const userInfoKey = 'loginPageUserInfo';

export const errorIconStyle = {
  color: 'red',
  marginRight: '8px',
};

export default function () {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loginIng, setLoginIng] = useState(false);
  const query: any = getQueryParams();
  const [errTips, setErrTips] = useState<any>();

  const resetErrTips = () => {
    setErrTips(null);
  };

  const handleSubmit = async (values: any) => {
    setLoginIng(true);
    resetErrTips();

    login(values)
      .then(userInfo => {
        message.success('登录成功');
        sessionStorage.setItem(userInfoKey, userInfo);

        setTimeout(() => {
          headerEvent.emit(updateUserInfoEventKeyWord, userInfo);
          if (query.redirect) {
            history.push(query.redirect);
          } else {
            history.push('/');
          }
        }, 1000);
      })
      .catch(error => {
        setErrTips(
          <>
            <CloseCircleOutlined style={errorIconStyle} />
            {error.message}
          </>,
        );
      })
      .finally(() => {
        setLoginIng(false);
      });
  };

  return (
    <PageWrap className={S.pageWrap}>
      <MainWidth className={S.mainWrap}>
        <Image src={loginIll} preview={false} />

        <div className={S.loginWrap}>
          <div className={S.titlWrap}>
            <Image src={loginIcon} preview={false} />
            <span className={S.text}>区块链教学实验平台</span>
          </div>

          <div className={S.formWrap}>
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: (
                      <>
                        <CloseCircleOutlined style={errorIconStyle} />
                        请输入学号/手机/邮箱
                      </>
                    ),
                  },
                ]}
              >
                <Input onFocus={resetErrTips} placeholder="请输入学号/手机/邮箱" />
              </Form.Item>

              <Form.Item
                name="passwd"
                help={errTips}
                rules={[
                  {
                    required: true,
                    message: (
                      <>
                        <CloseCircleOutlined style={errorIconStyle} />
                        请输入正确的密码
                      </>
                    ),
                  },
                ]}
              >
                <Input.Password onFocus={resetErrTips} type="password" placeholder="请输入密码" />
              </Form.Item>

              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loginIng}
                    style={{ width: '100%' }}
                  >
                    登录
                  </Button>
                )}
              </Form.Item>
            </Form>

            <div className={S.tools}>
              <Link to="/user/change-password">忘记密码？</Link>
              <Link to="/user/register">注册新账号</Link>
            </div>
          </div>
        </div>
      </MainWidth>
    </PageWrap>
  );
}
