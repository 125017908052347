import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  boxShadow,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { Link } from 'react-router-dom';
import css from '@styled-system/css';
import theme from '../../css/theme';

export const toStyledComponent = (vDOm: any) => {
  return styled(vDOm)<
    ColorProps &
      SpaceProps &
      TypographyProps &
      LayoutProps &
      PositionProps &
      BorderProps &
      ShadowProps &
      BackgroundProps &
      FlexboxProps
  >`
    box-sizing: border-box;
    min-width: 0;
    ${position}
    ${space}
  ${layout}
  ${typography}
  ${color}
  ${border}
  ${boxShadow}
  ${background}
  ${flexbox}
  `;
};

export const RoundGhostButton = toStyledComponent(styled.div<{ color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.colors.accent)};
  border-color: ${props => (props.color ? props.color : props.theme.colors.accent)};
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  width: fit-content;
  display: inline-flex;
  transition: all 0.3s;
  &:hover {
    background: ${props => (props.color ? props.color : props.theme.colors.accent)};
    color: ${theme.colors['midGray']};
  }
`);

export const RoundButton = toStyledComponent(styled.div<{ color?: string }>`
  background: ${props => (props.color ? props.color : props.theme.colors.accent)};
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  width: fit-content;
  display: inline-flex;
  transition: all 0.3s;
  &:hover {
    background: ${props => lighten(0.2, props.color ? props.color : props.theme.colors.accent)};
  }
`);

export const RoundButton2 = toStyledComponent(styled.div<{ color?: string }>`
  background: ${props => (props.color ? props.color : 'rgba(60, 18, 0, 0.3)')};
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  width: fit-content;
  display: inline-flex;
  transition: all 0.3s;
  border: 2px solid #fff;
  &:hover {
    background: ${props => lighten(0.2, props.color ? props.color : props.theme.colors.accent)};
  }
`);

export const RoundButton3 = toStyledComponent(styled.div<{ bgColor: string }>`
  border: 2px solid;
  border-image-source: linear-gradient(180deg, rgba(254, 135, 8, 1), rgba(252, 79, 3, 1));
  background: ${props => `linear-gradient(${props.bgColor}, ${props.bgColor}) padding-box,
    linear-gradient(180deg, rgba(254, 135, 8, 1), rgba(252, 79, 3, 1)) border-box !important`};
  color: rgba(63, 63, 63, 1);
  border-radius: 30px;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  width: fit-content;
  display: inline-flex;
  transition: all 0.3s;
  &:hover {
    background: linear-gradient(transparent, transparent) padding-box,
      linear-gradient(180deg, rgba(254, 135, 8, 1), rgba(252, 79, 3, 1)) border-box !important;
    color: #fff;
  }
`);

export const MenuItemBox = toStyledComponent(styled.div<{ activated?: boolean }>`
  cursor: pointer;
  width: fit-content;
  display: inline-flex;
  transition: all 0.3s;
  position: relative;
  color: #7f7f7f;
  font-weight: 500;
  :after {
    content: '';
    position: absolute;
    bottom: -9px;
    height: 2px;
    background-color: ${props => props.theme.colors.accent};
    display: block;
    width: ${props => (props.activated ? '100%' : '0%')};
    opacity: ${props => (props.activated ? '1' : '0')};
    transform: ${props => (props.activated ? 'scaleX(1)' : 'scaleX(0)')};
    transition: all 0.2s cubic-bezier(0.18, 0.89, 0.17, 0.88), opacity 0.15s ease;
  }
  ${props =>
    !props.activated &&
    `
      &:hover {
        :after {
          transform: scaleX(1);
          opacity: 1;
          width: 100%;
        }
      }
    `}
`);

export const Box = styled.div<
  ColorProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    PositionProps &
    BorderProps &
    ShadowProps &
    BackgroundProps &
    FlexboxProps
>`
  box-sizing: border-box;
  min-width: 0;
  line-height: 1.6;
  position: relative;
  ${position}
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${border}
  ${boxShadow}
  ${background}
  ${flexbox}
`;

export const Flex = styled.div<
  ColorProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    FlexboxProps &
    PositionProps &
    BorderProps &
    BackgroundProps &
    ShadowProps
>`
  display: flex;
  line-height: 1;
  position: relative;
  transition: all 0.3s;
  ${position}
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${flexbox}
  ${border}
  ${background}
  ${boxShadow}
`;

export const Text = styled('span')<
  TypographyProps & SpaceProps & ColorProps & LayoutProps & BackgroundProps
>(
  compose(typography, space, color, layout, background),
  css({
    cursor: 'pointer',
    '&:hover': {
      color: 'accent',
    },
  }),
);

export const Span = styled('span')<
  ColorProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    PositionProps &
    BorderProps &
    ShadowProps &
    BackgroundProps &
    FlexboxProps
>`
  ${position}
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${border}
  ${boxShadow}
  ${background}
  ${flexbox}
`;

export const Card = styled(Box)(
  css({
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.09)',
    },
  }),
);

export const Row = styled(Flex)(
  css({
    display: 'flex',
    flexDirection: 'row',
  }),
);

export const LineClamp = styled.div<{ lineNumber: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lineNumber};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const RivRound = styled.div`
  width: 45px;
  height: 2px;
  border-radius: 100%;
  display: inline-flex;
  background-color: ${props => props.theme.colors.accent};
`;

export const HLine = styled.div<{ width?: string; color?: string; bgColor?: string }>`
  display: block;
  width: ${props => props.width || '100%'};
  height: 1px;
  background: ${props => props.bgColor || '#252428'};
  position: relative;
  :after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    background-color: ${props => props.color || 'rgba(255,255,255, 0.2)'};
    top: 0;
  }
`;

export const AniBtn = toStyledComponent(styled.button`
  display: inline-flex;
  border: 1px solid #fb4f03;
  background: transparent;
  width: fit-content;
  color: #fb4f03;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 1rem 3.5rem;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: #fb4f03;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #fb4f03;
    transition: all 0.3s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
    .icon {
      fill: #fff;
    }
  }
`);

export const Round = styled.div`
  width: 5px;
  height: 5px;
  background: rgba(251, 79, 3, 1);
  border-radius: 50%;
`;

export const Href = toStyledComponent(styled.a`
  color: #413333;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
  transition: all 0.3s;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #e61e3c;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 0.3s;
    transform: scaleX(0.5);
  }
  &:hover {
    color: #e61e3c;
    &:after {
      opacity: 1;
    }
  }
`);

export const SLink = styled(Link)<
  ColorProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    PositionProps &
    BorderProps &
    ShadowProps &
    BackgroundProps &
    FlexboxProps
>`
  box-sizing: border-box;
  min-width: 0;
  ${position}
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${border}
  ${boxShadow}
  ${background}
  ${flexbox}
`;

export const SImg = toStyledComponent(styled('img')`
  object-fit: contain;
`);

export function CommonTitle({
  title,
  sub,
  text,
  style,
  textAlign = 'center',
}: {
  title: string;
  sub: string;
  text?: string;
  style?: CSSProperties;
  textAlign?: string | string[];
}) {
  return (
    <Box textAlign={textAlign as any} style={style}>
      <H3>{title}</H3>
      <H5>{sub}</H5>
      <P>{text}</P>
    </Box>
  );
}

export const BannerText = toStyledComponent(styled.div`
  font-size: 2.8rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 4rem;
  text-shadow: 1px 0px 6px rgba(0, 0, 0, 1);
  text-stroke: 1px rgba(0, 0, 0, 1);
  text-align: center;
`);
export const BannerTitle = styled.div`
  font-size: 6rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 8.4rem;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 1);
  margin-bottom: 1rem;
`;

export const TwoToneCard = toStyledComponent(styled.div`
  background: linear-gradient(135deg, rgba(241, 241, 244, 1) 0%, rgba(255, 255, 255, 1) 100%);
  box-shadow: 10px 15px 20px 0px rgba(210, 206, 204, 0.5);
  border-radius: 20px;
  padding: 3.3rem 2rem;
  margin-right: 2.8rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  color: rgba(83, 83, 83, 1);
  &:hover {
    background: linear-gradient(319deg, rgba(255, 141, 55, 0.89) 0%, rgba(252, 79, 3, 1) 100%);
    box-shadow: 10px 15px 30px 0px rgba(210, 206, 204, 0.45);
    color: #fff;
  }
`);

export const JoinBtn = toStyledComponent(styled('div')`
  background: #0eb4f4;
  padding: 10px 32px;
  box-shadow: 7px 12px 15px 0px #c8e5ff;
  border-radius: 4px;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    transform: translateY(-4px);
    box-shadow: 7px 18px 15px 0px rgba(200, 229, 255, 0.8);
    background: #3dc1f6;
  }
  &:active {
    transform: translateY(-4px);
    box-shadow: 7px 12px 15px 0px #c8e5ff;
    background: ${darken(0.1, '#0eb4f4')};
  }
`);
export const RedBtn = toStyledComponent(styled('div')`
  background: #e61e3c;
  padding: 10px 32px;
  box-shadow: 7px 12px 15px 0px #ffc8d6;
  border-radius: 4px;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    transform: translateY(-4px);
    box-shadow: 7px 18px 15px 0px rgba(230, 30, 60, 0.2);
    background: ${lighten(0.1, '#e61e3c')};
  }
  &:active {
    transform: translateY(-4px);
    box-shadow: 7px 12px 15px 0px #ffc8d6;
    background: ${darken(0.1, '#e61e3c')};
  }
`);
export const Eng = toStyledComponent(styled.div`
  font-size: 60px;
  font-weight: 500;
  color: #d9e4ed;
  position: relative;
  top: 54px;
  z-index: 0;
  line-height: 84px;
`);

export const ArrowRight = () => {
  return (
    <Box color="inherit" ml="12px" display="inline-block" style={{ transform: 'scaleY(1.4)' }}>
      {'>'}
    </Box>
  );
};
export const H3 = toStyledComponent(styled.div`
  font-weight: 600;
  color: rgba(52, 52, 52, 1);
  line-height: 4rem;
  font-size: 3rem;
`);
export const H4 = toStyledComponent(styled.div`
  font-weight: 600;
  color: #343434;
  line-height: 3.3rem;
  font-size: 2.4rem;
`);
export const H5 = toStyledComponent(styled.div`
  font-weight: 500;
  color: rgba(136, 136, 136, 1);
  line-height: 2.65rem;
  font-size: 1.9rem;
  margin-bottom: 2rem;
`);
export const P = toStyledComponent(styled.div<{ textAlign?: 'string' }>`
  font-weight: 500;
  line-height: 1.6;
  font-size: 1.4rem;
  text-align: ${props => props.textAlign || 'justify'};
`);
