import React from 'react';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  return (
    <div className={S.footer}>
      区块链实验平台
    </div>
  );
}
