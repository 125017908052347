import React from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { Tabs } from 'antd';
// @ts-ignore
import { PageWrap } from '@components/MainLayout/index';
import { CourseList } from '@components/Course/index';
import UserInfo from './UserInfo';
import UserExperiments from './UserExperiments';
import UserExam from './UserExam';
import { getUsersCourses } from '@pages/user/api';
import { getQueryParams } from '@utils/lib-url';
// @ts-ignore
import S from './style.module.less';

const { TabPane } = Tabs;

export default function (props: any) {
  const history = useHistory();
  // @ts-ignore
  const currTab = getQueryParams()?.currTab || 'course';

  return (
    <PageWrap className={S.pageWrap}>
      <UserInfo />

      <Tabs
        defaultActiveKey={currTab}
        onChange={activeKey => {
          history.replace(`/user/home?currTab=${activeKey}`);
        }}
        className={S.mainContent}
      >
        <TabPane tab="我的课程" key="course" className={S.courseModule}>
          <CourseList
            showPriceInfo={false}
            getDataSourceFn={getUsersCourses}
            params={{
              pageSize: 15,
            }}
          />
        </TabPane>

        <TabPane tab="我的实验" key="experiment" className={S.experimentModule}>
          <UserExperiments />
        </TabPane>

        <TabPane tab="我的考试" key="exam" className={S.examModule}>
          <UserExam />
        </TabPane>
      </Tabs>
    </PageWrap>
  );
}
