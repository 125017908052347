import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import { getPointRanking } from '@pages/forum/api';
import cx from 'classnames';
// @ts-ignore
import S from './style.module.less';
import { UserOutlined } from '@ant-design/icons';

export default function (props: any) {
  const { className } = props;

  // eslint-disable-next-line
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    handleGetPointRanking();
  }, []);

  const handleGetPointRanking = () => {
    getPointRanking().then((result: any) => {
      setDataSource(result);
    });
  };

  const jumpUserArticlePage = (id: any) => () => {
    window.open(`/forum/user-article-list/${id}`);
  };

  if (!dataSource.length) {
    return null;
  }

  return (
    <dl className={cx(S.prestige, className)}>
      <dt>声望排行榜</dt>
      <dd>
        {dataSource.map((item: any) => (
          <div key={item.id} className={S.userItem}>
            <div className={S.userImageWrap}>
              <Avatar src={item.avatar} icon={<UserOutlined />} size={32} />
            </div>

            <span onClick={jumpUserArticlePage(item.id)} className={S.userName}>
              {item.nickname || `用户${item.id}`}
            </span>
            <span className={S.score}>{`${item.point}分`}</span>
          </div>
        ))}
      </dd>
    </dl>
  );
}
