import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@components/ui/commons';

const NotFound = () => (
  <Box paddingY="300px" textAlign="center" fontSize="3rem">
    <Box>
      404 页面没有找到... | <Link to="/">返回主页</Link>
    </Box>
  </Box>
);

export default NotFound;
