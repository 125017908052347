import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Form, Input, Statistic } from 'antd';
import { Flex } from '../ui/commons';
import { CloseCircleOutlined } from '@ant-design/icons';
import { sendCheckCode } from '@services/user';
import { sessionStorage } from '@utils/lib-storage';
import { checkResultTips } from '@pages/user/changePassword';
// @ts-ignore
import S from './style.module.less';

const { Countdown } = Statistic;
const errorIconStyle = {
  color: 'red',
  marginRight: '8px',
};

const isCountdownKey = 'registerPageIsCountdown'; // 是不是在倒计时 本地存储key
const countdownStartTimeKey = 'registerPageCountdownStartTime'; // 倒计时的开始时间 本地存储key

export default ({ handleSubmit, type }: { handleSubmit: Function; type: 'email' | 'phone' }) => {
  const isCountdownDefaults = sessionStorage.getItem(isCountdownKey) || false; // 是不是在倒计时（本地存储）
  const countdownStartTimeDefaults = sessionStorage.getItem(countdownStartTimeKey) || Date.now();
  const isExpired = Date.now() - +sessionStorage.getItem(countdownStartTimeKey) >= 60 * 1000; // 是不是过期了，已经超过60秒了

  const [isCountdown, setIsCountdown] = useState<boolean>(isExpired ? false : isCountdownDefaults); // 如果时间过期了，则不倒计时;  如果没过期 按照原有状态
  const [countdownStartTime, setCountdownStartTime] = useState<number>(countdownStartTimeDefaults); // 倒计时的开始时间

  const [codeStateCode, setCodeStateCode] = useState<0 | 1 | 2 | 3 | 4>(0); // 验证码校验提示语级别
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const getTypeText = {
    phone: '手机号',
    email: '邮箱',
  }[type];

  // 获取验证码
  const sendCode = async () => {
    const checkResult = await form
      .validateFields([type])
      .then(() => true)
      .catch(() => false);

    // 校验不通过
    if (!checkResult) {
      return;
    }

    const time = Date.now();
    const channelValue = form.getFieldValue(type);

    const params = {
      channel: type, // 校验类型
      checkCodeType: 'register', // 注册类型'register'   忘记密码和重置密码 'forget_passwd'
      channelValue: channelValue,
    };

    sendCheckCode(params)
      .then((result: any) => {
        // 开始倒计时 本地存储记录相关信息
        sessionStorage.setItem(isCountdownKey, true); // 标记处于倒计时状态
        sessionStorage.setItem(countdownStartTimeKey, time); // 记录倒计时的开始时间
        setIsCountdown(true);
        setCountdownStartTime(time);

        setCodeStateCode(1);
      })
      .catch(() => {
        setCodeStateCode(4);
      });
  };

  // 监听倒计时结束
  const onCountdownFinish = () => {
    sessionStorage.setItem(isCountdownKey, false); // 标记处于倒计时状态
    sessionStorage.setItem(countdownStartTimeKey, 0); // 记录倒计时的开始时间

    setIsCountdown(false);
    setCountdownStartTime(0);
    setCodeStateCode(0);
  };

  const btnGetCode = (
    <Button onClick={sendCode} type="primary" style={{ width: '120px' }}>
      获取验证码
    </Button>
  );

  const btnCountdown = (
    <Button disabled type="primary" style={{ width: '120px' }}>
      {isCountdown && (
        <Countdown
          value={Date.now() + 60 * 1000 - (Date.now() - countdownStartTime)}
          onFinish={onCountdownFinish}
          format="ss秒后可重发"
          valueStyle={{ fontSize: '14px', color: '#C1C4C6' }}
        />
      )}
    </Button>
  );

  const onSubmit = (values: any) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 3000);

    handleSubmit(values);
  };

  return (
    <Form form={form} onFinish={onSubmit} className={S.registerForm}>
      <Form.Item
        className="normal"
        name={type}
        validateTrigger="onBlur"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value, callback) {
              // 手机号校验
              if (type === 'phone' && !/^1\d{10}$/gi.test(value)) {
                return Promise.reject(
                  <>
                    <CloseCircleOutlined style={errorIconStyle} />
                    请输入正确的手机号！
                  </>,
                );
              }

              // 邮箱校验
              if (
                type === 'email' &&
                !/^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(value)
              ) {
                return Promise.reject(
                  <>
                    <CloseCircleOutlined style={errorIconStyle} />
                    请输入正确的邮箱！
                  </>,
                );
              }

              // 千万记得写这一句，不然表单校验通过不会执行成功回调
              callback();
            },
          }),
        ]}
      >
        <Input placeholder={`请输入${getTypeText}`} />
      </Form.Item>

      <Form.Item
        className="normal"
        name="passwd"
        validateTrigger="onBlur"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value = '', callback) {
              if (value.length <= 8) {
                return Promise.reject(
                  <>
                    <CloseCircleOutlined style={errorIconStyle} />
                    密码长度需要超过8位
                  </>,
                );
              }

              if (!(/[\d]/gi.test(value) && /[a-zA-Z]/gi.test(value))) {
                return Promise.reject(
                  <>
                    <CloseCircleOutlined style={errorIconStyle} />
                    必须包含英文和数字
                  </>,
                );
              }

              // 千万记得写这一句，不然表单校验通过不会执行成功回调
              callback();
            },
          }),
        ]}
      >
        <Input.Password type="password" placeholder="密码" />
      </Form.Item>

      <Form.Item
        className="normal"
        name="passwdRepeat"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value, callback) {
              if (value !== getFieldValue('passwd')) {
                return Promise.reject(
                  <>
                    <CloseCircleOutlined style={errorIconStyle} />
                    两次密码不一致
                  </>,
                );
              }

              // 千万记得写这一句，不然表单校验通过不会执行成功回调
              callback();
            },
          }),
        ]}
      >
        <Input.Password type="password" placeholder="确认密码" />
      </Form.Item>

      <Form.Item
        name="checkCode"
        className="normal"
        help={checkResultTips[codeStateCode]}
        validateStatus={[2, 3, 4].includes(codeStateCode) ? 'error' : 'success'}
        rules={[
          {
            required: true,
            message: (
              <>
                <CloseCircleOutlined style={errorIconStyle} />
                请输入正确的验证码
              </>
            ),
          },
        ]}
      >
        <Flex>
          <Input placeholder="6位数字验证码" maxLength={6} style={{ marginRight: '8px' }} />
          {isCountdown ? btnCountdown : btnGetCode}
        </Flex>
      </Form.Item>

      <Form.Item
        className="normal"
        name="agree"
        valuePropName="checked"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value = '', callback) {
              if (!value) {
                return Promise.reject(
                  <>
                    <CloseCircleOutlined style={errorIconStyle} />
                    请同意用户协议
                  </>,
                );
              }

              // 千万记得写这一句，不然表单校验通过不会执行成功回调
              callback();
            },
          }),
        ]}
      >
        <Checkbox>
          同意
          <Button type="link">
            <Link to="/agreement/user-register" target="_blank">
              《用户注册协议》
            </Link>
          </Button>
        </Checkbox>
      </Form.Item>

      <Form.Item shouldUpdate={true}>
        {() => (
          <Button type="primary" htmlType="submit" className={S.submitBtn} loading={loading}>
            注册
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
