import React, { useEffect, useState } from 'react';
import { Breadcrumb, Affix } from 'antd';
import { PageWrap } from '@components/MainLayout';
import ArticleDetails from '@pages/forum/components/ArticleDetails';
import UserItem from '@pages/forum/components/UserItem';
// @ts-ignore
import S from './style.module.less';
import { getArticleDetail, getUserOldDetail } from '../api';
import { useParams } from 'react-router-dom';
import { userInfoKey } from '@pages/user/login';
import { sessionStorage } from '@utils/lib-storage';

export default function (props: any) {
  const [userInfo, setUserInfo] = useState<any>();
  const [hasFollow, setHasFollow] = useState(false);
  const [hasApproval, setHasApproval] = useState(false);
  const params: any = useParams();

  const { id } = params || {};

  const [authorId, setAuthorId] = useState();
  const [dataSource, setDataSource] = useState<any>({});
  const [commentsList, setCommentsList] = useState([]);
  const [page, setpage] = useState({
    pageNum: 2,
    pageSize: 10,
  });

  const currentUserId = sessionStorage.getItem(userInfoKey)?.id;

  const getDataSource = async () => {
    const result: any = await getArticleDetail(id, {
      ...page,
    });
    setDataSource(result.posts);
    setHasFollow(result.hasFollow);
    setHasApproval(result.hasApproval);
    setCommentsList(result.comments);
    setAuthorId(result.posts.authorId);
  };

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      getDataSource();
    }
    // eslint-disable-next-line
  }, [id, page]);

  useEffect(() => {
    if (authorId) {
      handleGetUserOldDetail();
    }
    // eslint-disable-next-line
  }, [authorId]);

  const handleGetUserOldDetail = async () => {
    const result: any = await getUserOldDetail(authorId);
    setUserInfo(result.user);
  };

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>文章</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.contentWrap}>
        <div className={S.mainWrap}>
          <ArticleDetails
            page={page}
            dataSource={dataSource}
            hasApproval={hasApproval}
            commentsList={commentsList}
            setpage={setpage}
            getDataSource={getDataSource}
          />
        </div>

        {currentUserId !== userInfo?.id && (
          <Affix offsetTop={64 + 20}>
            <div className={S.asideWrap}>
              <UserItem
                theme="theme1"
                type="fans"
                hasFollow={hasFollow}
                point={userInfo?.point}
                name={userInfo?.nickname}
                headImg={userInfo?.avatar}
                id={userInfo?.id}
                status={userInfo?.status}
              />
            </div>
          </Affix>
        )}
      </div>
    </PageWrap>
  );
}
