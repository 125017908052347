import { Key } from '../../types/users';
import { GET, POST, prefix } from '@utils/lib-request/request';
import { Experiment, ExperimentPathSave } from '../../types/experiment';

// 查询实验列表
export const getExperiments = (params: any) => GET(`${prefix}/experiments`, params);

// 获取实验介绍
export const getExperimentsIntroduction = (id: string) =>
  GET(`${prefix}/experiments/introduction/${id}`);

export const getExperiment = (id: string) => GET<any, Experiment>(`${prefix}/experiments/${id}`);

export const saveExperiment = (data: { experimentId: string; filePath: string }) =>
  POST<ExperimentPathSave, any>(`${prefix}/experiments/save`, data);

export const submitExperiment = (data: { experimentId: string; filePath: string }) =>
  POST<ExperimentPathSave, any>(`${prefix}/experiments/submit`, data);

export const learnChapter = (data: { chapterId: string; experimentId: string }) =>
  POST<any, any>(`${prefix}/learn/chapter`, data);

export const getKeys = () => POST<any, Key[]>(`${prefix}/find/key`);

// 获取实验链信息详情
export const getResourcesChainDetails = (params: any) =>
  GET(`${prefix}/resources/chain/${params.id}/${params.type}`);

// 保存实验结果附件
export const saveResultProfiles = (params: any) =>
  POST<any, any>(`${prefix}/experiments/ResultProfiles`, params);

// 获取fabric页面链接
export const getFabricUrl = () => POST<any, any>(`${prefix}/vsCode`);
