import { UserOutlined } from '@ant-design/icons';
import { PageWrap } from '@components/MainLayout';
import { Breadcrumb, Divider, Comment, Avatar, Tooltip, Image, Space, Pagination } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMessageList } from '../api';
// @ts-ignore
import S from './style.module.less';

export default function MessageList(props: any) {
  const [list, setList] = useState<any>([]);
  const [pageRequest, setPageRequest] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);

  const getDataSource = async () => {
    const result: any = await getMessageList({
      ...pageRequest,
    });
    setList(result.messageList);
    setTotal(result.pager.count);
  };

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line
  }, [pageRequest]);

  const userBehavior = (item: any) => {
    const { typeDesc, title, infoAction } = item;
    switch (typeDesc) {
      case '用户关注':
        return '关注了你';
      default:
        return (
          <Space>
            {typeDesc}:<Link to={infoAction}>{title}</Link>
          </Space>
        );
    }
  };

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/forum">论坛首页</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>消息列表</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.contentWrap}>
        <div className={S.mainWrap}>
          <div className={S.titleWrap}>
            <p className={S.tips}>
              <em>消息列表</em>
            </p>
          </div>
          <Divider />
          <div>
            {list.map((item: any) => {
              return (
                <Comment
                  actions={[]}
                  author={
                    <Space>
                      <Link to={`/forum/user-article-list/${item.sender}`}>
                        {item.senderName || `用户${item.sender}`}
                      </Link>
                      {userBehavior(item)}
                    </Space>
                  }
                  avatar={
                    item.senderAvatar ? (
                      <Image preview={false} src={item.senderAvatar} />
                    ) : (
                      <div className="ant-image">
                        <Avatar icon={<UserOutlined />} />
                      </div>
                    )
                  }
                  content={<Link to={item.infoAction}>{item.context}</Link>}
                  datetime={
                    <Tooltip title={moment(item.createAt).format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{moment(item.createAt).fromNow()}</span>
                    </Tooltip>
                  }
                />
              );
            })}
            <div className={S.pagination}>
              <Pagination
                current={pageRequest.pageNum}
                pageSize={pageRequest.pageSize}
                total={total}
                onChange={(pageNum: number, pageSize: any) => {
                  setPageRequest({
                    pageNum,
                    pageSize,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageWrap>
  );
}
