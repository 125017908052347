import React from 'react';
import { Pagination, Empty } from 'antd';
import ArticleItem from '../ArticleItem/index';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const { articleList, total, pageRequest, setPageRequest } = props;

  const changePage = (pageNum: any, pageSize: any) => {
    setPageRequest({
      pageNum,
      pageSize,
    });
  };

  return (
    <>
      {articleList.length ? (
        articleList.map((item: any) => <ArticleItem article={item} />)
      ) : (
        <Empty />
      )}

      <Pagination
        current={pageRequest?.pageNum}
        total={total}
        pageSize={pageRequest?.pageSize}
        className={S.pagination}
        onChange={changePage}
      />
    </>
  );
}
