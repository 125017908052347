import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Card, Space, Spin } from 'antd';
import { PageWrap } from '@components/MainLayout/index';
import { getResourcesChainDetails } from '@pages/experiment/api';
// @ts-ignore
import S from './style.module.less';

export default function (props: any) {
  const [loading, setLoading] = useState(false);

  const [podName, setPodName] = useState();
  const [containerName, setContainerName] = useState();
  const [namespace, setNamespace] = useState();

  const [fabricList, setFabricList] = useState([]);

  const params = useParams<{ id: string }>();
  const resourcesId = params.id;

  // 这个代码抄实验详情里面的
  useEffect(() => {
    const maxRunCount = 5; // 后端说限制轮询的次数
    let currRunCount = 0;

    setLoading(true);

    const getDetails = () => {
      const params = {
        id: resourcesId,
        type: 0, // 0:大型资源, 1为实验
      };

      getResourcesChainDetails(params).then((result: any) => {
        currRunCount++;

        setLoading(false);
        if (currRunCount >= maxRunCount) {
          clearInterval(timer);
        }

        if (result.isStart) {
          setFabricList(result.fabricChainInfoVoList);
          setPodName(result.podName);
          setContainerName(result.containerName);
          setNamespace(result.namespace);
          clearInterval(timer);
        }
      });
    };

    const timer = setInterval(getDetails, 4 * 1000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <PageWrap className={S.pageWrap}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/resources/manage">资源管理</a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>资源详情</Breadcrumb.Item>
      </Breadcrumb>

      <div className={S.terminalWrap}>
        <Spin tip="终端启动中..." spinning={loading}>
          <Space direction="vertical" style={{ width: '100%' }}>
            {fabricList?.length > 0 ? (
              fabricList.map((e: any, index: number) => {
                return (
                  <Card bordered={false} size="small" title={`节点${index + 1}`}>
                    <iframe
                      title="bei-hang-terminal"
                      src={`${process.env.REACT_APP_API_URL}/xterm.html?podName=${e.podName}&namespace=${e.namespace}&container=${e.containerName}`}
                    />
                  </Card>
                );
              })
            ) : (
              <iframe
                title="bei-hang-terminal"
                src={`${process.env.REACT_APP_API_URL}/xterm.html?podName=${podName}&namespace=${namespace}&container=${containerName}`}
              />
            )}
          </Space>
        </Spin>
      </div>
    </PageWrap>
  );
}
